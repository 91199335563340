<template v-slot:container>
	<form class="container-fluid gr-color" v-if="!showTokenError">
		<div class="row">
			<div class="col-sm-7 ps-0">
				<h5>{{ $t("buttonTexts.resetPassword") }}</h5>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-3">
			<div class="col-sm-7 ps-0">
				<p>
					{{ $t("textFields.passwordRequirements") }}
				</p>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-5 align-items-center">
			<div class="col-sm-3 d-flex align-items-center ps-0">
				<p class="fw-bolder">{{ $t("textFields.newPassword") }} *</p>
				<RequiredWarning
					:warningText="$t('warningTexts.readPasswordRequirements')"
					v-if="invalidPassword && password2 === password1"
				/>
			</div>
			<div class="col-sm-4">
				<input
					type="password"
					v-model="password1"
					:placeholder="$t('placeHolders.password')"
					required
					minlength="8"
					class="input-field w-75"
					@input="invalidPassword = false"
				/>
			</div>
		</div>
		<div class="row mt-5 align-items-center">
			<div class="col-sm-3 d-flex align-items-center ps-0">
				<p class="fw-bolder me-2">
					{{ $t("warningTexts.repeatNewPassword") }} *
				</p>
				<RequiredWarning
					:warningText="$t('warningTexts.passwordIsIncorrect')"
					v-if="invalidPassword && password2 !== password1"
				/>
			</div>
			<div class="col-sm-4">
				<input
					type="password"
					v-model="password2"
					placeholder="Lösenord"
					required
					minlength="8"
					class="input-field w-75"
				/>
			</div>
		</div>
		<div class="row mt-5 align-items-center pb-5">
			<div class="col-sm-3 ps-0">
				<button class="active-btn" @click.prevent="postNewPassword">
					{{ $t("buttonTexts.savePassword") }}
				</button>
			</div>
			<div class="col-sm-4"></div>
		</div>
	</form>
	<div class="container-fluid" v-else>
		<div class="row">
			<div class="col-sm-6 ps-0">
				<h5>{{ $t("textFields.urlHasExpired") }}</h5>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-3">
			<div class="col-sm-12 ps-0">
				<p>
					{{ $t("textFields.urlExpiredInfo") }}
				</p>
			</div>
		</div>

		<div class="row mt-5 align-items-center">
			<div class="col-sm-3 ps-0">
				<button class="active-btn" @click="getNewReset">
					{{ $t("buttonTexts.sendNewLink") }}
				</button>
			</div>
			<div class="col-sm-4"></div>
		</div>
	</div>

	<ResponseModal
		v-if="showError"
		:response="0"
		:responseText="
			$t('responseText.passwordError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="dispatch()"
	/>
	<ResponseModal
		v-if="showSuccess"
		:response="1"
		:responseText="$t('responseText.passwordSuccess')"
		@close="navToLogin()"
	/>
</template>
<script>
import ResponseModal from "@/modals/ResponseModal.vue";
import router from "@/router";
import privilages_api from "@/services/api/privilages_api";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";

export default {
	data() {
		return {
			password1: "",
			password2: "",
			invalidPassword: false,
			showError: false,
			showSuccess: false,
			showTokenError: false,
			isLoading: true,
			error: "",
			langOptions: [
				{ lang: "SV", id: "sv" },
				{ lang: "NO", id: "no" },
				{ lang: "DA", id: "da" },
				{ lang: "FI", id: "fi" },
				{ lang: "EN", id: "en" },
			],
			selectedLang: "",
		};
	},
	computed: {
		lang() {
			return this.$store.state.language;
		},
	},
	methods: {
		selectLanguage(lang) {
			this.selectedLang = lang;
			if (this.selectedLang.lang !== "") {
				if (this.getLanguage !== this.selectedLang.id) {
					this.$i18n.locale = this.selectedLang.id;
					this.changeLanguage(this.selectedLang.id);
				}
			}
		},
		dispatch() {
			this.$store.dispatch("auth/handleBackendError", this.error);
		},
		navToLogin() {
			this.$store.dispatch("auth/logout");
			router.push({ name: "login" });
		},
		getNewReset() {
			privilages_api
				.getNewReset(
					this.$route.query.client,
					this.$route.query.token,
					this.$route.query.user
				)
				.then(() => {
					this.isLoading = false;
				})
				.catch(() => {
					this.showTokenError = true;
					this.isLoading = false;
				});
		},
		verifyToken() {
			privilages_api
				.verifyToken(
					this.$route.query.client,
					this.$route.query.token,
					this.$route.query.user
				)
				.then((res) => {
					if (res.data === "OK") {
						this.showTokenError = false;
					} else if (res.data === "Token has expired") {
						this.showTokenError = true;
					} else {
						router.push({ name: "NotFound" });
					}
					this.isLoading = false;
				})
				.catch(() => {
					this.showTokenError = true;
					this.isLoading = false;
				});
		},
		postNewPassword() {
			let numAmount = this.password1.replace(/\D/g, "");
			if (
				this.password1.length >= 8 &&
				this.password1 === this.password2 &&
				/[A-Z]/.test(this.password1) === true &&
				numAmount.length >= 2
			) {
				this.invalidPassword = false;
				privilages_api
					.postNewPassword(
						this.$route.query.client,
						this.$route.query.token,
						this.$route.query.user,
						this.password1
					)
					.then(() => {
						this.showSuccess = true;
					})
					.catch((e) => {
						this.error = e;
						this.showError = true;
					});
			} else {
				this.invalidPassword = true;
			}
		},
	},
	mounted() {
		this.selectedLang = this.langOptions[0];
		this.verifyToken();
	},
	components: {
		ResponseModal,
		RequiredWarning,
	},
};
</script>

<style scoped>
.gr-color {
	padding: 2rem;
	margin-left: -1.2rem;
	width: 103%;
}
.req-list {
	position: fixed;
	z-index: 3;
	top: 18%;

	background-color: white;
	padding-left: 1.6rem;
	padding-right: 1rem;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	color: black;
	border-radius: 10px;

	font-size: 14px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.req-list p {
	font-weight: bolder;
}
.reset-pw-wrapper {
	height: 70vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reset-pw-container {
	width: 90%;
	height: 80%;
	background-color: #f8f5ee;
	border-radius: 20px;
	display: flex;
	justify-content: center;
}

.reset-pw-content {
	margin-top: 4%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.reset-pw-content h2 {
	margin-bottom: 10%;
	text-align: center;
}

.reset-pw-content h2 {
	margin-bottom: 10%;
}

.pw-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2rem;
}

.pw-row h3 {
	margin-bottom: 1rem;
}

.pw-row input {
	height: 3rem;
	border-radius: 10px;
	border: 1px solid grey;
	width: 18rem;
	font-size: 20px;
	padding-left: 1rem;
}

.save-btn {
	height: 42px;
	font-size: 14px;
}
.sub-header {
	width: 100%;
	height: 4rem;

	background-color: #f8f5ee;
	margin-bottom: 1rem;
}

.sub-header-container {
	width: 100%;
	height: 100%;
}

.sub-header h2 {
	margin-left: 1rem;
}

.navbar-container {
	width: 100%;
	display: flex;
	height: 10vh;
	align-items: center;
}

.logo {
	height: 40%;
	cursor: pointer;
}
</style>

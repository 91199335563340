<template>
	<div class="row mb-2">
		<div class="col">
			<p class="large-text">
				{{ $t("navbarTitle.inventory") }}
			</p>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<p class="small-text">
				{{ $t("landingPage.inventoryInformation") }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col d-flex mb-3">
			<button class="active-filter" @click="option = 0">
				{{ $t("inventory.allInventories") }}
			</button>
			<button class="filter-btn" @click="option = 1">
				{{ $t("inventory.createNewInventory") }}
			</button>

			<div class="search-bar">
				<img
					src="@/assets/img/search-icon.svg"
					alt="search icon"
					class="search-icon"
				/>
				<input
					type="text"
					class="text-input"
					:placeholder="$t('placeHolders.searchInventory')"
					v-model="searchValue"
				/>
			</div>
		</div>
	</div>

	<InventoryMain
		ref="main"
		@newInventory="option = 1"
		:searchValue="searchValue"
		:option="option"
		@option="(opt) => (option = opt)"
	/>
</template>

<script>
import InventoryMain from "./InventoryMain.vue";
export default {
	components: {
		InventoryMain,
	},
	data() {
		return {
			option: 0,
			searchValue: "",
			windowWidth: window.innerWidth,
			activeRecs: true,
			historyRecs: false,
		};
	},
	methods: {},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	watch: {},
};
</script>

<style scoped>
.bc {
	cursor: pointer;
}
</style>

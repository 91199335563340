<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid sticky-header" v-else>
			<div class="row table-header-b align-items-center">
				<div
					class="col-sm-2 ps-0"
					:class="sortOption.title === 'namn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('namn')"
					>
						<p class="me-2">{{ $t("tableHeaders.name") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'namn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-3"
					:class="sortOption.title === 'epost' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('epost')"
					>
						<p class="me-2">{{ $t("tableHeaders.email") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'epost' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'foretag' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('foretag')"
					>
						<p class="me-2">{{ $t("tableHeaders.company") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'foretag' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col-sm-4 d-flex">
					<div class="d-flex align-items-center">
						<p class="me-4">{{ $t("tableHeaders.permissions") }}</p>
						<Popper :hover="true">
							<template #content>
								<div class="popper-content">
									<p class="fw-bolder">
										{{ $t("tableHeaders.infoAboutPermissions") }}:
									</p>

									<p>{{ $t("textFields.viewPermission") }}</p>
									<p>{{ $t("textFields.inventoryPermission") }}</p>
									<p>{{ $t("textFields.claimPermission") }}</p>
									<p>{{ $t("textFields.orderPermission") }}</p>
									<p>{{ $t("textFields.changePermission") }}</p>
								</div>
							</template>
							<img
								src="@/assets/img/info-icon.svg"
								class="info-icon"
								alt="info icon"
							/>
						</Popper>
					</div>
				</div>

				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<template v-for="(user, i) in filteredUsers" :key="user">
				<div
					class="row table-item-b align-items-center"
					v-if="user.aktiv === 'J'"
				>
					<div class="col-sm-2 fw-bold ps-0">
						<input
							type="text"
							class="input-field w-75"
							:placeholder="$t('tableHeaders.name')"
							v-model="user.namn"
							@input="isEditing = true"
							maxlength="40"
							required
						/>
						<RequiredWarning
							:warningText="$t('warningTexts.requiredField')"
							v-if="showWarning && !user.namn.length"
						/>
					</div>
					<div class="col-sm-3 fw-bold">
						<p class="ellipsis">{{ user.epost }}</p>
					</div>
					<div class="col-sm-2 fw-bold">
						<input
							type="text"
							class="input-field w-75"
							:placeholder="$t('tableHeaders.company')"
							v-model="user.foretag"
							maxlength="40"
							@input="isEditing = true"
						/>
					</div>

					<div class="col-sm-2 d-flex align-items-center">
						<div class="content-xxl">
							<Multiselect
								class="selector-xxl right-multiselect"
								:open-direction="i === 0 || i === 1 || i === 2 ? 'bottom' : ''"
								:options="userPrivileges"
								:show-labels="false"
								:placeholder="$t('placeHolders.changePermissions')"
								:close-on-select="false"
								:clear-on-select="true"
								:multiple="true"
								><template v-slot:caret>
									<div class="multiselect__select">
										<img
											src="@/assets/img/down-icon.svg"
											alt="dropdown icon"
										/></div
								></template>
								<template #option="{ option }"
									><div
										v-if="option === 'produkter'"
										class="d-flex align-items-center multi-checkbox"
									>
										<input
											type="checkbox"
											class="dropdown-checkbox ms-2"
											checked
											disabled
										/>
										<p>
											{{ $t("buttonTexts.viewProducts") }}
										</p>
									</div>
									<div
										v-else
										class="d-flex align-items-center w-100 multi-checkbox"
										@click="
											user[option] = user[option] === 'J' ? 'N' : 'J';
											isEditing = true;
										"
									>
										<input
											type="checkbox"
											@change="isEditing = true"
											class="dropdown-checkbox ms-2"
											true-value="J"
											false-value="N"
											v-model="user[option]"
										/>
										<p>
											{{ $t("list.privileges." + option) }}
										</p>
									</div></template
								></Multiselect
							>
						</div>
					</div>

					<div class="col-sm-3 d-flex justify-content-end">
						<button
							class="standard-btn me-3"
							@click="
								() => {
									verifyReset = true;
									chosenUser = user;
								}
							"
						>
							{{ $t("buttonTexts.resetPassword") }}
						</button>

						<Popper :hover="true" v-if="login !== user.login">
							<template #content>
								<div class="popper-content">
									<p>{{ $t("buttonTexts.delete") }}</p>
								</div>
							</template>
							<img
								src="@/assets/img/delete.svg"
								class="delete-circle me-2"
								alt="delete icon"
								@click="
									verifyInactive = true;
									inactiveUser = user;
								"
							/>
						</Popper>

						<div class="delete-circle me-2" v-else></div>
					</div>
				</div>
			</template>
		</div>
	</div>
	<footer class="container-fluid sticky-footer pe-0" v-if="isEditing">
		<button class="active-btn" @click="checkReqFields()">
			{{ $t("buttonTexts.saveChanges") }}
		</button>
	</footer>
	<VerificationModal
		:header="$t('buttonTexts.reset')"
		v-if="verifyReset"
		:verificationText="$t('verificationTexts.passwordReset')"
		@accept="
			resetPassword();
			verifyReset = false;
		"
		@close="verifyReset = false"
	/>
	<VerificationModal
		:header="$t('buttonTexts.delete')"
		v-if="verifyInactive"
		:verificationText="$t('verificationTexts.deleteUser')"
		@accept="
			updateUsers();
			verifyInactive = false;
		"
		@close="verifyInactive = false"
	/>
	<VerificationModal
		:header="$t('buttonTexts.update')"
		v-if="verifyUpdate"
		:verificationText="$t('verificationTexts.userUpdate')"
		@accept="
			updateUsers();
			verifyUpdate = false;
		"
		@close="verifyUpdate = false"
	/>

	<ResponseModal
		v-if="resetSuccess"
		:response="1"
		:responseText="translatedText"
		@close="resetSuccess = false"
	/>
	<ResponseModal
		v-if="resetError"
		:response="0"
		:responseText="$t('responseText.passwordResetError')"
		@close="resetError = false"
	/>

	<ResponseModal
		v-if="updateSuccess"
		:response="1"
		:responseText="$t('responseText.userUpdateSuccess')"
		@close="
			updateSuccess = false;
			getUsers();
		"
	/>
	<ResponseModal
		v-if="updateError"
		:response="0"
		:responseText="
			$t('responseText.userUpdateError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="updateError = false"
	/>
</template>

<script>
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import admin_api from "@/services/api/admin_api";
import Popper from "vue3-popper";
import VerificationModal from "@/modals/VerificationModal.vue";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import Multiselect from "vue-multiselect";
import router from "@/router";

export default {
	components: {
		VerificationModal,
		LoaderSpinner,
		RequiredWarning,
		ResponseModal,
		Multiselect,
		Popper,
	},
	emits: ["isEditing"],

	props: { searchValue: String },
	data() {
		return {
			inactiveUser: null,
			verifyInactive: false,
			verifyUpdate: false,
			verifyReset: false,
			chosenUser: [],
			users: [],
			isEditing: false,
			isLoading: false,
			adminRole: this.$store.getters["adminRole"],
			login: "",
			sortOption: {
				title: "",
				active: false,
			},
			userPrivileges: [
				"produkter",
				"beh_inventera",
				"beh_reklamera",
				"beh_andra",
				"beh_produktinfo",
			],
			intUserPrivileges: [
				"produkter",
				"beh_inventera",
				"beh_reklamera",
				"beh_andra",
				"beh_produktinfo",
				"intern",
			],
			showWarning: false,
			resetSuccess: false,
			resetError: false,
			updateSuccess: false,
			updateError: false,
		};
	},
	computed: {
		translatedText() {
			return this.$t("responseText.passwordResetSuccess", [
				`<span style="font-weight: bolder">${this.chosenUser.epost}</span>`,
			]);
		},
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredUsers() {
			if (this.searchValue.length === 0) {
				return this.users;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.users.filter((u) => {
					return (
						u.namn.toLowerCase().includes(searchText) ||
						u.login.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		checkReqFields() {
			let validFields = true;

			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].namn === "") {
					this.showWarning = true;
					this.users[i].isInvalid = true;
					validFields = false;
				}
			}
			if (validFields) {
				this.verifyUpdate = true;
				this.showWarning = false;
			}
		},

		resetPassword() {
			admin_api
				.resetPassword(this.chosenUser.id)
				.then(() => {
					this.verifyReset = false;
					this.resetSuccess = true;
				})
				.catch(() => {
					this.resetError = true;
				});
		},
		getUsers() {
			if (this.adminRole) {
				this.isLoading = true;
				admin_api
					.getUsers(this.clientId)
					.then((res) => {
						this.users = res.data;
						this.isLoading = false;
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},
		updateUsers() {
			if (this.inactiveUser !== null) {
				this.users.map((user) => {
					if (user.id === this.inactiveUser.id) {
						user.aktiv = "N";
					}
				});
			}

			let validUser = true;
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].validLogin === false) {
					validUser = false;
					break;
				}
			}
			if (validUser) {
				admin_api
					.updateUsers(this.users)
					.then(() => {
						this.isEditing = false;
						this.verifyUpdate = false;
						this.updateSuccess = true;
					})
					.catch(() => {
						this.updateError = true;
					});
			} else {
				this.showWarning = true;
			}
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (this.sortOption.active) {
					this.users.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.users.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				this.users.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
			}
		},
	},
	mounted() {
		this.getUsers();
		this.login = this.$store.state.client.login;
	},

	watch: {
		clientId() {
			this.users = [];
			this.showWarning = false;
			this.adminRole = this.$store.getters["adminRole"];
			this.getUsers();
		},

		isEditing(val) {
			this.$emit("isEditing", val);
		},
	},
};
</script>

<style scoped></style>

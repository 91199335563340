import store from "../store";
import { createRouter, createWebHistory } from "vue-router";
import LoginWindow from "@/views/standard_views/LoginWindow";
import MainSlot from "../views/main_view/MainSlot";
import HandleRoomsSlot from "../views/room_handle_view/HandleRoomsSlot";
import InventorySlot from "../views/inventory_view/InventorySlot";
import InventorySlotRooms from "../views/inventory_view/InventorySlotRooms";
import InventorySlotSelected from "../views/inventory_view/InventorySlotSelected";
import UsersSlot from "../views/users_view/UsersSlot";
import LandingPage from "../views/standard_views/LandingPage";
import ResetPassword from "../views/ResetPassword";
import NotFound from "../views/NotFound";

const routes = [
	{
		name: "login",
		path: "/login",
		component: LoginWindow,
		meta: {
			middleware: "guest",
			title: `Login`,
		},
	},
	{
		name: "Home",
		path: "/",
		component: LandingPage,
		meta: {
			mainHeader: 0,
			title: `Home`,
		},
	},
	{
		name: "ProductOverview",
		path: "/product-overview",
		component: MainSlot,
		meta: {
			mainHeader: 1,
			title: `Product overview`,
		},
	},
	{
		name: "HandleRoomsSlot",
		path: "/room-handle",
		component: HandleRoomsSlot,
		meta: {
			role: ["beh_inventera", "beh_admin"],
			mainHeader: 2,
			title: `Handle rooms`,
		},
	},

	{
		name: "InventorySlot",
		path: "/inventory",
		component: InventorySlot,
		meta: {
			role: ["beh_inventera", "beh_admin"],
			mainHeader: 3,
			title: `Inventory`,
		},
	},
	{
		name: "InventorySlotRooms",
		path: "/inventory/:invId/rooms/:locName",
		component: InventorySlotRooms,
		meta: {
			role: ["beh_inventera", "beh_admin"],
			mainHeader: 3,
			title: `Inventory`,
		},
		props: true,
	},

	{
		name: "InventorySlotSelected",
		path: "/inventory/:invId/rooms/:locName/index/:loc",
		component: InventorySlotSelected,
		meta: {
			role: ["beh_inventera", "beh_admin"],
			mainHeader: 3,
			title: `Inventory`,
		},
		props: true,
	},

	{
		name: "UsersSlot",
		path: "/users",
		component: UsersSlot,
		meta: {
			role: ["beh_admin"],
			mainHeader: 4,
			title: `Users`,
		},
	},
	{
		name: "resetpassword",
		path: "/reset-password",
		component: ResetPassword,
		meta: {
			middleware: "guest",
			title: `Reset password`,
		},
	},
	{
		name: "NotFound",
		path: "/not-found",
		component: NotFound,
		meta: {
			middleware: "guest",
			title: `404 Not found`,
		},
	},
	{
		path: "/:catchAll(.*)",
		component: NotFound,
		meta: {
			middleware: "guest",
			title: `404 Not found`,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

router.beforeResolve((to, from, next) => {
	document.title = `${process.env.VUE_APP_NAME} - ${to.meta.title}`;
	const allowed = () => {
		let priv = false;
		to.meta?.role.map((r) => {
			if (store.getters.client[r] === "J") {
				priv = true;
			}
		});
		return priv;
	};
	if (to.meta?.role !== undefined) {
		if (allowed() && store.state.auth.authenticated) {
			next();
			return;
		} else {
			next({ name: "NotFound" });
		}
	}

	if (to.meta.middleware == "guest") {
		next();
	} else {
		if (store.state.auth.authenticated) {
			next();
			return;
		} else {
			next({ name: "login" });
			return;
		}
	}
});

export default router;

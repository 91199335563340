<template>
	<GrModalMedium>
		<template v-slot:modal-header-title>
			<p class="fw-bolder">{{ $t("modalLabels.inventoryHistory") }}</p>
		</template>
		<template v-slot:modal-content>
			<div class="table-header-b col-sm-12 d-flex align-items-center">
				<div class="col-sm-5">
					{{ $t("tableHeaders.designation") }}
				</div>
				<div class="col-sm-5">
					{{ $t("tableHeaders.state") }}
				</div>
				<div class="col-sm-2">
					{{ $t("tableHeaders.date") }}
				</div>
			</div>
			<div class="table-wrapper">
				<div class="row">
					<div
						class="table-item-b d-flex align-items-center ps-0 pe-0"
						v-for="inv in invHistory"
						:key="inv"
					>
						<div class="col-sm-5">
							{{ inv.benamning }}
						</div>
						<div class="col-sm-5">
							{{ inv.status }}
						</div>
						<div class="col-sm-2">
							{{
								inv.inv_datum !== null ? inv.inv_datum.substring(0, 10) : "-"
							}}
						</div>
					</div>
				</div>
			</div>
		</template>
	</GrModalMedium>
</template>

<script>
import GrModalMedium from "@/modals/GrModalMedium.vue";

export default {
	components: { GrModalMedium },
	props: { invHistory: Object },
	data() {},
};
</script>

<style scoped>
.table-header-b {
	padding-left: 1.2rem;
	padding-right: 2rem;
}
.table-wrapper {
	padding-left: 2rem;
	padding-right: 2rem;
	max-height: 30rem;
	overflow: auto;
	background-color: white;
}
</style>

import api from "@/services/api";

export default {
	getUser(id, login) {
		return api().get(`/user/${id}/${login}`);
	},
	getUsers() {
		return api().get("/users");
	},
	updateUsers(users) {
		return api().post("/users", JSON.stringify({ users: users }));
	},
	resetPassword(userId) {
		return api().post(`/users/${userId}/reset`);
	},
	verifyUser(email, userId) {
		return api().post(
			`/users/${userId}/verify`,
			JSON.stringify({ email: email })
		);
	},
};

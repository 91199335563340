<template>
	<div class="container-fluid ms-1 mt-5">
		<div class="row align-items-center">
			<div class="col-sm-6 ps-0">
				<h5>{{ $t("modalLabels.claimProduct") }}</h5>
			</div>
			<div class="col-sm-6"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-2">
			<div class="col-sm-8 ps-0">
				<p>
					{{ $t("textFields.claimInfo") }}
				</p>
			</div>
			<div class="col"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-5">
			<div class="col-sm-3 d-flex align-items-center ps-0">
				<p class="fw-bolder">{{ $t("modalLabels.issue") }} *</p>
				<RequiredWarning
					class="ms-2"
					v-if="showWarning && !claimForm.selectedIssue.length"
					:warningText="$t('warningTexts.noIssueChosen')"
				/>
			</div>
			<div class="col-sm-5">
				<div class="content-m">
					<Multiselect
						class="selector-c multiselect-left"
						:options="claimTitles.map((loc) => loc.id)"
						v-model="claimForm.selectedIssue"
						:show-labels="false"
						:custom-label="
							(option) => claimTitles.find((x) => x.id == option).text
						"
						:placeholder="$t('modalLabels.chooseIssue')"
						><template v-slot:caret>
							<div class="multiselect__select">
								<img
									src="@/assets/img/down-icon.svg"
									alt="dropdown icon"
								/></div></template
					></Multiselect>
				</div>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-4">
			<div class="col-sm-3 d-flex ps-0">
				<p class="fw-bolder">{{ $t("modalLabels.issueDesc") }} *</p>
				<RequiredWarning
					class="ms-2"
					v-if="showWarning && !claimForm.description.length"
					:warningText="$t('warningTexts.missingIssueDescription')"
				/>
			</div>
			<div class="col-sm-5 d-flex align-items-end">
				<textarea
					class="textbox-field"
					type="text"
					:placeholder="$t('placeHolders.describeClaimReason')"
					v-model="claimForm.description"
					@input="isEditing = true"
				></textarea>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-4 justify-content-between align-items-center">
			<div class="col-sm-3 d-flex align-items-center ps-0">
				<p class="fw-bolder me-3">
					{{ $t("articleInfoLabels.clientErrNumber") }}
				</p>
			</div>
			<div class="col-sm-5">
				<input
					class="input-field w-100"
					:placeholder="$t('placeHolders.enterErrandNumber')"
					type="text"
					v-model="claimForm.errandNumber"
					@input="isEditing = true"
				/>
			</div>
			<div class="col"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-4">
			<div class="col-sm-3 ps-0">
				<p class="fw-bolder">{{ $t("modalLabels.placement") }}</p>
			</div>
			<div class="col-sm-5">
				<div class="content-l">
					<Multiselect
						class="selector-c multiselect-left"
						:options="locations.map((loc) => loc.plats)"
						:show-labels="false"
						:custom-label="
							(option) => locations.find((x) => x.plats == option).platsnamn
						"
						:placeholder="$t('placeHolders.chooseLocation')"
						v-model="claimForm.selectedLocation"
						><template v-slot:caret>
							<div class="multiselect__select">
								<img
									src="@/assets/img/down-icon.svg"
									alt="dropdown icon"
								/></div></template
					></Multiselect>
				</div>
			</div>
			<div class="col"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-4">
			<div class="col-sm-3 d-flex align-items-center ps-0">
				<p class="fw-bolder">{{ $t("modalLabels.confirmPlace") }} *</p>
				<RequiredWarning
					class="ms-2"
					v-if="showWarning && !verifyLocation"
					:warningText="$t('warningTexts.confirmLocation')"
				/>
			</div>
			<div class="col-sm-5 d-flex align-items-center">
				<input
					class="input-checkbox me-3"
					type="checkbox"
					v-model="verifyLocation"
					@input="isEditing = true"
				/>
				<p class="fw-bold">{{ $t("textFields.productIsAtThisLocation") }}</p>
			</div>
			<div class="col"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-5">
			<div class="col-sm-3 ps-0"></div>
			<div class="col-sm-5 d-flex align-items-center">
				<div class="image-gallery w-100">
					<label for="file-upload" class="image-box me-2">
						<input
							id="file-upload"
							type="file"
							name="filename"
							multiple
							@change="uploadImage(1)"
							ref="file1"
						/>
						<img :src="imgSrc1" class="claim-img" alt="claim image" />
						<p class="fw-bold img-text" v-if="imgSrc1 === null">
							{{ $t("modalLabels.image") }} 1 *
						</p>
						<RequiredWarning
							class="ms-2 img-req"
							v-if="showWarning && imgSrc1 === null"
							:warningText="$t('textFields.missingImage')"
						/>
					</label>
					<label for="file-upload-2" class="image-box me-2">
						<input
							id="file-upload-2"
							type="file"
							name="filename"
							multiple
							@change="uploadImage(2)"
							ref="file2"
						/>
						<img :src="imgSrc2" class="claim-img" alt="claim image" />
						<p class="fw-bold img-text" v-if="imgSrc2 === null">
							{{ $t("modalLabels.image") }} 2 *
						</p>
						<RequiredWarning
							class="ms-2 img-req"
							v-if="showWarning && imgSrc2 === null"
							:warningText="$t('textFields.missingImage')"
						/>
					</label>

					<label for="file-upload-3" class="image-box">
						<input
							id="file-upload-3"
							type="file"
							name="filename"
							multiple
							@change="uploadImage(3)"
							ref="file3"
						/>
						<img :src="imgSrc3" alt="claim image" class="claim-img" />
						<p class="fw-bold img-text" v-if="imgSrc3 === null">
							{{ $t("modalLabels.image") }} 3
						</p>
					</label>
				</div>
			</div>
			<div class="col"></div>
		</div>
		<div class="row align-items-center justify-content-between mt-5 mb-4 ps-1">
			<div class="col-sm-4"></div>
			<div class="col-sm-4"></div>
			<div class="col-sm-3 d-flex justify-content-start">
				<button class="active-btn mt-3 mb-3 ps-4" @click="checkValidation()">
					{{ $t("modalLabels.claimProduct") }}
				</button>
			</div>
		</div>
	</div>

	<ResponseModal
		v-if="claimNumberSuccess"
		:response="1"
		:responseText="
			$t('responseText.claimNumberSuccess', [claimForm.errandNumber, errand])
		"
		@close="
			claimNumberSuccess = false;
			$emit('refresh');
		"
	/>
	<ResponseModal
		v-if="noNumberSuccess"
		:response="1"
		:responseText="$t('responseText.noNumberSuccess', [errand])"
		@close="
			noNumberSuccess = false;
			$emit('refresh');
		"
	/>
	<ResponseModal
		v-if="errorResponse"
		:response="0"
		:responseText="
			$t('responseText.claimError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="errorResponse = false"
	/>
	<VerificationModal
		:header="$t('articleInfoLabels.claim')"
		v-if="verifyClaim"
		:verificationText="$t('verificationTexts.submitClaim')"
		@close="verifyClaim = false"
		@accept="submitClaim()"
	/>
</template>

<script>
import Multiselect from "vue-multiselect";
import room_handle_api from "@/services/api/room_handle_api.js";
import claim_api from "@/services/api/claim_api.js";
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
export default {
	data() {
		return {
			verifyClaim: false,
			isEditing: false,
			isLoading: false,
			showSelector: false,
			claimTitles: [],
			verifyLocation: false,
			locations: [],
			claimForm: {
				selectedIssue: "",
				selectedLocation: "",
				errandNumber: "",
				description: "",
				image1: "",
				image2: "",
				image3: "",
			},
			imgSrc1: null,
			imgSrc2: null,
			imgSrc3: null,
			errand: null,
			maxLength: false,
			image: null,
			claimNumberSuccess: false,
			noNumberSuccess: false,
			errorResponse: false,
			showWarning: false,
		};
	},
	props: {
		chosenId: Object,
		hideColumns: { type: Boolean, default: false },
	},
	emits: ["closeModal", "refreshClaim", "refresh", "isEditing"],

	components: {
		ResponseModal,
		VerificationModal,
		RequiredWarning,
		Multiselect,
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		checkValidation() {
			if (
				this.claimForm.selectedIssue.length > 0 &&
				this.claimForm.description.length > 0 &&
				this.claimForm.image1 !== "" &&
				this.claimForm.image2 !== "" &&
				this.verifyLocation === true
			) {
				this.verifyClaim = true;
			} else {
				this.showWarning = true;
			}
		},

		uploadImage(num) {
			this.isEditing = true;

			if (num === 1) {
				let file = this.$refs.file1.files[0];
				this.claimForm.image1 = file;
				this.imgSrc1 = URL.createObjectURL(file);
			} else if (num === 2) {
				let file = this.$refs.file2.files[0];
				this.claimForm.image2 = file;
				this.imgSrc2 = URL.createObjectURL(file);
			} else {
				let file = this.$refs.file3.files[0];
				this.claimForm.image3 = file;
				this.imgSrc3 = URL.createObjectURL(file);
			}
		},

		getHandleRooms: function () {
			room_handle_api.getHandleRooms().then((res) => {
				this.locations = res.data;
				this.claimForm.selectedLocation = this.chosenId.plats;
				this.showSelector = true;
			});
		},

		getClaimTitles: function () {
			claim_api.getClaimTitles().then((res) => {
				this.claimTitles = res.data;
			});
		},
		submitClaim() {
			this.isLoading = true;
			this.verifyClaim = false;

			let formData = new FormData();

			for (let key in this.claimForm) {
				formData.append(key, this.claimForm[key]);
			}

			claim_api
				.postClaim(this.chosenId.id, formData)
				.then((res) => {
					this.errand = res.data;
					if (this.claimForm.errandNumber.length > 0) {
						this.claimNumberSuccess = true;
					} else {
						this.noNumberSuccess = true;
					}

					this.isLoading = false;
				})
				.catch(() => {
					this.errorResponse = true;
					this.isLoading = false;
				});
		},
	},
	mounted() {
		this.getClaimTitles();
		this.getHandleRooms();
	},

	watch: {
		isEditing(bool) {
			if (bool) {
				this.$emit("isEditing");
			}
		},
	},
};
</script>

<style scoped>
.img-req {
	position: absolute;
	top: 0.4rem;
	right: 0.4rem;
}
.req-text-container {
	min-width: 8rem;
	max-width: 14rem;
	padding: 0.4rem;
	min-height: 2.4rem;
	background-color: rgb(255, 255, 255);
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-size: 14px;
}

.req-text {
	max-width: 100%;
	white-space: normal;
}

img:-moz-broken {
	opacity: 0;
}

img {
	position: relative;
}

img::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
}
.claim-img {
	width: 100%;
	height: 100%;
}
.image-wrapper {
	width: 100%;
	height: 100%;
}

.img-text {
	position: absolute;
	top: 1rem;
	left: 1rem;
}
.image-box {
	position: relative;
	width: 9rem;
	max-width: 9rem;
	height: 9rem;
	max-height: 9rem;
	border: 1.5px solid black;
	background-color: white;
	cursor: pointer;
}

.image-gallery {
	display: flex;
	justify-content: space-between;
}
.claim-gallery {
	max-height: 90%;
	overflow: auto;
}
.warning-text {
	color: red;
	font-weight: 600;
}
.delete-icon {
	margin-right: 0.4rem;
	cursor: pointer;
}

.delete-icon:hover {
	transform: scale(1.1);
}
.file-row {
	font-size: 14px;
	margin-bottom: 0.4rem;
	display: flex;
	text-decoration: underline;
}
.input-checkbox {
	width: 2rem;
	height: 2rem;
}

.claim-input {
	height: 4rem;
}
.textbox-field {
	width: 100%;
	height: 8rem;
	font-size: 14px;
	padding: 10px;
	border: 1.5px solid black;
}

.textbox-field::placeholder {
	color: #949494 !important;
}

input[type="file"] {
	display: none;
}
</style>

<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid" v-else>
			<div class="row align-items-center">
				<div class="col-sm-6 ps-0">
					<div
						class="d-flex align-items-center return-wrapper"
						@click="returnToRooms()"
					>
						<img
							src="@/assets/img/down-icon.svg"
							alt="dropdown arrow"
							class="return-arrow me-2"
						/>
						<p class="large-text">
							{{ invData.benamning }}
						</p>
					</div>

					<p class="mt-2 small-text">
						{{ invData.inventerade }}/{{ invData.antal_artiklar }}
						{{ $t("inventory.productsInventoried") }}
					</p>
				</div>
			</div>
			<div class="row mt-4 mb-4">
				<div class="col-sm-5 d-flex fs-6 ps-0">
					<button @click="showInventory = true" class="standard-btn">
						{{ $t("inventory.startScannerManually") }}
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col ps-0 d-flex fw-bolder">
					{{ $t("buttonTexts.notInventoriedProducts") }}
				</div>
			</div>
		</div>
		<div class="main-container mt-3">
			<div
				class="container-fluid"
				:class="articles.length > 3 ? 'sticky-header' : ''"
			>
				<div class="row align-items-center table-header-b">
					<div
						class="col-sm-4 d-flex ps-0"
						:class="
							sortOption.title === 'benamn' && sortOption.table === 0
								? 'active-th'
								: ''
						"
					>
						<div class="d-flex space">
							<input v-if="!activeArticles" type="checkbox" disabled />
							<input v-else type="checkbox" v-model="checkAllInv" />
						</div>
						<div
							class="d-flex sorting align-items-center ms-1"
							@click="setSorting('benamn')"
						>
							<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
							<img
								src="@/assets/img/drop-arrow.svg"
								alt="dropdown arrow"
								:class="
									sortOption.title === 'benamn' &&
									sortOption.active &&
									sortOption.table === 0
										? 'active-sort'
										: 'inactive-sort'
								"
							/>
						</div>
					</div>
					<div class="col-sm-2">{{ $t("tableHeaders.artNumber") }}</div>
					<div class="col-sm-2">{{ $t("articleInfoLabels.id") }}</div>

					<div class="col-sm-2">
						<div class="d-flex align-items-center">
							<p>{{ $t("tableHeaders.state") }}</p>
						</div>
					</div>
					<div class="col-sm-2" v-if="setInv.length">
						<div class="d-flex align-items-center">
							<p class="me-4">{{ $t("placeHolders.comment") }}</p>
							<Popper :hover="true">
								<template #content>
									<div class="popper-content">
										{{ $t("textFields.infoAboutComment") }}
									</div>
								</template>
								<img
									src="@/assets/img/info-icon.svg"
									class="info-icon"
									alt="info icon"
								/>
							</Popper>
						</div>
					</div>
				</div>
			</div>

			<div class="container-fluid" v-for="inv in articles" :key="inv.id">
				<div class="row table-item-b align-items-center">
					<div class="col-sm-4 d-flex align-items-center ps-0">
						<input
							v-if="inv.farg === 'success'"
							type="checkbox"
							:checked="setInv[getIndex(inv.id)]"
							@input="markItem(inv)"
							class="space inv-checkbox"
						/>
						<div class="space" v-else></div>
						<div class="product-image-container ms-1">
							<img
								v-if="inv.bild"
								:src="inv.bild"
								alt="product-image"
								class="product-image"
							/>
							<img
								v-else
								src="@/assets/img/no-image.svg"
								alt="product-image"
								class="product-image err-image"
							/>
						</div>

						<p class="fw-bolder ms-2">{{ inv.benamn }}</p>
					</div>
					<div class="col-sm-2">{{ inv.artikelnr }}</div>
					<div class="col-sm-2">{{ inv.id }}</div>
					<div :class="inv.farg === 'success' ? 'col-sm-2' : 'col-sm-4'">
						<p class="ellipsis">
							{{
								inv.text !== "N"
									? inv.text
									: $t("inventory.states.notInventoried")
							}}
						</p>
					</div>
					<div class="col-sm-2" v-if="checkedInv(inv.id)">
						<input
							type="text"
							class="input-field w-100"
							v-model="setInv[getIndex(inv.id)].rec"
							:placeholder="$t('placeHolders.comment')"
						/>
					</div>
				</div>
			</div>
			<div class="container-fluid sticky-footer" v-if="activeArticles">
				<div class="row w-100 justify-content-end" v-if="setInv.length">
					<div class="col-sm-6 ps-0 d-flex justify-content-end">
						<div class="content-mml">
							<Multiselect
								class="selector-c right-multiselect"
								:options="invOptions.map((inv) => inv.atgard)"
								:open-direction="'top'"
								:show-labels="false"
								:custom-label="
									(option) => invOptions.find((x) => x.atgard == option).name
								"
								v-model="selectedInvOption"
								:placeholder="$t('placeHolders.selectAction')"
								><template v-slot:caret>
									<div class="multiselect__select">
										<img
											src="@/assets/img/down-icon.svg"
											alt="dropdown icon"
										/></div></template
							></Multiselect>
						</div>
						<div class="content-s ms-2">
							<Multiselect
								class="selector-c right-multiselect"
								v-if="selectedInvOption !== 'I' && selectedInvOption !== 'K'"
								:options="locations"
								:open-direction="'top'"
								v-model="selectedLocation"
								:show-labels="false"
								label="platsnamn"
								:placeholder="$t('handleRooms.chooseRoom')"
								><template v-slot:caret>
									<div class="multiselect__select">
										<img
											src="@/assets/img/down-icon.svg"
											alt="dropdown icon"
										/></div></template
							></Multiselect>
						</div>

						<RequiredWarning
							v-if="showWarning && selectedLocation === null"
							:warningText="$t('warningTexts.noRoomSelected')"
						/>

						<button
							v-if="selectedInvOption !== 'I'"
							class="standard-btn confirm-btn ms-2"
							@click="
								selectedInvOption === 'K'
									? (verifyDiscard = true)
									: checkLocation()
							"
						>
							{{ $t("buttonTexts.save") }}
						</button>

						<button
							v-else
							class="standard-btn confirm-btn ms-2"
							@click="showVerification = true"
						>
							{{ $t("buttonTexts.save") }}
						</button>
					</div>
				</div>

				<div class="row w-100 justify-content-end" v-else>
					<div class="col-sm-5 d-flex justify-content-end">
						<p class="fw-bolder">{{ $t("warningTexts.noArticleSelected") }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-sm-4 fw-bolder">
				{{ $t("inventory.inventoriedProducts") }}
			</div>
		</div>
		<div class="main-container" v-if="!isLoading">
			<div class="container-fluid mt-4 sticky-header inv-lower">
				<div class="row align-items-center table-header-b">
					<div
						class="col-sm-4 d-flex ps-0"
						:class="
							sortOption.title === 'benamn1' && sortOption.table === 1
								? 'active-th'
								: ''
						"
					>
						<div class="space"></div>
						<div
							class="d-flex sorting align-items-center ms-1"
							@click="setInactiveSorting('benamn1')"
						>
							<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
							<img
								src="@/assets/img/drop-arrow.svg"
								alt="dropdown arrow"
								:class="
									sortOption.title === 'benamn1' &&
									sortOption.active &&
									sortOption.table === 1
										? 'active-sort'
										: 'inactive-sort'
								"
							/>
						</div>
					</div>
					<div class="col-sm-2">{{ $t("tableHeaders.artNumber") }}</div>
					<div class="col-sm-2">{{ $t("articleInfoLabels.id") }}</div>

					<div class="col-sm-2">{{ $t("tableHeaders.state") }}</div>
					<div
						class="col-sm-1"
						:class="
							sortOption.title === 'inv_datum' && sortOption.table === 1
								? 'active-th'
								: ''
						"
					>
						<div
							class="d-flex sorting align-items-center"
							@click="setInactiveSorting('inv_datum')"
						>
							<p class="me-2">{{ $t("tableHeaders.date") }}</p>
							<img
								src="@/assets/img/drop-arrow.svg"
								alt="dropdown arrow"
								:class="
									sortOption.title === 'inv_datum' &&
									sortOption.active &&
									sortOption.table === 1
										? 'active-sort'
										: 'inactive-sort'
								"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="container-fluid" v-for="inv in inactiveArticles" :key="inv">
				<div class="row table-item-b align-items-center">
					<div class="col-sm-4 d-flex align-items-center ps-0">
						<div class="space">
							<img
								src="@/assets/img/check-green.svg"
								alt="dropdown arrow"
								class="inv-checkbox"
							/>
						</div>
						<div class="product-image-container ms-1">
							<img
								v-if="inv.bild"
								:src="inv.bild"
								alt="product image"
								class="product-image"
							/>
							<img
								v-else
								src="@/assets/img/no-image.svg"
								alt="product image"
								class="product-image err-image"
							/>
						</div>

						<p class="fw-bolder ms-2">{{ inv.benamn1 }}</p>
					</div>

					<div class="col-sm-2">{{ inv.artikelnr }}</div>
					<div class="col-sm-2">{{ inv.id }}</div>

					<div class="col-sm-2">
						{{
							inv.inventerad === "J"
								? $t("inventory.states.inventoried")
								: inv.status
						}}
					</div>
					<div class="col">
						{{
							inv.inv_datum !== null
								? inv.inv_datum.substring(0, 10)
								: "xxxx-xx-xx"
						}}
					</div>
				</div>
			</div>
		</div>
	</div>

	<ScanInventory
		v-if="showInventory"
		:invType="invType"
		@closeModal="
			showInventory = false;
			scanned = '';
		"
		@updateData="getInvArticles"
		v-model="scanned"
		@update:model-value="
			() => {
				scanned = '';
				activeScan = false;
			}
		"
		:selectedRoom="invData"
	/>

	<VerificationModal
		:header="$t('tableHeaders.inventory')"
		v-if="showVerification"
		:verificationText="$t('verificationTexts.storeArticles', [setInv.length])"
		@accept="postInventoryArticles"
		@close="showVerification = false"
	/>
	<VerificationModal
		:header="$t('inventory.move')"
		v-if="showMoveVerification"
		:verificationText="
			$t('verificationTexts.moveMultipleText', [
				this.setInv.length,
				this.invData.benamning,
				this.selectedLocation.platsnamn,
			])
		"
		@close="showMoveVerification = false"
		@accept="postInventoryArticles"
	/>
	<VerificationModal
		:header="$t('inventory.storeAndChangeLocation')"
		v-if="showMoveOrderVerification"
		:verificationText="
			$t('verificationTexts.moveAndStoreMultipleText', [
				this.setInv.length,
				this.invData.benamning,
				this.selectedLocation.platsnamn,
			])
		"
		@close="showMoveOrderVerification = false"
		@accept="postInventoryArticles"
	/>

	<VerificationModal
		:header="$t('articleInfoLabels.disuse')"
		v-if="verifyDiscard"
		:verificationText="$t('verificationTexts.discardArticle', [setInv.length])"
		@close="verifyDiscard = false"
		@accept="discardArticles()"
	/>

	<ResponseModal
		v-if="discardSuccess"
		:response="1"
		:responseText="$t('responseText.discardSuccess')"
		@close="
			discardSuccess = false;
			location.reload();
		"
	/>

	<ResponseModal
		v-if="discardError"
		:response="0"
		:responseText="
			$t('responseText.discardError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="discardError = false"
	/>

	<ResponseModal
		v-if="inventorySuccess && selectedInvOption === 'I'"
		:response="1"
		:responseText="$t('responseText.inventorySuccess')"
		@close="
			inventorySuccess = false;
			getInvArticles();
		"
	/>
	<ResponseModal
		v-if="inventorySuccess && selectedInvOption === 'P'"
		:response="1"
		:responseText="$t('responseText.moveSuccess')"
		@close="
			inventorySuccess = false;
			getInvArticles();
		"
	/>

	<ResponseModal
		v-if="inventorySuccess && selectedInvOption === 'T'"
		:response="1"
		:responseText="$t('responseText.inventoryAndMoveSuccess')"
		@close="
			inventorySuccess = false;
			getInvArticles();
		"
	/>

	<ResponseModal
		v-if="inventoryError"
		:response="0"
		:responseText="$t('responseText.inventoryError')"
		@close="
			inventoryError = false;
			isLoading = false;
		"
	/>
</template>

<script>
import ResponseModal from "@/modals/ResponseModal.vue";
import Multiselect from "vue-multiselect";
import unique_api from "@/services/api/unique_api";
import inventory_api from "@/services/api/inventory_api";
import room_handle_api from "@/services/api/room_handle_api";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import ScanInventory from "@/components/inventory_components/ScanInventory.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import Popper from "vue3-popper";
import router from "@/router";

export default {
	components: {
		VerificationModal,
		ScanInventory,
		LoaderSpinner,
		RequiredWarning,
		ResponseModal,
		Multiselect,
		Popper,
	},
	props: {
		invId: { type: String, default: null },
		plats: { type: String, default: null },
	},
	data() {
		return {
			discardSuccess: false,
			discardError: false,
			showMoveOrderVerification: false,
			showMoveVerification: false,
			activeScan: true,
			showVerification: false,
			isLoading: true,
			showInventory: false,
			inactiveArticles: [],
			articles: [],
			selectedRoom: [],
			setInv: [],
			invOptions: [
				{
					name: this.$t("tableHeaders.inventory"),
					atgard: "I",
				},
				{
					name: this.$t("inventory.move"),
					atgard: "P",
				},
				{
					name: this.$t("inventory.storeAndChangeLocation"),
					atgard: "T",
				},
				{
					name: this.$t("buttonTexts.deleteProduct"),
					atgard: "K",
				},
			],
			reconditions: [
				{
					name: this.$t("list.reconditions.W.name"),
					atgard: "W",
				},
				{
					name: this.$t("list.reconditions.T.name"),
					atgard: "T",
				},
				{
					name: this.$t("list.reconditions.V.name"),
					atgard: "V",
				},
				{
					name: this.$t("list.reconditions.R.name"),
					atgard: "R",
				},
				{
					name: this.$t("list.reconditions.A.name"),
					atgard: "A",
				},
			],
			inventRole: this.$store.getters["inventRole"],

			locations: [],
			selectedInvOption: "I",
			selectedLocation: [],
			scanned: "",
			showWarning: false,
			sortOption: {
				title: "",
				active: false,
				table: 0,
			},
			invData: [],
			verifyDiscard: false,
			inventorySuccess: false,
			inventoryError: false,
			checkAllInv: false,
			invType: null,
		};
	},
	computed: {
		activeArticles() {
			for (let i = 0; i < this.articles.length; i++) {
				if (this.articles[i].farg === "success") {
					return true;
				}
			}
			return false;
		},
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		returnToRooms() {
			router.go(-1);
		},
		markRec(inv, option) {
			let index = null;
			index = this.getIndexType(inv.rec, option.atgard);
			if (index !== null) {
				inv.rec.splice(index, 1);
			} else {
				inv.rec.push({ text: null });
			}
		},
		markItem(inv) {
			let index = null;
			index = this.getIndex(inv.id);
			if (index !== null) {
				this.setInv.splice(index, 1);
			} else {
				this.setInv.push({ id: inv.id, rec: null });
			}
		},
		addRec(obj, opt) {
			obj.rec.map((o, i) => {
				if (o.type === opt.atgard) {
					obj.rec.splice(i, 1);
				} else {
					obj.rec.push({ type: opt.atgard, text: null });
				}
			});
		},
		getIndex(id) {
			let index = null;
			this.setInv.map((idX, i) => {
				if (id === idX.id) {
					index = i;
				}
			});
			return index;
		},
		getIndexType(rec, type) {
			let index = null;
			if (rec) {
				rec.map((obj, i) => {
					if (type === obj.type) {
						index = i;
					}
				});
				return index;
			}
			return null;
		},
		checkedInv(id) {
			let exists = false;
			if (this.setInv.length) {
				this.setInv.map((i) => {
					if (i.id === id) {
						exists = true;
					}
				});
			}
			return exists;
		},
		discardArticles() {
			unique_api
				.discardArticles(this.setInv)
				.then(() => {
					this.discardSuccess = true;
				})
				.catch(() => {
					this.discardError = true;
				});
		},
		setSorting(opt) {
			this.sortOption.table = 0;
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;

				if (this.sortOption.active) {
					this.articles.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.articles.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;

				this.articles.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
			}
		},

		setInactiveSorting(opt) {
			this.sortOption.table = 1;
			let sorting = opt.toLowerCase();

			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;

				if (this.sortOption.active) {
					if (sorting === "inv_datum") {
						this.inactiveArticles.sort((x, y) => {
							if (x[sorting] === "xxxx-xx-xx") {
								return -1;
							} else if (y[sorting] === "xxxx-xx-xx") {
								return 1;
							} else {
								const dateX = new Date(x[sorting]);
								const dateY = new Date(y[sorting]);
								return dateX.getTime() - dateY.getTime();
							}
						});
					} else {
						this.inactiveArticles.sort((x, y) =>
							x[sorting] < y[sorting] ? -1 : 1
						);
					}
				} else {
					this.inactiveArticles.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (sorting === "inv_datum") {
					this.inactiveArticles.sort((x, y) => {
						if (x[sorting] === "xxxx-xx-xx") {
							return -1;
						} else if (y[sorting] === "xxxx-xx-xx") {
							return 1;
						} else {
							const dateX = new Date(x[sorting]);
							const dateY = new Date(y[sorting]);
							return dateX.getTime() - dateY.getTime();
						}
					});
				} else {
					this.inactiveArticles.sort((x, y) =>
						x[sorting] < y[sorting] ? -1 : 1
					);
				}
			}
		},

		checkLocation() {
			if (this.selectedLocation !== null) {
				this.selectedInvOption === "P"
					? (this.showMoveVerification = true)
					: (this.showMoveOrderVerification = true);

				this.showWarning = false;
			} else {
				this.showWarning = true;
			}
		},
		updateCloseModal() {
			this.showInventory = false;
			this.activeScan = true;
			this.getInvArticles();
		},

		getInvArticles() {
			if (this.inventRole) {
				this.setInv = [];
				this.articles = [];
				this.inactiveArticles = [];
				this.isLoading = true;
				this.selectedInvOption = this.invOptions[0].atgard;

				inventory_api
					.getInvArticles(this.invId, this.plats)
					.then((res) => {
						this.articles = res.data.active.sort((a, b) =>
							a.farg > b.farg ? 1 : -1
						);

						this.inactiveArticles = res.data.inactive.sort((a, b) =>
							a.inv_datum > b.inv_datum ? -1 : 1
						);

						this.invData = res.data.invData[0];

						this.invType = res.data.type.typ;
						this.getLocations();
						this.isLoading = false;
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},

		getLocations: function () {
			this.locations = [];
			room_handle_api.getHandleRooms().then((res) => {
				res.data.map((loc) => {
					if (loc.plats !== Number(this.invData.plats)) {
						this.locations.push(loc);
					}
				});
			});
		},

		postInventoryArticles() {
			this.isLoading = true;
			this.showVerification = false;
			this.showMoveVerification = false;
			this.showMoveOrderVerification = false;
			inventory_api
				.postInventoryArticles(
					this.invData.plats,
					this.invData.invid,
					this.setInv,
					this.selectedInvOption
				)
				.then(() => {
					this.inventorySuccess = true;
					this.isLoading = false;
				})
				.catch(() => {
					this.inventoryError = true;
				});
		},
	},
	mounted() {
		this.selectedInvOption = this.invOptions[0].atgard;
		this.getInvArticles();

		window.addEventListener("keypress", (e) => {
			if (this.activeScan) {
				this.scanned += String.fromCharCode(e.keyCode);

				if (e.key === "Enter") {
					this.showInventory = true;
				}
			}
		});
	},
	watch: {
		clientId() {
			this.invItems = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getInvArticles();
		},
		showInventory(val) {
			if (!val) {
				this.activeScan = true;
			}
		},
		checkAllInv(bool) {
			if (bool) {
				this.articles.map((art) => {
					if (art.farg === "success") {
						this.setInv.push({ id: art.id, rec: "" });
					}
				});
			} else {
				this.setInv = [];
			}
		},
	},
};
</script>

<style scoped>
.return-wrapper {
	cursor: pointer;
	max-width: fit-content;
}
.return-arrow {
	width: 1.4rem;
}
.inv-lower {
	z-index: 4 !important;
}
.inventory-table {
	max-height: 10rem;
	overflow: auto;
}

.scan-text {
	font-size: 14px;
}
.click-span {
	text-decoration: underline;
	cursor: pointer;
}
.return-link {
	cursor: pointer;
}
.return-link:hover {
	text-decoration: underline;
}
.return-icon {
	height: 1rem;
}
.amount-span {
	font-weight: 400 !important;
}

.inv-selector {
	width: 9rem;
}
</style>

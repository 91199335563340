import axios from "axios";

const header = {
	Accept: "application/json",
	"Content-Type": "application/json",
};

export default () => {
	return axios.create({
		baseURL: process.env.VUE_APP_LARAVEL_API + "/api",
		withCredentials: false,
		headers: header,
	});
};

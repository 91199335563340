<template>
	<div class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-sm-5 gr-color pt-5 ps-5 pe-5 pb-5">
				<h4>{{ $t("buttonTexts.logIn") }}</h4>
				<form @submit.prevent="login" id="loginForm">
					<input
						type="text"
						v-model="auth.name"
						name="email"
						required
						class="input-field w-100 mt-4"
						:placeholder="$t('placeHolders.emailAdress')"
					/>
					<input
						type="password"
						v-model="auth.password"
						name="password"
						required
						class="input-field w-100 mt-4"
						:placeholder="$t('placeHolders.password')"
					/>
				</form>
				<p class="small-text mt-2" @click="verifyReset = true">
					{{ $t("textFields.haveYouForgottenThePassword") }}
				</p>
				<div
					class="mt-3 w-100 d-flex justify-content-between align-items-center"
				>
					<div class="d-flex align-items-center" v-if="showRemember">
						<input type="checkbox" />
						<p class="remember-text">{{ $t("textFields.rememberMe") }}</p>
					</div>
					<div class="standard-btn d-flex align-items-center" v-if="processing">
						<LoaderDots :text="$t('buttonTexts.pleaseWait')" />
					</div>
					<button class="standard-btn" type="submit" form="loginForm" v-else>
						{{ $t("buttonTexts.logIn") }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<ResetPasswordModal
		v-if="verifyReset"
		@accept="verifyReset = false"
		@cancel="verifyReset = false"
	/>

	<ResponseModal
		v-if="showError"
		@close="showError = false"
		:response="0"
		:responseText="$t('responseText.loginError')"
	/>
</template>

<style scoped>
.small-text {
	cursor: pointer;
}
.remember-text {
	margin-left: 0.6rem;
	font-size: 14px;
}
.standard-btn {
	width: 10rem;
}
.inlog-logo {
	height: 3rem;
	width: fit-content;
	margin-top: 2rem;
}
.login-section {
	color: black;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
}

.login-container {
	background-color: #f8f5ee;
	padding-bottom: 4rem;
	width: 50%;
	height: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 10px;
	margin-top: 10%;
}

.login-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 4rem;
}

.login-form h2 {
	margin-bottom: 1rem;
}

.input-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 1rem;
}

.gr-color {
	max-width: 30rem;
}

.input-wrapper input {
	height: 1.6rem;
	width: 100%;
	border-radius: 6px;
	border: 1px solid grey;
	font-size: 16px;
	height: 42px;
	margin-top: 0.4rem;
	padding-left: 0.4rem;
}

.m-submit-btn {
	font-size: 16px;
	font-weight: 600;
	margin-top: 1rem;
}

@media screen and (max-width: 600px) {
	.login-container {
		width: 94%;
	}
}
</style>

<script>
import ResponseModal from "@/modals/ResponseModal.vue";
import LoaderDots from "@/assets/component_assets/LoaderDots.vue";
import ResetPasswordModal from "@/components/login_components/ResetPasswordModal.vue";
import { mapActions } from "vuex";
import axios from "axios";

export default {
	name: "LoginWindow",
	components: {
		ResponseModal,
		ResetPasswordModal,
		LoaderDots,
	},
	data() {
		return {
			auth: {
				name: "",
				password: "",
			},
			processing: false,
			showError: false,
			verifyReset: false,
			showRemember: false,
			isLoading: false,
		};
	},
	methods: {
		...mapActions({
			signIn: "auth/login",
		}),

		async login() {
			this.processing = true;
			await axios.get("/sanctum/csrf-cookie").catch(() => {
				this.showError = true;
			});
			await axios
				.post("/api/login", this.auth)
				.then(({ data }) => {
					localStorage.setItem("token", data.access_token);
					this.$store.state.client = data.logged_into;
					this.signIn();
				})
				.catch(() => {
					this.showError = true;
					this.processing = false;
				})
				.finally(() => {});
		},
	},
};
</script>

<template>
	<div
		class="main-container ps-0 pe-0"
		:style="{ height: isLoading ? '10rem' : '' }"
	>
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid mb-4" v-if="roomArticles.length >= 6">
			<div class="row layout-padding">
				<div class="col-sm-6 ps-0 pe-0 d-flex">
					<div class="space"></div>
					<div class="search-bar ms-2">
						<img
							src="@/assets/img/search-icon.svg"
							alt="search icon"
							class="search-icon"
						/>
						<input
							type="text"
							class="text-input"
							:placeholder="$t('placeHolders.searchLocation')"
							v-model="searchValue"
						/>
					</div>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row align-items-center layout-padding">
				<div
					class="col-sm-3 no-wrap d-flex ps-0 pe-0"
					:class="sortOption.title === 'benamn1' ? 'active-th' : ''"
				>
					<div class="space"></div>
					<div
						class="ms-2 d-flex sorting align-items-center table-header w-100"
						@click="setSorting('benamn1')"
					>
						<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
						<img
							alt="dropdown arrow"
							src="@/assets/img/drop-arrow.svg"
							:class="
								sortOption.title === 'benamn1' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div class="col-sm-2 table-header">
					<p>{{ $t("tableHeaders.artNumber") }}</p>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'levnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('levnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.brand") }}</p>
						<img
							alt="dropdown arrow"
							src="@/assets/img/drop-arrow.svg"
							:class="
								sortOption.title === 'levnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'antal' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('antal')"
					>
						<p class="me-2">{{ $t("tableHeaders.productAmount") }}</p>
						<img
							alt="dropdown arrow"
							src="@/assets/img/drop-arrow.svg"
							:class="
								sortOption.title === 'antal' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div class="col table-header"></div>
			</div>
		</div>

		<div
			class="container-fluid main-container"
			v-for="article in filteredArticles"
			:key="article"
		>
			<div
				class="row align-items-center layout-padding"
				@click.stop="showUniques(article)"
				:class="article.active ? 'lower-sticky-header gr-color' : ''"
			>
				<div class="col-sm-3 no-wrap d-flex ps-0 pe-0">
					<div class="space"></div>
					<div
						class="ms-2 w-100 table-item"
						:style="{ borderBottom: article.active ? '0' : '' }"
					>
						<div class="product-image-container">
							<img
								v-if="article.img_url"
								:src="article.img_url"
								alt="product-image"
								class="product-image"
							/>
							<img
								v-else
								src="@/assets/img/no-image.svg"
								alt="product-image"
								class="product-image err-image"
							/>
						</div>

						<p class="fw-bold ms-2 ellipsis">{{ article.benamn1 }}</p>
					</div>
				</div>

				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.artikelnr }}
				</div>

				<div
					class="col-sm-2 ellipsis table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.levnamn }}
				</div>

				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.antal }}
				</div>

				<div
					class="col-sm-3 d-flex table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					<Popper :hover="true">
						<template #content>
							<div class="popper-content">
								<p>{{ $t("buttonTexts.showProductInfo") }}</p>
							</div>
						</template>
						<img
							src="@/assets/img/search-file.svg"
							alt="edit article image"
							class="edit-article-image me-4"
							@click.stop="openModal(article)"
						/>
					</Popper>
					<button
						class="standard-btn me-5"
						:class="
							inactiveButtons && !article.active
								? 'active-btn'
								: article.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							article.active
								? $t("buttonTexts.closeProduct")
								: $t("buttonTexts.showProduct")
						}}
					</button>
				</div>
			</div>
			<div @click.stop class="row mt-2 mb-5" v-if="article.active">
				<UniqueIds
					:idList="idList"
					@update="getRoomArticles()"
					@multiMove="$emit('multiMove', idList)"
				/>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row layout-padding">
				<div class="col-sm-12 d-flex ps-0 pe-0">
					<div class="space"></div>
					<div class="bottom-border w-100 ms-2"></div>
				</div>
			</div>
		</div>
	</div>

	<EditArticleInformation
		v-if="displayModal"
		@closeModal="displayModal = false"
		:articleData="articleData"
		@refresh="getRoomArticles()"
	/>
</template>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import UniqueIds from "./UniqueIds.vue";
import Popper from "vue3-popper";
import EditArticleInformation from "@/components/main_view_components/product_components/EditArticleInformation.vue";
import room_filter_api from "@/services/api/room_filter_api";
export default {
	props: {
		roomArticles1: Object,
	},
	components: {
		UniqueIds,
		LoaderSpinner,
		EditArticleInformation,
		Popper,
	},
	data() {
		return {
			option: "",
			uniqueIds: [],
			displayModal: false,
			articleData: [],
			searchValue: "",
			idList: [],
			isLoading: false,
			sortOption: {
				title: "",
				active: false,
			},
			roomArticles: [],
			inactiveButtons: false,
		};
	},
	emits: ["closeRoom", "multiMove"],
	computed: {
		filteredArticles() {
			if (this.searchValue.length === 0) {
				return this.roomArticles;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.roomArticles.filter((a) => {
					return (
						a.artikelnr.toLowerCase().includes(searchText) ||
						a.text_etikett.toLowerCase().includes(searchText) ||
						a.levnamn.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		getRoomArticles() {
			this.isLoading = true;
			room_filter_api.getRoomArticles(this.roomArticles1.plats).then((res) => {
				this.roomArticles = res.data;
				if (!this.roomArticles.length) {
					this.$emit("closeRoom");
				}
				this.inactiveButtons = false;
				this.isLoading = false;
			});
		},

		closeDropdown() {
			this.idList = [];
		},
		showUniques(article) {
			article.active = !article.active;
			this.idList = article;
			this.roomArticles.map((x) => {
				if (x.artikelnr !== article.artikelnr) {
					x.active = false;
				}
			});

			if (!article.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
		openModal(article) {
			this.displayModal = true;
			this.articleData = article;
		},

		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (opt !== "antal") {
					if (this.sortOption.active) {
						this.roomArticles.sort((x, y) =>
							x[sorting] < y[sorting] ? -1 : 1
						);
					} else {
						this.roomArticles.sort().reverse();
					}
				} else {
					Number(opt);
					if (this.sortOption.active) {
						this.roomArticles.sort((a, b) => a[opt] - b[opt]);
					} else {
						this.roomArticles.sort().reverse();
					}
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt !== "antal") {
					this.roomArticles.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
				} else {
					Number(opt);
					this.roomArticles.sort((a, b) => a[opt] - b[opt]);
				}
			}
		},
	},
	watch: {},
	mounted() {
		this.idList = [];
		this.roomArticles.map((x) => {
			x.active = false;
		});
		this.getRoomArticles();
	},
};
</script>
<style scoped>
.standard-btn {
	min-width: 7.5rem;
}
</style>

<template>
	<div class="d-flex w-100 align-items-center justify-content-between">
		<div
			class="d-flex align-items-center file-info"
			v-if="!showInput"
			@click="showFile()"
		>
			<img src="@/assets/img/files.svg" alt="files icon" class="me-2" />
			<p class="ellipsis">{{ text }}</p>
		</div>

		<div class="d-flex align-items-center" v-if="showInput">
			<img
				src="@/assets/img/files.svg"
				alt="files icon"
				class="me-2"
				@click="showFile()"
			/>
			<input
				type="text"
				@blur="!text.length ? $emit('update:text', dataText) : ''"
				class="file-input input-field"
				:placeholder="$t('placeHolders.changeFilename')"
				:value="text"
				@input="$emit('update:text', $event.target.value)"
			/>
		</div>

		<img
			src="@/assets/img/edit-pen.svg"
			v-if="productRole"
			alt="edit pencil"
			class="me-3 edit-pen"
			@click="showInput = !showInput"
		/>
	</div>
</template>

<script>
import doc_bank_api from "@/services/api/doc_bank_api";
export default {
	props: {
		hash: { type: String },
		version: { type: Number, default: 1 },
		editable: { type: Boolean, default: true },
		start_file: { type: File, default: null },
		text: { type: String },
	},
	emits: ["update:hash", "update:text", "deleteFile"],
	data() {
		return {
			showInput: false,
			id: null,
			dataText: null,
			productRole: this.$store.getters["productRole"],
			isLoading: false,
			content: process.env.VUE_APP_CONTENT_URL,
			uploadedFile: {},
			mime_type: null,
			startName: null,
		};
	},
	mounted() {
		this.dataText = this.text;
		if (this.start_file !== null) {
			const formData = new FormData();
			formData.append("file", this.start_file);
			doc_bank_api
				.postFile(formData)
				.then((response) => {
					this.id = response.data.hash;
					this.mime_type = response.data.mimetype;
					this.$emit("update:hash", this.id);
				})
				.catch(() => {});
		} else {
			this.id = this.hash;
		}
	},
	methods: {
		openUpload: function () {
			let fileInputElement = this.$refs.fileUploader;
			fileInputElement.click();
		},

		uploadFile() {
			this.uploadedFile = {
				file: this.$refs.fileUploader.files[0],
			};
			const formData = new FormData();
			formData.append("file", this.uploadedFile.file);
			doc_bank_api
				.postFile(formData)
				.then((response) => {
					this.id = response.data.hash;
					this.mime_type = response.data.mimetype;
					this.$emit("update:hash", this.id);
					this.isLoading = false;
				})
				.catch(() => {});
		},
		showFile: function () {
			if (this.start_file) {
				let displayedFile = URL.createObjectURL(this.start_file);
				window.open(displayedFile, "_blank");
				this.$emit("resetFile");
			} else if (this.id) {
				doc_bank_api.showFile(this.id).then((res) => {
					let blob = new Blob([res.data], {
							type: res.headers["content-type"],
						}),
						url = window.URL.createObjectURL(blob);
					window.open(url);
				});
			}
		},
	},
};
</script>

<style scoped>
.ellipsis {
	max-width: 13rem;
}
.edit-pen {
	width: 1.6rem;
	cursor: pointer;
}

.file-info {
	cursor: pointer;
	font-size: 14px;
}

.file-info:hover {
	font-weight: 500;
	transition: 0.1s;
}
.file-input {
	height: 1.8rem;
	font-size: 14px;
	width: 12rem;
	padding-left: 0.2rem;
}
</style>

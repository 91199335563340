<template>
	<div class="container-fluid sticky-header">
		<div class="row align-items-center table-header-b">
			<div
				class="col-sm-2 d-flex ps-0"
				:class="sortOption.title === 'benamn1' ? 'active-th' : ''"
			>
				<div
					class="d-flex sorting align-items-center"
					@click="setSorting('benamn1')"
				>
					<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
					<img
						src="@/assets/img/drop-arrow.svg"
						alt="dropdown arrow"
						:class="
							sortOption.title === 'benamn1' && sortOption.active
								? 'active-sort'
								: 'inactive-sort'
						"
					/>
				</div>
			</div>
			<div
				class="col-sm-2 d-flex"
				:class="sortOption.title === 'platsnamn' ? 'active-th' : ''"
			>
				<div
					class="d-flex sorting align-items-center ms-3"
					@click="setSorting('platsnamn')"
				>
					<p class="me-2">{{ $t("tableHeaders.location") }}</p>
					<img
						src="@/assets/img/drop-arrow.svg"
						alt="dropdown arrow"
						:class="
							sortOption.title === 'platsnamn' && sortOption.active
								? 'active-sort'
								: 'inactive-sort'
						"
					/>
				</div>
			</div>
			<div class="col-sm-2">
				<p>{{ $t("tableHeaders.artNumber") }}</p>
			</div>
			<div class="col-sm-2">{{ $t("articleInfoLabels.id") }}</div>

			<div
				class="col-sm-2 d-flex"
				:class="sortOption.title === 'atgard' ? 'active-th' : ''"
			>
				<div
					class="d-flex sorting align-items-center"
					@click="setSorting('atgard')"
				>
					<p class="me-2">{{ $t("tableHeaders.comment") }}</p>
					<img
						src="@/assets/img/drop-arrow.svg"
						alt="dropdown arrow"
						:class="
							sortOption.title === 'atgard' && sortOption.active
								? 'active-sort'
								: 'inactive-sort'
						"
					/>
				</div>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div
			class="row table-item-b align-items-center"
			v-for="(item, i) in filteredRecs"
			:key="item.id"
		>
			<div class="col-sm-2 ps-0 d-flex align-items-center">
				<div class="product-image-container ms-1">
					<img
						v-if="item.bild"
						:src="item.bild"
						alt="product-image"
						class="product-image"
					/>
					<img
						v-else
						src="@/assets/img/no-image.svg"
						alt="product-image"
						class="product-image err-image"
					/>
				</div>

				<p class="fw-bolder ms-2 w-75 ellipsis">{{ item.benamn1 }}</p>
			</div>
			<div class="col-sm-2">
				<p class="ms-3">{{ item.platsnamn }}</p>
			</div>
			<div class="col-sm-2">
				<p>{{ item.artikelnr }}</p>
			</div>
			<div class="col-sm-2">
				{{ item.id }}
			</div>

			<div class="col-sm-2">
				<p>{{ item.atgard }}</p>
			</div>
			<div class="col-sm-2 d-flex justify-content-end" v-if="!item.handled">
				<div class="content-ml">
					<Multiselect
						class="selector-c right-multiselect"
						:options="recOptions.map((rec) => rec.status)"
						v-model="item.atgard_status"
						:show-labels="false"
						:open-direction="i === 0 || i === 1 || i === 2 ? 'bottom' : ''"
						@select="$emit('isEditing')"
						:custom-label="
							(option) => {
								const foundOption = recOptions.find(
									(rec) => rec.status === option
								);

								return foundOption
									? foundOption.text
									: $t('placeHolders.selectAction');
							}
						"
						:placeholder="$t('placeHolders.selectAction')"
					>
						<template v-slot:caret>
							<div class="multiselect__select">
								<img
									src="@/assets/img/down-icon.svg"
									alt="dropdown icon"
								/></div
						></template>
					</Multiselect>
				</div>
			</div>
			<div class="col-sm-2 d-flex justify-content-end fst-italic" v-else>
				<p>{{ $t("inventory.states.handled") }}:</p>
				<p class="ms-1">{{ actionText(item.atgard_status) }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
	components: { Multiselect },
	emits: ["isEditing"],
	props: {
		searchValue: { value: String, default: null },
		reconditions: { value: Object, default: null },
	},
	data() {
		return {
			sortOption: {
				title: "",
				active: false,
			},
			tableReconditions: [],
			recOptions: [
				{
					text: this.$t("inventory.productIsHandled"),
					status: "A",
				},
				{
					text: this.$t("inventory.productIsDiscarded"),
					status: "K",
				},
				{
					text: this.$t("inventory.commentNotRelevant"),
					status: "E",
				},
			],
		};
	},
	computed: {
		filteredRecs() {
			if (this.searchValue.length === 0) {
				return this.tableReconditions;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.tableReconditions.filter((a) => {
					return (
						a.benamn1.toLowerCase().includes(searchText) ||
						a.artikelnr.toLowerCase().includes(searchText) ||
						a.id.toLowerCase().includes(searchText) ||
						a.atgard.toLowerCase().includes(searchText) ||
						a.platsnamn.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		actionText(status) {
			let text = "";
			if (status === "A") {
				text = this.$t("inventory.states.handled");
			} else if (status === "K") {
				text = this.$t("inventory.states.discarded");
			} else {
				text = this.$t("inventory.states.noAction");
			}
			return text;
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();

			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				this.tableReconditions.sort().reverse();
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;

				this.tableReconditions.sort((x, y) =>
					x[sorting] < y[sorting] ? -1 : 1
				);
			}
		},
	},
	mounted() {
		this.tableReconditions = this.reconditions;
		this.tableReconditions.map((rec) => {
			if (rec.atgard_status === "J") {
				rec.handled = false;
			} else {
				rec.handled = true;
			}
		});
	},
	watch: {},
};
</script>

<style scoped>
.rec-select {
	max-width: 14rem;
}
.report-wrapper .standard-btn {
	width: 10rem;
}

.amount-span {
	font-weight: 400 !important;
}

.rec-select {
	max-width: 9rem;
}
</style>

import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import "@/assets/scss/global.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.css";
import VueLazyload from "vue-lazyload";

axios.defaults.withCredentials = false;
const token = localStorage.getItem("token");
if (token) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
axios.defaults.baseURL = process.env.VUE_APP_LARAVEL_API;
const getTranslations = async () => {
	const response = await axios.get(
		`https://app-ext-api.inputinterior.se/api/get-translations/IMS`,
		{
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}
	);

	const i18n = createI18n({
		fallbackLocale: store.getters.language,

		locale:
			store.getters.language ||
			Object.keys(response.data.sv.list.domains[location.host].locales)[0] ||
			"sv",
		silentTranslationWarn: true,
		silentFallbackWarn: true,
		messages: {
			sv: response.data.sv,
			en: response.data.en,
			da: response.data.da,
			no: response.data.no,
			fi: response.data.fi,
		},
	});
	app.use(i18n);
	store.state.locale = i18n.global.locale;
	app.mount("#app");
};
const app = createApp(App);
app.use(store);
app.use(VueLazyload, {
	lazyComponent: true,
});
app.use(router);
getTranslations();

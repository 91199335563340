<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid sticky-header" v-else>
			<div class="row table-header-b align-items-center">
				<div class="col-sm-2 ps-0">
					<p>{{ $t("tableHeaders.location2") }}</p>
				</div>
				<div class="col-sm-2">
					{{ $t("tableHeaders.building") }}
				</div>
				<div class="col-sm-2">
					{{ $t("tableHeaders.floor") }}
				</div>

				<div class="col-sm-2">
					{{ $t("tableHeaders.room") }}
				</div>

				<div class="col-sm-2">
					{{ $t("tableHeaders.locationName") }}
				</div>

				<div class="col d-flex justify-content-center">
					{{ $t("tableHeaders.productAmount") }}
				</div>
			</div>
		</div>
		<div class="container-fluid" ref="container" v-if="!isLoading">
			<div
				class="row table-item-b align-items-center"
				v-for="room in filteredRooms"
				:key="room"
			>
				<template v-if="room.plats !== null">
					<div class="col-sm-2 ps-0">{{ room.enhet }}</div>
					<div class="col-sm-2">
						{{ room.hus }}
					</div>
					<div class="col-sm-2">
						{{ room.vaning }}
					</div>
					<div class="col-sm-2">
						{{ room.rum }}
					</div>
					<div class="col-sm-2">
						{{ room.platsnamn }}
					</div>

					<div class="col d-flex justify-content-center">
						{{ room.antal }}
					</div>
				</template>
				<template v-else>
					<div class="col-sm-2 ps-0">
						<input
							v-if="distinctLocations.length === 1"
							@input="isEditing = true"
							class="input-field"
							type="text"
							maxlength="100"
							v-model="room.enhet"
							:placeholder="$t('tableHeaders.location2')"
						/>
						<Multiselect
							class="selector-l left-multiselect"
							v-if="distinctLocations.length > 1"
							:options="distinctLocations.map((loc) => loc.enhet)"
							:taggable="true"
							@tag="
								(newLoc) => {
									distinctLocations.push({ enhet: newLoc });
									room.enhet = newLoc;
									isEditing = true;
								}
							"
							v-model="room.enhet"
							:show-labels="false"
							@select="isEditing = true"
							:tag-placeholder="$t('placeHolders.add')"
							:placeholder="$t('placeHolders.chooseLocation')"
							><template v-slot:caret>
								<div class="multiselect__select">
									<img
										src="@/assets/img/down-icon.svg"
										alt="dropdown icon"
									/></div></template
						></Multiselect>
					</div>
					<div class="col-sm-2">
						<input
							@input="isEditing = true"
							class="input-field no-arrows"
							type="number"
							v-model="room.hus"
							:placeholder="$t('tableHeaders.building')"
						/>
					</div>
					<div class="col-sm-2">
						<input
							@input="isEditing = true"
							class="input-field no-arrows w-50"
							type="number"
							v-model="room.vaning"
							:placeholder="$t('tableHeaders.floor')"
						/>
					</div>
					<div class="col-sm-2">
						<input
							@input="isEditing = true"
							class="input-field"
							type="text"
							maxlength="12"
							v-model="room.rum"
							:placeholder="$t('tableHeaders.room')"
						/>
					</div>
					<div class="col-sm-2 d-flex align-items-center">
						<input
							@input="isEditing = true"
							class="input-field"
							type="text"
							maxlength="100"
							v-model="room.platsnamn"
							:placeholder="$t('tableHeaders.locationName')"
						/>
						<RequiredWarning
							v-if="showWarning && !room.platsnamn.length"
							:warningText="$t('buttonTexts.locationRequired')"
							class="ms-1"
						/>
					</div>

					<div class="col-sm-2 d-flex justify-content-center">
						<button class="active-btn" @click="checkValidValue()">
							{{ $t("buttonTexts.saveRoom") }}
						</button>
					</div>
				</template>
			</div>
		</div>
	</div>

	<VerificationModal
		:header="$t('buttonTexts.save')"
		v-if="saveVerification"
		:verificationText="$t('verificationTexts.addRoom')"
		@close="saveVerification = false"
		@accept="updateRooms()"
	/>

	<ResponseModal
		v-if="editFail"
		@close="editFail = false"
		:response="0"
		:responseText="
			$t('responseText.roomUpdateError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
	/>
</template>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import Multiselect from "vue-multiselect";
import room_handle_api from "@/services/api/room_handle_api";
import ResponseModal from "@/modals/ResponseModal.vue";
import router from "@/router";

export default {
	components: {
		VerificationModal,
		LoaderSpinner,
		RequiredWarning,
		Multiselect,
		ResponseModal,
	},
	emits: ["isEditing"],
	props: { searchValue: { type: String, default: "" } },
	data() {
		return {
			lastIndex: null,
			editFail: false,
			rooms: [],
			isLoading: true,
			inventRole: this.$store.getters["inventRole"],
			validValue: false,
			isEditing: false,
			saveVerification: false,
			showWarning: false,
			distinctLocations: [],
		};
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredRooms() {
			if (this.searchValue.length === 0) {
				return this.rooms;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.rooms.filter((r) => {
					return (
						r.hus.toLowerCase().includes(searchText) ||
						r.platsnamn.toLowerCase().includes(searchText) ||
						r.rum.toLowerCase().includes(searchText) ||
						r.enhet.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		addRoom() {
			if (!this.isLoading) {
				this.rooms.push({
					antal: null,
					hus: "",
					plats: null,
					platsnamn: "",
					rum: "",
					vaning: "",
					enhet: "",
				});
				setTimeout(() => {
					window.scrollTo(0, document.body.scrollHeight);
				}, 1);
			}
		},

		getHandleRooms: function () {
			if (this.inventRole) {
				this.isLoading = true;
				room_handle_api
					.getHandleRooms()
					.then((res) => {
						this.rooms = res.data;
						this.lastIndex = this.rooms.length;

						this.rooms.map((r) => {
							Object.keys(r).find((key) => {
								if (r[key] === null) {
									r[key] = "";
								}
							});
						});

						this.isEditing = false;
						this.saveVerification = false;
						this.showWarning = false;
						this.isLoading = false;
						this.addRoom();
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},

		getDistinctLocations() {
			room_handle_api.getDistinctLocations().then((res) => {
				this.distinctLocations = res.data;
			});
		},
		checkValidValue() {
			let validLocName = true;
			for (let i = this.rooms.length - 1; i > 0; i--) {
				if (this.rooms[i].platsnamn === "") {
					this.showWarning = true;
					validLocName = false;
				}
			}
			if (validLocName) {
				this.saveVerification = true;
				this.showWarning = false;
			}
		},

		updateRooms: function () {
			this.saveVerification = false;
			room_handle_api
				.updateRooms(this.rooms)
				.then(() => {
					this.getHandleRooms();
				})
				.catch(() => {
					this.editFail = true;
				});
		},
	},
	mounted() {
		this.getHandleRooms();
		this.getDistinctLocations();
	},
	watch: {
		clientId() {
			this.rooms = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getHandleRooms();
		},

		editFail(bool) {
			if (!bool) {
				document.body.classList.remove("modal-open");
			}
		},

		saveVerification(bool) {
			if (!bool) {
				document.body.classList.remove("modal-open");
			}
		},

		isEditing(val) {
			this.$emit("isEditing", val);
		},
	},
};
</script>

<template>
	<GrModalSmall :cancel="cancel">
		<template v-slot:modal-header-title>
			<div class="row">
				<div class="col">
					<h5 class="fw-bold fs-6">{{ $t("modalLabels.orderProduct") }}</h5>
				</div>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="row">
				<div class="col-sm-12 d-flex align-items-center">
					<p class="fw-bolder small-text me-2">
						* {{ $t("tableHeaders.amount") }}
					</p>
					<RequiredWarning
						v-if="showWarning"
						:warningText="$t('warningTexts.minOneArticleAmount')"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12">
					<input
						class="input-field w-100"
						:placeholder="$t('placeHolders.enterAmount')"
						type="number"
						@input="isEditing = true"
						v-model="order.amount"
					/>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-sm-12">
					<p class="fw-bolder small-text">
						{{ $t("placeHolders.enterOrderNumber") }}
					</p>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12">
					<input
						class="input-field w-100 no-arrows"
						:placeholder="$t('placeHolders.enterOrderNumber')"
						@input="isEditing = true"
						type="number"
						v-model="order.num"
					/>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-sm-12 mb-2">
					<p class="fw-bolder small-text">
						{{ $t("modalLabels.comment") }}
					</p>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12 mb-4">
					<input
						class="input-field w-100"
						:placeholder="$t('placeHolders.enterComment')"
						type="text"
						@input="isEditing = true"
						v-model="order.comment"
					/>
				</div>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="row">
				<div class="col-sm-12 d-flex justify-content-between">
					<button
						class="standard-btn me-2"
						@click="!isEditing ? (cancel = true) : (closeVerification = true)"
					>
						{{ $t("buttonTexts.cancel") }}
					</button>
					<button class="active-btn no-wrap" @click="controlAmount()">
						{{ $t("modalLabels.sendOrder") }}
					</button>
				</div>
			</div>
		</template>
		<template v-slot:modal-slot>
			<VerificationModal
				:header="$t('verificationTexts.headers.order')"
				v-if="showVerification"
				:verificationText="
					$t('verificationTexts.orderArticle', [this.order.amount])
				"
				@close="showVerification = false"
				@accept="postOrder()"
			/>
			<VerificationModal
				:header="$t('verificationTexts.headers.exit')"
				:verificationText="$t('verificationTexts.closeChanges')"
				v-if="closeVerification"
				@accept="cancel = true"
				@close="closeVerification = false"
			/>
			<ResponseModal
				v-if="orderSuccess"
				:response="1"
				:responseText="$t('responseText.orderSuccessful')"
				@close="
					orderSuccess = false;
					close = true;
				"
			/>

			<ResponseModal
				v-if="orderError"
				:response="0"
				:responseText="
					$t('responseText.orderError') +
					' ' +
					$t('responseText.contactSystemAdministrator')
				"
				@close="orderError = false"
			/>
		</template>
	</GrModalSmall>
</template>

<script>
import unique_api from "@/services/api/unique_api";
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import GrModalSmall from "@/modals/GrModalSmall.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
export default {
	data() {
		return {
			closeVerification: false,
			order: {
				amount: 1,
				num: "",
				comment: "",
			},
			errorMsg: false,
			showVerification: false,
			orderError: false,
			orderSuccess: false,
			cancel: false,
			showWarning: false,
			isEditing: false,
		};
	},
	props: {
		articleData: Object,
	},

	components: {
		ResponseModal,
		VerificationModal,
		GrModalSmall,
		RequiredWarning,
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		controlAmount() {
			if (this.order.amount <= 0) {
				this.showWarning = true;
			} else {
				this.showVerification = true;
			}
		},
		postOrder() {
			this.showVerification = false;
			unique_api
				.postOrder(this.order, this.articleData.artikelnr)
				.then(() => {
					this.orderSuccess = true;
					this.isEditing = false;
				})
				.catch(() => {
					this.orderError = true;
				});
		},
	},
	mounted() {},
	watch: {
		orderError() {
			document.body.classList.add("modal-open");
		},
		orderSuccess() {
			document.body.classList.add("modal-open");
		},
	},
};
</script>

<style scoped></style>

<template>
	<Popper :hover="true">
		<template #content>
			<div class="req-text-container">
				<p class="req-text">{{ warningText }}</p>
			</div>
		</template>
		<img
			src="@/assets/img/warning-logo.svg"
			alt="warning image"
			class="warning-img"
		/>
	</Popper>
</template>

<script>
import Popper from "vue3-popper";
export default {
	components: {
		Popper,
	},
	props: {
		warningText: String,
	},
};
</script>
<style scoped>
.warning-img {
	width: 1.2rem;
}

.req-text-container {
	min-width: 8rem;
	max-width: 14rem;
	padding: 0.4rem;
	min-height: 2.4rem;
	background-color: rgb(255, 255, 255);
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-size: 14px;
}

.req-text {
	color: red;
	max-width: 100%;
	white-space: normal;
}
</style>

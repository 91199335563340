<template>
	<div
		class="custom-select"
		v-if="optKey !== null"
		:tabindex="tabindex"
		@blur="open = false"
	>
		<div
			class="selected"
			:id="bgColor"
			:class="{ open: open }"
			@click="open = !open"
		>
			<p>{{ selected[optKey] }}</p>
			<img
				src="@/assets/img/drop-arrow.svg"
				alt="dropdown arrow"
				class="dropdown-arrow"
			/>
		</div>
		<div
			class="items"
			:style="{ backgroundColor: bgColor }"
			:class="{ selectHide: !open }"
		>
			<template v-for="(option, i) of options" :key="i">
				<div
					@click="
						selected = option;
						open = false;
						$emit('selectedOption', option);
					"
					v-if="option[optKey] !== selected[optKey]"
				>
					<p>
						{{ option[optKey] }}
					</p>
				</div>
			</template>
		</div>
	</div>
	<div
		class="custom-select"
		v-else-if="optKey === null"
		:tabindex="tabindex"
		@blur="open = false"
	>
		<div
			class="selected"
			:id="bgColor"
			:class="{ open: open }"
			@click="open = !open"
		>
			<p>{{ selected }}</p>
			<img
				src="@/assets/img/drop-arrow.svg"
				alt="dropdown-arrow"
				class="dropdown-arrow"
			/>
		</div>
		<div
			class="items"
			:style="{ backgroundColor: bgColor }"
			:class="{ selectHide: !open }"
		>
			<template v-for="(option, i) of options" :key="i">
				<div
					@click="
						selected = option;
						open = false;
						$emit('selectedOption', option);
					"
					v-if="options !== selected"
				>
					<p>
						{{ option }}
					</p>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	emits: ["selectedOption"],
	props: {
		bgColor: {
			type: String,
			default: "#f8f5ee",
		},
		reducedKey: {
			type: String,
			default: null,
		},
		optKey: {
			type: String,
			default: null,
		},
		default: {
			type: [Object, String],
			required: false,
			default: null,
		},
		options: {
			type: Array,
			required: true,
		},

		tabindex: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	data() {
		return {
			selected: this.default
				? this.default
				: this.options.length > 0
				? this.options[0]
				: null,
			open: false,
		};
	},
	methods: {},
};
</script>

<style scoped>
.custom-select {
	position: relative;
	text-align: left;
	outline: none;
	font-weight: 600;
	width: 8rem;
	font-size: 14px;
	white-space: nowrap;
}

.custom-select .selected {
	background-color: #f8f5ee;

	border: 1px solid transparent;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.selected p {
	min-width: 2rem;
	max-width: 6rem;
}

.dropdown-arrow {
	margin-left: 0.5rem;
}

.custom-select .items {
	padding-top: 0.6rem;
	overflow: hidden;
	width: 100%;
	position: absolute;
	left: 0;
	z-index: 9;
	padding-bottom: 0.6rem;
	padding-right: 0.4rem;
}

.custom-select .items div {
	padding-top: 0.4rem;
	padding-left: 0.5rem;
	cursor: pointer;
	user-select: none;
}

.custom-select .items div:hover {
	text-decoration: underline;
}

.selectHide {
	display: none;
}
</style>

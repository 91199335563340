import api from "@/services/api";
import api_multiform from "@/services/api_multiform";

export default {
	getClaimTitles() {
		return api().get("/claim/titles");
	},

	postClaim(id, form) {
		return api_multiform().post(`/claim/${id}`, form);
	},
};

<template>
	<div
		class="custom-select"
		v-if="optKey !== null"
		:tabindex="tabindex"
		@blur="open = false"
	>
		<div
			class="selected"
			:id="bgColor"
			:class="{ open: open }"
			@click="open = !open"
		>
			<p>{{ selected[optKey] }}</p>
			<img
				src="@/assets/img/drop-arrow-small.svg"
				alt="dropdown-arrow"
				class="dropdown-arrow"
			/>
		</div>
		<div
			class="items"
			:style="{ backgroundColor: bgColor }"
			:class="{ selectHide: !open }"
		>
			<div
				v-for="(option, i) of options"
				:key="i"
				@click="
					selected = option;
					open = false;
					$emit('selectedOption', option);
				"
			>
				{{ option[optKey] }}
			</div>
		</div>
	</div>
	<div class="custom-select" v-else :tabindex="tabindex" @blur="open = false">
		<div
			class="selected"
			:id="bgColor"
			:class="{ open: open }"
			@click="open = !open"
		>
			<p>{{ selected }}</p>
			<img
				src="@/assets/img/drop-arrow-small.svg"
				alt="dropdown-arrow"
				class="dropdown-arrow"
			/>
		</div>
		<div
			class="items"
			:style="{ backgroundColor: bgColor }"
			:class="{ selectHide: !open }"
		>
			<div
				v-for="(option, i) of options"
				:key="i"
				@click="
					selected = option;
					open = false;
					$emit('selectedOption', option);
				"
			>
				{{ option }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	emits: ["selectedOption"],
	props: {
		bgColor: {
			type: String,
			default: "#333",
		},
		reducedKey: {
			type: String,
			default: null,
		},
		optKey: {
			type: String,
			default: null,
		},
		default: {
			type: [Object, String],
			required: false,
			default: null,
		},
		options: {
			type: Array,
			required: true,
		},

		tabindex: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	data() {
		return {
			selected: this.default
				? this.default
				: this.options.length > 0
				? this.options[0]
				: null,
			open: false,
		};
	},
	methods: {},
};
</script>

<style scoped>
.custom-select {
	position: relative;
	text-align: left;
	outline: none;
	font-weight: 500;
	white-space: nowrap;
	font-size: 12.5px;
	min-width: max-content;
}

.custom-select:hover {
	text-decoration: underline;
}

.custom-select .selected {
	border-radius: 6px;
	border: 1px solid transparent;
	padding-left: 0.3rem;
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
}

.selected p {
	min-width: 1rem;
	max-width: 6rem;
}

.dropdown-arrow {
	margin-left: 0.5rem;
}

.custom-select .selected.open {
	border-radius: 6px 6px 0px 0px;
}

#white:after {
	border-color: #333 transparent transparent transparent !important;
}

#black:after {
	border-color: white transparent transparent transparent !important;
}

.custom-select .items {
	margin-top: 0.6rem;
	border-radius: 0px 0px 6px 6px;
	overflow: hidden;
	position: absolute;
	min-width: 3.4rem;
	left: 0;
	z-index: 10;
	min-width: 100%;
}

.custom-select .items div {
	padding: 0.4rem;
	cursor: pointer;
	user-select: none;
}

.custom-select .items div:hover {
	background-color: #eed8a6;
	color: black;
}

.selectHide {
	display: none;
}
</style>

import api from "@/services/api";

export default {
	getActiveRecs(invid) {
		return api().get(`/reconditions/${invid}`);
	},
	getInvName(invid) {
		return api().get(`/reconditions/${invid}/show`);
	},
	getInactiveRecs(invid) {
		return api().get(`/reconditions/${invid}/inactive`);
	},

	postRecState(invid, recs) {
		return api().post(`/reconditions/${invid}`, JSON.stringify({ recs: recs }));
	},

	getReport(invid) {
		return api().get(`/reconditions/${invid}/pdf`, {
			responseType: "arraybuffer",
		});
	},
};

import api from "@/services/api";

export default {
	getEditArticle(artikelnr) {
		return api().get(`/uniqueArticle/${artikelnr}`);
	},

	postArticleData(data) {
		return api().post("/uniqueArticle/media", JSON.stringify({ data: data }));
	},
};

import api from "@/services/api";
import api_file_json from "@/services/api_file_json";

export default {
	showFile(hash) {
		return api().get(`/media/${hash}`, {
			responseType: "arraybuffer",
		});
	},

	postImage(formData) {
		return api_file_json().post("/media/image", formData);
	},
	postFile(formData) {
		return api_file_json().post("/media/file", formData);
	},
};

<template>
	<div class="modal-wrapper">
		<Transition name="fade">
			<div class="small-modal-container gr-color" v-if="showContent">
				<div class="container-fluid">
					<slot name="modal-header-title"></slot>
				</div>

				<div class="container-fluid small-modal-content">
					<slot name="modal-content"></slot>
				</div>

				<div class="container-fluid button-container">
					<slot name="button-container"></slot>
				</div>
			</div>
		</Transition>
		<slot name="modal-slot"></slot>
	</div>
</template>

<script>
export default {
	emits: ["cancel"],
	data() {
		return {
			showContent: false,
		};
	},
	props: { cancel: { type: Boolean, default: false } },
	methods: {
		closeModal() {
			this.showContent = false;
			window.setTimeout(() => {
				this.$emit("cancel");
			}, 200);
		},
	},
	mounted() {
		this.showContent = true;
		this.$store.state.modalActive = true;
		document.body.classList.add("modal-open");
	},
	watch: {
		cancel(bool) {
			if (bool) {
				this.closeModal();
				document.body.classList.remove("modal-open");
			}
		},
	},
	beforeUnmount() {
		this.$store.state.modalActive = false;
	},
};
</script>

<style scoped>
.small-modal-content {
	height: 16rem;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.close-icon {
	width: 1.2rem;
}
.close-icon:hover {
	transform: scale(1.1);
	transition: 0.1s;
	cursor: pointer;
}

.small-modal-container {
	padding: 2rem;
	width: 24rem;
	height: 26rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media screen and (max-width: 1150px) {
	.small-modal-container {
		width: 98%;
	}
}
</style>

import axios from "axios";
import router from "../router/index";
import store from "./index";
export default {
	namespaced: true,
	state: {
		authenticated: false,
		user: {},
	},
	getters: {
		authenticated(state) {
			return state.authenticated;
		},
		user(state) {
			return state.user;
		},
	},
	mutations: {
		SET_AUTHENTICATED(state, value) {
			state.authenticated = value;
		},
		SET_USER(state, value) {
			state.user = value;
		},
	},
	actions: {
		handleBackendError({ dispatch }) {
			dispatch("logout");
			router.push({ path: "/login" });
		},

		login({ commit }) {
			const token = localStorage.getItem("token");
			if (token) {
				axios.defaults.headers.common["Authorization"] = "Bearer " + token;
			}
			return axios
				.get("api/" + store.state.language + "/user")
				.then(({ data }) => {
					commit("SET_USER", data);
					commit("SET_AUTHENTICATED", true);
					router.push({ path: "/" });
				})
				.catch(() => {
					commit("SET_USER", {});
					commit("SET_AUTHENTICATED", false);
				});
		},
		logout({ commit }) {
			commit("SET_USER", {});
			commit("SET_AUTHENTICATED", false);
		},
	},
};

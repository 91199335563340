<template>
	<div class="loader-wrapper" :style="{ backgroundColor: bgColor }">
		<div class="spring-spinner">
			<div class="spring-spinner-part top">
				<div class="spring-spinner-rotator"></div>
			</div>
			<div class="spring-spinner-part bottom">
				<div class="spring-spinner-rotator"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderSpinner",
	props: {
		bgColor: { value: String, default: "transparent" },
	},
};
</script>

<style scoped>
.loader-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 8;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spring-spinner,
.spring-spinner * {
	box-sizing: border-box;
}

.spring-spinner {
	height: 60px;
	width: 60px;
	position: sticky;
}

.spring-spinner .spring-spinner-part {
	overflow: hidden;
	height: calc(60px / 2);
	width: 60px;
}

.spring-spinner .spring-spinner-part.bottom {
	transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
	width: 60px;
	height: 60px;
	border: calc(60px / 7) solid transparent;
	border-right-color: #333;
	border-top-color: #333;
	border-radius: 50%;
	box-sizing: border-box;
	animation: spring-spinner-animation 3s ease-in-out infinite;
	transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
	0% {
		border-width: calc(60px / 7);
	}
	25% {
		border-width: calc(60px / 23.33);
	}
	50% {
		transform: rotate(115deg);
		border-width: calc(60px / 7);
	}
	75% {
		border-width: calc(60px / 23.33);
	}
	100% {
		border-width: calc(60px / 7);
	}
}
</style>

<template>
	<GrModalLarge
		@validate="validateClose"
		ref="largeModal"
		:grColor="true"
		@closeModal="$emit('closeModal')"
	>
		<template v-slot:modal-header-title>
			<div class="row">
				<div class="col-sm-3 mb-2 d-flex">
					<h5 class="fw-bold fs-5">{{ articleData.benamn1 }}</h5>
				</div>
			</div>
		</template>
		<template v-slot:modal-subheader>
			<div class="col">
				<p class="small-text">
					{{ $t("tableHeaders.artText") }}: {{ articleData.text_etikett }}
				</p>
			</div>
		</template>

		<template v-slot:modal-content>
			<div class="main-container">
				<LoaderSpinner v-if="isLoading" :bgColor="'#f8f5ee'" />

				<div class="row pe-0">
					<div class="col-sm-3">
						<div class="left-container">
							<div class="main-image-wrapper">
								<img
									v-if="isLoading === false && imageIndex !== null"
									:src="`${content}${artData.media[imageIndex].hash}`"
									alt="main-image"
									class="main-image"
								/>
								<div
									class="no-image d-flex justify-content-center align-items-center"
									v-else
								>
									<p>
										{{ $t("textFields.missingImage") }}
									</p>
								</div>
								<Popper class="popper-top" :hover="true" offsetDistance="80">
									<template #content>
										<div class="popper-content">
											<p>{{ $t("textFields.selectedMainImage") }}</p>
										</div>
									</template>
									<img
										v-if="isLoading === false && imageIndex === 0"
										src="@/assets/img/check-green.svg"
										class="img-checkbox"
										alt="img-checkbox"
									/>
								</Popper>

								<Popper class="popper-top" :hover="true" v-if="productRole">
									<template #content>
										<div class="popper-content">
											<p>{{ $t("textFields.selectAsMainImage") }}</p>
										</div>
									</template>

									<input
										v-if="
											isLoading === false &&
											imageIndex !== 0 &&
											imageIndex !== null
										"
										type="checkbox"
										class="img-checkbox right-position"
										:true-value="0"
										:false-value="imageIndex"
										v-model="artData.media[imageIndex].sort"
										@change="
											isEditing = true;
											changeOrder(artData.media[imageIndex]);
										"
									/>
								</Popper>

								<form
									action="/action_page.php"
									enctype="multipart/form-data"
									class="gallery-form w-100"
								>
									<label for="image-upload">
										<div
											class="d-flex add-container add-img"
											v-if="productRole"
										>
											<img
												src="@/assets/img/add-icon.svg"
												alt="add-icon"
												class="add-icon"
											/>
											<p class="small-text ms-1">
												{{ $t("buttonTexts.addNew") }}
											</p>
										</div>
									</label>
									<input
										id="image-upload"
										type="file"
										name="imagename"
										multiple
										@input="uploadImage"
										ref="image"
										accept="image/png, image/jpeg"
									/>
								</form>
							</div>
						</div>
					</div>
					<div class="col ps-0 pe-0">
						<div
							class="d-flex justify-content-between align-items-start bottom-border pb-2"
						>
							<div class="col-sm-3">
								<p class="fw-bold">{{ $t("tableHeaders.productName") }}</p>
							</div>
							<div class="col-sm-2">
								<p class="fw-bold">{{ $t("tableHeaders.artNumber") }}</p>
							</div>
							<div class="col-sm-3">
								<p class="fw-bold">{{ $t("tableHeaders.brand") }}</p>
							</div>
							<div class="col">
								<p class="fw-bold">{{ $t("tableHeaders.productAmount") }}</p>
							</div>
						</div>
						<div class="d-flex justify-content-between mt-3 align-items-center">
							<div class="col-sm-3 fw-bold">
								<p class="ellipsis pe-2">{{ articleData.benamn1 }}</p>
							</div>
							<div class="col-sm-2">
								<p>{{ articleData.artikelnr }}</p>
							</div>
							<div class="col-sm-3">
								<p>{{ articleData.levnamn }}</p>
							</div>
							<div class="col">
								<p>{{ articleData.antal }}</p>
							</div>
							<div class="col-sm-3 d-flex justify-content-end">
								<button
									class="active-btn"
									@click="showOrderModal = true"
									v-if="changeRole"
								>
									{{ $t("modalLabels.orderProduct") }}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="row pe-0">
					<div class="col-sm-3">
						<div
							v-if="productRole"
							class="image-gallery left-container"
							item-key="hash"
							@change="changeOrder"
						>
							<div
								v-for="(img, i) in artData.media"
								:key="img"
								class="gal-img-wrapper"
								@click="setImage(img, i)"
								@mouseover="showDelete = img.hash"
								@mouseleave="showDelete = null"
								:style="{ border: img.active ? '1.5px solid black' : '' }"
							>
								<img
									src="@/assets/img/delete-cross.svg"
									alt="delete image icon"
									class="delete-img-icon"
									@click.stop="deleteImage(img)"
									v-if="showDelete === img.hash"
								/>
								<ImageLoadComponent
									v-model="img.hash"
									:start_file="img.start_image"
									:editable="false"
									@updated="checkDuplicates"
								></ImageLoadComponent>
							</div>
						</div>
						<div class="image-gallery left-container" v-else>
							<div
								class="gal-img-wrapper"
								v-for="(img, i) in artData.media"
								@click="setImage(img, i)"
								:key="img"
								:style="{ border: img.active ? '1.5px solid black' : '' }"
							>
								<ImageLoadComponent
									v-model="img.hash"
									:start_file="img.start_image"
									:editable="false"
									@updated="checkDuplicates"
								></ImageLoadComponent>
							</div>
						</div>
					</div>
					<div
						class="col-sm-9 scrollable-container ps-0"
						ref="container"
						:class="scrollbarVisible ? 'pe-0' : 'pe-2'"
					>
						<p class="fw-bold bottom-border pb-2" v-if="articleData.text">
							{{ $t("textFields.text") }}
						</p>
						<p class="mt-3 mb-3" v-if="articleData.text">
							{{ articleData.text }}
						</p>
						<div class="d-flex justify-content-between bottom-border pb-2 mt-5">
							<div class="col-sm-5">
								<p class="fw-bold">
									{{ $t("articleInfoLabels.productIsInFollowingRooms") }}
								</p>
							</div>

							<div class="col-sm-5">
								<p class="fw-bold">{{ $t("tableHeaders.productAmount") }}</p>
							</div>
						</div>

						<div
							class="table-item-b d-flex justify-content-between align-items-center"
							v-for="room in artData.rooms"
							:key="room"
						>
							<div class="col-sm-5">
								{{ room.platsnamn }}
							</div>

							<div class="col-sm-5">
								<p class="">{{ room.antal }}</p>
							</div>
						</div>
						<div
							class="links-files-wrapper mt-5 d-flex justify-content-between"
						>
							<div class="col-sm-5">
								<div
									class="d-flex justify-content-between align-items-center border-bottom pb-2"
								>
									<p class="fw-bold">{{ $t("modalLabels.links") }}</p>
									<div class="d-flex add-container me-2" v-if="productRole">
										<img
											src="@/assets/img/add-icon.svg"
											alt="add icon"
											class="add-icon"
										/>
										<p @click="showLinkModal = true" class="small-text ms-1">
											{{ $t("buttonTexts.addNew") }}
										</p>
									</div>
								</div>

								<div class="links-wrapper">
									<div
										class="d-flex justify-content-between align-items-center link-item border-bottom"
										v-for="(link, i) in artData.links"
										:key="link"
									>
										<a :href="`${link.link}`" class="link" target="_blank">
											{{ link.text }}
										</a>
										<img
											v-if="productRole"
											src="@/assets/img/delete-cross.svg"
											alt="delete cross"
											class="delete-icon me-3"
											@click.stop="deleteLink(link, i)"
										/>
									</div>
								</div>
							</div>
							<div class="col-sm-5">
								<div
									class="d-flex justify-content-between align-items-center border-bottom pb-2"
								>
									<p class="fw-bold">{{ $t("modalLabels.files") }}</p>
									<div class="d-flex add-container"></div>
									<form
										v-if="productRole"
										action="/action_page.php"
										enctype="multipart/form-data"
										class="gallery-form"
									>
										<label for="file-upload" class="add-container d-flex me-2">
											<img
												src="@/assets/img/add-icon.svg"
												alt="add icon"
												class="add-icon"
											/>
											<p class="small-text ms-1">
												{{ $t("buttonTexts.addNew") }}
											</p>
										</label>
										<input
											id="file-upload"
											type="file"
											name="filename"
											multiple
											@change="uploadFile"
											ref="file"
										/>
									</form>
								</div>

								<div class="links-wrapper">
									<div
										class="d-flex justify-content-between link-item border-bottom"
										v-for="(fileX, i) in artData.files"
										:key="fileX.hash"
									>
										<DocLoadComponent
											:start_file="start_file"
											v-model:hash="fileX.hash"
											v-model:text="fileX.text"
											@update:text="isEditing = true"
											@resetFile="start_file = null"
										></DocLoadComponent>
										<img
											v-if="productRole"
											src="@/assets/img/delete-cross.svg"
											alt="delete cross"
											class="delete-icon me-3"
											@click="deleteFile(i)"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4 align-items-start">
					<div class="col-sm-3"></div>
					<div class="col"></div>
				</div>
				<AddLink
					v-if="showLinkModal"
					@cancel="showLinkModal = false"
					@add="setLink"
				/>
				<OrderProduct
					:articleData="articleData"
					v-if="showOrderModal"
					@cancel="showOrderModal = false"
				/>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="row mt-3 ps-0 pe-0 align-items-center">
				<div class="col-sm-3 ps-0"></div>
				<div
					class="col-sm-9 d-flex align-items-center justify-content-end btns-wrapper"
					v-if="productRole"
				>
					<button
						class="standard-btn me-3"
						@click="isEditing ? (showReset = true) : noChanges()"
					>
						{{ $t("buttonTexts.reset") }}
					</button>

					<button class="active-btn no-wrap" v-if="!isEditing">
						{{ $t("buttonTexts.noChanges") }}
					</button>
					<button class="active-btn no-wrap" v-else @click="showSave = true">
						{{ $t("buttonTexts.saveChanges") }}
					</button>
				</div>
			</div>
		</template>
	</GrModalLarge>
	<VerificationModal
		:headers="$t('verificationTexts.headers.exit')"
		:verificationText="$t('verificationTexts.closeChanges')"
		v-if="closeVerification"
		@accept="$refs.largeModal.closeModal()"
		@close="closeVerification = false"
	/>
	<VerificationModal
		:header="$t('buttonTexts.save')"
		v-if="showSave"
		:verificationText="$t('verificationTexts.saveChanges')"
		@close="showSave = false"
		@accept="postArticleData()"
	/>
	<VerificationModal
		:header="$t('buttonTexts.reset')"
		v-if="showReset"
		:verificationText="$t('verificationTexts.resetChanges')"
		@close="showReset = false"
		@accept="getEditArticle()"
	/>

	<ResponseModal
		v-if="showSuccess"
		:response="1"
		:responseText="$t('responseText.updateSuccess')"
		@close="showSuccess = false"
	/>
	<ResponseModal
		v-if="showError"
		:response="0"
		:responseText="
			$t('responseText.updateError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="showError = false"
	/>
</template>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import GrModalLarge from "@/modals/GrModalLarge.vue";
import article_editor_api from "@/services/api/article_editor_api";
import VerificationModal from "@/modals/VerificationModal.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import OrderProduct from "./OrderProduct.vue";
import AddLink from "./AddLink.vue";
import ImageLoadComponent from "@/assets/component_assets/ImageLoadComponent.vue";
import DocLoadComponent from "@/assets/component_assets/DocLoadComponent.vue";
import Popper from "vue3-popper";

export default {
	data() {
		return {
			closeVerification: false,
			drag: false,
			orderError: false,
			orderSuccess: false,
			showSuccess: false,
			showError: false,
			showVerification: false,
			isLoading: true,
			isEditing: false,
			content: process.env.VUE_APP_CONTENT_URL,
			image: null,
			start_file: null,
			file: [],
			artData: [],
			uploadedLinks: [],
			verificationText: "",
			changeImage: false,
			errorMsg: false,
			showReset: false,
			showSave: false,
			showUnsaved: false,
			showOrderModal: false,
			changeRole: this.$store.getters["changeRole"],
			productRole: this.$store.getters["productRole"],
			imageIndex: null,
			fileTextError: false,
			showLinkModal: false,
			showDelete: null,
			showNoChanges: false,
		};
	},
	components: {
		VerificationModal,
		ResponseModal,
		GrModalLarge,
		ImageLoadComponent,
		DocLoadComponent,
		OrderProduct,
		AddLink,
		LoaderSpinner,
		Popper,
	},
	emits: ["closeModal", "refresh"],
	props: {
		articleData: Object,
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		scrollbarVisible() {
			let scrollCount = null;
			if (!this.isLoading) {
				let container = this.$refs.container;
				scrollCount = container.scrollHeight < container.clientHeight;
			}
			return scrollCount;
		},
	},
	methods: {
		checkDuplicates() {
			const uniqueMedia = this.artData.media.reduce((acc, current) => {
				if (!acc.some((item) => item.hash === current.hash)) {
					acc.push(current);
				}
				return acc;
			}, []);

			this.artData.media = uniqueMedia;
		},
		validateClose() {
			if (!this.isEditing) {
				this.$refs.largeModal.closeModal();
			} else {
				this.closeVerification = true;
			}
		},
		changeOrder(image) {
			this.imageIndex = 0;
			if (image.sort === 0 && image.active) {
				this.artData.media.sort(function (x, y) {
					return x === image ? -1 : y === image ? 1 : 0;
				});

				this.artData.media.map((art, i) => {
					if (art.hash !== image.hash) {
						art.sort = i;
					}
				});
			}
		},
		noChanges() {
			this.showNoChanges = true;
			window.setTimeout(() => {
				this.showNoChanges = false;
			}, 2000);
		},

		setImage(img, index) {
			img.active = true;

			this.imageIndex = index;

			for (let i = 0; i < this.artData.media.length; i++) {
				if (i !== index) {
					this.artData.media[i].active = false;
				} else {
					this.imageIndex = i;
				}
			}
		},
		getEditArticle: function () {
			this.start_file = null;
			this.image = null;
			this.isLoading = true;
			article_editor_api
				.getEditArticle(this.articleData.artikelnr)
				.then((res) => {
					this.artData = res.data;
					if (this.artData.media.length !== 0) {
						this.imageIndex = 0;
						this.artData.media[0].active = true;
					}
					this.isLoading = false;
				});
			this.isEditing = false;
			this.showReset = false;
			this.showUnsaved = false;
			this.showSave = false;
		},
		uploadFile() {
			this.start_file = null;
			this.isEditing = true;
			this.start_file = this.$refs.file.files[0];

			this.artData.files.push({
				text: this.start_file.name,
			});
			this.$refs.image.value = null;
		},
		deleteFile(index) {
			this.isEditing = true;
			this.artData.files.splice(index, 1);
		},

		setLink(linkFields) {
			this.isEditing = true;
			this.artData.links.push({
				text: linkFields.linkTitle,
				link: linkFields.linkUrl,
			});
			this.showLinkModal = false;
		},
		deleteLink(link, index) {
			this.isEditing = true;

			if ("text" in link) {
				this.artData.links.splice(index, 1);
			} else if ("title" in link) {
				this.uploadedLinks.splice(index, 1);
			}
		},

		uploadImage() {
			this.isEditing = true;

			this.errorMsg = false;
			let imageFiles = [];
			Array.from(this.$refs.image.files).forEach((file) => {
				imageFiles.push(file);
			});
			imageFiles.forEach((img) => {
				this.artData.media.push({ start_image: img });
			});

			if (this.imageIndex == null) {
				this.imageIndex = 0;
			}

			this.$refs.image.value = null;
		},

		deleteImage(img) {
			this.isEditing = true;

			for (let i = 0; i < this.artData.media.length; i++) {
				if (this.artData.media[i].hash === img.hash) {
					this.artData.media.splice(i, 1);
				}
			}

			if (this.artData.media.length === 0) {
				this.imageIndex = null;
			} else {
				this.imageIndex = 0;
				this.artData.media[0].active = true;
			}
		},

		postArticleData() {
			this.showSave = false;
			article_editor_api
				.postArticleData(this.artData)
				.then(() => {
					this.showSuccess = true;
					this.$emit("refresh");
					this.getEditArticle();
				})
				.catch(() => {
					this.showError = true;
				});
		},
	},
	mounted() {
		this.getEditArticle();
	},
	watch: {
		showOrderModal() {
			document.body.classList.add("modal-open");
		},

		showLinkModal() {
			document.body.classList.add("modal-open");
		},
		showSuccess() {
			document.body.classList.add("modal-open");
		},
		showError() {
			document.body.classList.add("modal-open");
		},
	},
};
</script>

<style scoped>
::-webkit-scrollbar {
	width: 6px;
	height: 4px;
	max-height: 50%;
	height: 50%;
	background-color: transparent;
	margin-left: 1rem;
}
.link-item {
	height: 2.6rem;
}
.add-btn {
	width: 10rem;
	margin-top: 1rem;
}
.btns-wrapper button {
	width: 11.4rem;
}
.table-item {
	height: 3rem;
}
.scrollable-container {
	margin-top: -2rem;
	height: 22rem;
	overflow: auto;
}

.delete-img-icon {
	cursor: pointer;
	position: absolute;
	top: 6%;
	z-index: 2;
	right: 6%;
	width: 1rem;
}
.delete-icon {
	width: 1.2rem;
	cursor: pointer;
}

input[type="file"] {
	display: none;
}

.add-container {
	cursor: pointer;
}

.add-container:hover {
	text-decoration: underline;
	transition: 0.1s;
}

.add-container:hover .add-icon {
	transform: scale(1.1);
	transition: 0.1s;
}

.add-img {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #ffffff;
	padding: 0.3rem;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	border-bottom-left-radius: 6px;
}
.link {
	cursor: pointer;
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: black;
	text-decoration: none;
}

.link:hover {
	font-weight: 500;
	transition: 0.1s;
}
.links-wrapper {
	width: 100%;
}
.left-container {
	width: 11rem;
}
.add-icon {
	width: 1rem;
}

.room-wrapper {
	max-height: 6rem;
	overflow-x: hidden;
	overflow-y: auto;
}

.img-wrapper {
	width: 3.8rem;
	height: 4rem;

	border: 1px solid transparent;
}
.gal-img-wrapper {
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	border: 1px solid transparent;
	position: relative;
}

.gal-img {
	object-fit: fill;
	width: 100%;
	height: 100%;
}
.gal-img-wrapper:hover,
.img-wrapper:hover {
	border: 1px solid rgba(128, 128, 128);
	transition: 0.2s;
}

.main-image-wrapper {
	height: 10rem;
	border: 1.5px solid black;
	position: relative;
}

.main-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.no-image {
	width: 100%;
	border: 1.5px solid black;
	height: 100%;
	object-fit: contain;
}
.image-gallery {
	margin-top: 1rem;
	width: 11rem;
	overflow: auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));

	grid-gap: 4px;
}

.img-checkbox {
	position: absolute;
	bottom: 4px;
	right: 10px;
}

.right-position {
	right: -4px !important;
}

@media screen and (max-height: 800px) {
	.scrollable-container {
		height: 17rem;
	}
}
</style>

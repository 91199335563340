<template>
	<div class="row mb-2">
		<div class="col">
			<p class="large-text">{{ $t("navbarTitle.users") }}</p>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<p class="small-text">
				{{ $t("landingPage.usersInformation") }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col d-flex mb-3">
			<button
				:class="`${option === 0 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(0)"
			>
				{{ $t("filterOptions.allUsers") }}
			</button>
			<button
				:class="`${option === 1 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(1)"
			>
				{{ $t("filterOptions.editUsers") }}
			</button>
			<button
				:class="`${option === 2 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(2)"
			>
				{{ $t("filterOptions.addUser") }}
			</button>
			<div class="search-bar">
				<img
					src="@/assets/img/search-icon.svg"
					alt="search icon"
					class="search-icon"
				/>
				<input
					type="text"
					class="text-input"
					:placeholder="$t('placeHolders.searchUser')"
					v-model="searchValue"
				/>
			</div>
		</div>
	</div>

	<AllUsers :searchValue="searchValue" v-if="option === 0" />
	<EditUsers
		:searchValue="searchValue"
		v-if="option === 1"
		@isEditing="(bool) => (showWarning = bool)"
	/>
	<AddUser
		:searchValue="searchValue"
		v-if="option === 2"
		@isEditing="(bool) => (showWarning = bool)"
	/>
	<VerificationModal
		:header="$t('verificationTexts.headers.exit')"
		v-if="navVerification"
		:verificationText="$t('verificationTexts.closeChanges')"
		@accept="confirmExit()"
		@close="navVerification = false"
	/>
	<VerificationModal
		:header="$t('verificationTexts.headers.exit')"
		v-if="routeVerification"
		:verificationText="$t('verificationTexts.closeChanges')"
		@accept="confirmRouting()"
		@close="routeVerification = false"
	/>
</template>

<script>
import VerificationModal from "@/modals/VerificationModal.vue";
import AllUsers from "./AllUsers.vue";
import EditUsers from "./EditUsers.vue";
import AddUser from "./AddUser.vue";
import router from "@/router";

export default {
	components: {
		AllUsers,
		EditUsers,
		AddUser,
		VerificationModal,
	},
	data() {
		return {
			option: 0,
			selected: null,
			searchValue: "",
			navVerification: false,
			routeVerification: false,
			showWarning: false,
			toggleActUser: true,
			path: null,
			windowWidth: window.innerWidth,
		};
	},
	methods: {
		confirmExit() {
			this.showWarning = false;
			this.option = this.selected;
			this.navVerification = false;
			document.body.classList.remove("modal-open");
		},
		confirmRouting() {
			this.showWarning = false;
			document.body.classList.remove("modal-open");
			router.push({ name: this.path });
		},
		checkIsEditing(opt) {
			this.selected = opt;
			if (this.showWarning) {
				this.navVerification = true;
			} else {
				this.navVerification = false;
				this.option = this.selected;
			}
		},
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},

	beforeRouteLeave(to) {
		this.path = to.name;
		if (this.showWarning) {
			this.routeVerification = true;
			return false;
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 1150px) {
	.filter-btns-wrapper {
		display: none !important;
	}
}
</style>

<template>
	<div class="row mb-2">
		<div class="col">
			<p class="large-text">{{ $t("navbarTitle.roomHandle") }}</p>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<p class="small-text">
				{{ $t("landingPage.roomInformation") }}
			</p>
		</div>
	</div>
	<div class="row">
		<div class="col d-flex mb-3">
			<button
				:class="`${option === 0 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(0)"
			>
				{{ $t("filterOptions.editRooms") }}
			</button>
			<button
				:class="`${option === 1 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(1)"
			>
				{{ $t("filterOptions.mergeRooms") }}
			</button>
			<button
				:class="`${option === 2 ? 'active-filter' : 'filter-btn'}`"
				@click="checkIsEditing(2)"
			>
				{{ $t("buttonTexts.addRoom") }}
			</button>
			<div class="search-bar">
				<img
					src="@/assets/img/search-icon.svg"
					alt="search icon"
					class="search-icon"
				/>
				<input
					type="text"
					class="text-input"
					:placeholder="$t('placeHolders.searchRoomOrLocation')"
					v-model="searchValue"
				/>
			</div>
		</div>
	</div>

	<EditRooms
		v-if="option === 0"
		:searchValue="searchValue"
		@isEditing="(bool) => (showWarning = bool)"
	/>

	<CombineRooms
		v-if="option === 1"
		:searchValue="searchValue"
		@isEditing="(bool) => (showWarning = bool)"
	/>
	<AddRoom
		v-if="option === 2"
		:searchValue="searchValue"
		@isEditing="(bool) => (showWarning = bool)"
	/>
	<VerificationModal
		v-if="navVerification"
		:header="$t('verificationTexts.headers.exit')"
		:verificationText="$t('verificationTexts.closeChanges')"
		@accept="confirmExit()"
		@close="navVerification = false"
	/>

	<VerificationModal
		:header="$t('verificationTexts.headers.exit')"
		v-if="routeVerification"
		:verificationText="$t('verificationTexts.closeChanges')"
		@accept="confirmRouting()"
		@close="routeVerification = false"
	/>
</template>

<script>
import VerificationModal from "@/modals/VerificationModal.vue";
import AddRoom from "./AddRoom.vue";
import CombineRooms from "./CombineRooms.vue";
import EditRooms from "./EditRooms.vue";
import router from "@/router";

export default {
	components: {
		CombineRooms,
		EditRooms,
		AddRoom,
		VerificationModal,
	},
	data() {
		return {
			option: 0,
			selected: null,
			searchValue: "",
			navVerification: false,
			routeVerification: false,
			showWarning: false,
			windowWidth: window.innerWidth,
		};
	},
	methods: {
		confirmExit() {
			this.showWarning = false;
			this.option = this.selected;
			this.navVerification = false;
			document.body.classList.remove("modal-open");
		},

		confirmRouting() {
			this.showWarning = false;
			document.body.classList.remove("modal-open");
			router.push({ name: this.path });
		},
		checkIsEditing(opt) {
			this.selected = opt;
			if (this.showWarning) {
				this.navVerification = true;
			} else {
				this.navVerification = false;
				this.option = this.selected;
			}
		},
	},

	beforeRouteLeave(to) {
		this.path = to.name;
		if (this.showWarning) {
			this.routeVerification = true;
			return false;
		}
	},

	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
};
</script>

<style scoped></style>

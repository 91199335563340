<template>
	<GrLayoutTemplate :hideFooter="hideFooter">
		<template v-slot:top-navbar>
			<div class="container-fluid h-100">
				<div class="row h-100 align-items-center">
					<div class="col-sm-6">
						<slot name="top-navbar-left"></slot>
					</div>
					<div class="col-sm-6">
						<slot name="top-navbar-right"></slot>
					</div>
				</div>
			</div>
		</template>

		<template v-slot:bottom-navbar>
			<div class="container-fluid h-100">
				<div class="row h-100 align-items-center">
					<div class="col-sm-6">
						<slot name="bottom-navbar-left"></slot>
					</div>
					<div class="col-sm-6">
						<slot name="bottom-navbar-right"></slot>
					</div>
				</div>
			</div>
		</template>

		<template v-slot:container>
			<slot name="container"></slot>
		</template>
		<template v-slot:footer>
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-4">
						<img
							src="@/assets/img/greenified.svg"
							class="greenified-footer-logo"
							alt="greenified footer logo"
						/>
						<slot name="footer-left-content"></slot>
					</div>
					<div class="col-sm-8">
						<slot name="footer-right-content"></slot>
					</div>
				</div>
			</div>
		</template>
	</GrLayoutTemplate>
</template>

<style scoped>
.greenified-footer-logo {
	height: 1.2rem;
}

@media screen and (min-width: 1150px) {
	.container-fluid {
		padding: 0 !important;
	}
}
</style>

<script>
import GrLayoutTemplate from "./GrLayoutTemplate.vue";

export default {
	name: "NavBar",

	emits: ["hideFooter"],
	components: { GrLayoutTemplate },
	props: {
		hideFooter: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		hideFooter(val) {
			if (val) {
				this.$emit("hideFooter");
			}
		},
	},
};
</script>

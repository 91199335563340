<template>
	<div
		class="main-container mt-2 ps-0 pe-0"
		:style="{ height: isLoading ? '10rem' : '' }"
	>
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid" v-else>
			<div class="row align-items-center layout-padding">
				<div class="col-sm-7 pe-0 d-flex align-items-center">
					<div class="space"></div>
					<div class="space"></div>
					<div
						class="table-header w-100 no-wrap ms-1 d-flex align-items-center"
					>
						<p>
							{{ $t("tableHeaders.chooseOneArticle") }} ({{
								$t("articleInfoLabels.id")
							}})
						</p>
					</div>
				</div>
				<div
					class="col-sm-2 d-flex align-items-center table-header"
					v-if="uniqueIds.length > 1 && inventRole"
				>
					<input type="checkbox" v-model="moveMore" class="td-checkbox" />
					<p>{{ $t("tableHeaders.moveMoreArticles") }}</p>
				</div>
				<div class="col-sm-2 table-header">
					<div class="content-s">
						<Multiselect
							class="selector-c multiselect-left"
							v-if="movedArticleList.length && inventRole"
							:options="locations"
							:open-direction="'bottom'"
							v-model="newLocation"
							:show-labels="false"
							@select="showMultiMove = true"
							label="platsnamn"
							:placeholder="$t('handleRooms.chooseRoom')"
							><template v-slot:caret>
								<div class="multiselect__select">
									<img
										src="../../assets/img/down-icon.svg"
										alt="dropdown icon"
									/></div></template
						></Multiselect>
					</div>
				</div>
				<div class="col table-header"></div>
			</div>
		</div>

		<div
			class="container-fluid"
			v-for="item in uniqueIds"
			:key="item"
			:class="item.active ? 'gr-color ' : ''"
		>
			<div
				class="row align-items-center layout-padding"
				@click.stop="showUnique(item)"
			>
				<div class="col-sm-3 pe-0 d-flex align-items-center">
					<div class="space"></div>
					<div class="space"></div>
					<div class="w-100 table-item ms-1">
						<div
							class="d-flex align-items-center td-checkbox"
							v-if="moveMore"
							@click.stop
						>
							<input :value="item" type="checkbox" v-model="movedArticleList" />
						</div>

						<p>{{ item.id }}</p>
					</div>
				</div>

				<div class="col-sm-4 table-item">
					<button
						class="standard-btn"
						:class="
							inactiveButtons && !item.active
								? 'active-btn'
								: item.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							item.active
								? $t("buttonTexts.closeArticle")
								: $t("buttonTexts.showArticle")
						}}
					</button>
				</div>
				<div class="col table-item"></div>
			</div>
			<div @click.stop v-if="item.active" class="row mt-2 mb-5">
				<ProductInfo
					:chosenId1="chosenId1"
					@refreshUnique="
						getUniqueIds(1);
						$emit('update');
					"
				/>
			</div>
			<div
				class="row bottom-bar layout-padding"
				@click.stop
				v-if="item.active"
			></div>
		</div>
		<div class="container-fluid">
			<div class="row layout-padding">
				<div class="col-sm-12 d-flex pe-0">
					<div class="space"></div>
					<div class="space"></div>
					<div class="bottom-border w-100 ms-1"></div>
				</div>
			</div>
		</div>
	</div>

	<VerificationModal
		:header="$t('inventory.move')"
		v-if="showMultiMove"
		:verificationText="
			$t('verificationTexts.moveMultipleText', [
				this.movedArticleList.length,
				this.movedArticleList[0]?.platsnamn,
				this.newLocation.platsnamn,
			])
		"
		@close="
			showMultiMove = false;
			newLocation = '';
		"
		@accept="postLocations()"
	/>
	<ResponseModal
		v-if="moveError"
		:response="0"
		:responseText="
			$t('responseText.moveError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="moveError = false"
	/>
	<ResponseModal
		v-if="moveSuccess"
		:response="1"
		:responseText="$t('responseText.moveSuccess')"
		@close="
			moveSuccess = false;
			$emit('multiMove');
		"
	/>
</template>

<script>
import Multiselect from "vue-multiselect";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import unique_api from "@/services/api/unique_api";
import ProductInfo from "./ProductInfo.vue";
import room_handle_api from "@/services/api/room_handle_api";
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
export default {
	props: { idList: Object },
	data() {
		return {
			movedArticleList: [],
			chosenId1: [],
			moveMore: false,
			inventRole: this.$store.getters["inventRole"],
			isLoading: false,
			uniqueIds: [],
			newLocation: "",
			locations: [],
			moveSuccess: false,
			moveError: false,
			showMultiMove: false,
			inactiveButtons: false,
		};
	},
	components: {
		ProductInfo,
		LoaderSpinner,
		Multiselect,
		ResponseModal,
		VerificationModal,
	},
	emits: ["update", "multiMove"],
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		postLocations: function () {
			this.moveMore = false;
			let multipleIds = [];
			this.movedArticleList.map((art) => {
				multipleIds.push(art.id);
			});

			unique_api
				.postLocations(this.newLocation.plats, multipleIds)
				.then(() => {
					this.showMultiMove = false;
					this.moveSuccess = true;
				})
				.catch(() => {
					this.moveError = true;
				});
		},
		showUnique(id) {
			id.active = !id.active;
			this.chosenId1 = id;

			this.uniqueIds.map((x) => {
				if (x.id !== id.id) {
					x.active = false;
				}
			});

			if (!id.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
		getUniqueIds: function (val) {
			this.movedArticleList = [];
			this.chosenId1 = [];
			this.uniqueIds = [];
			this.isLoading = val ? false : true;
			unique_api
				.getUniqueIds(this.idList.artikelnr, this.idList.plats)
				.then((res) => {
					this.uniqueIds = res.data.data;

					this.isLoading = false;
				});
		},
		getHandleRooms: function () {
			this.locations = [];

			room_handle_api.getHandleRooms().then((res) => {
				res.data.map((loc) => {
					if (loc.plats !== Number(this.idList.plats)) {
						this.locations.push(loc);
					}
				});
			});
		},
	},
	watch: {
		clientId() {
			this.inventRole = this.$store.getters["inventRole"];
		},
		moveMore(bool) {
			if (bool === false) {
				this.movedArticleList = [];
			}
		},
	},
	mounted() {
		this.getHandleRooms();
		this.getUniqueIds();
	},
};
</script>

<style scoped>
.table-header {
	height: 3.6rem;
}
.standard-btn {
	min-width: 6.9rem;
}
</style>

<template>
	<div class="top-navbar layout-padding">
		<slot name="top-navbar"></slot>
	</div>

	<div
		:class="
			this.$store.state.modalActive && smallNavbar
				? 'fixed-navbar-wrapper'
				: 'navbar-wrapper'
		"
		@click="scrollToTop"
	>
		<div ref="navbar" class="layout-padding">
			<div class="container-fluid ps-0 pe-0">
				<div class="row">
					<div
						class="col-sm-6 d-flex align-items-center"
						:class="smallNavbar ? 'small-logo-wrapper' : 'logo-wrapper'"
					>
						<img
							src="@/assets/img/greenified-ims.svg"
							alt="greenified-logo"
							:class="smallNavbar ? 'small-logo' : 'greenified-logo'"
						/>
					</div>

					<div
						v-if="$store.state.auth.authenticated"
						class="col-sm-6 d-flex align-items-center justify-content-end fw-bolder"
					>
						<div
							class="navlink-wrapper"
							:class="$route.meta.mainHeader === 1 ? 'active-border' : ''"
						>
							<p @click="pushRoute('/product-overview')" class="nav-link-lower">
								{{ $t("navbarTitle.productOverview") }}
							</p>
						</div>
						<div
							class="navlink-wrapper"
							v-if="inventRole"
							:class="$route.meta.mainHeader === 2 ? 'active-border' : ''"
						>
							<p @click="pushRoute('/room-handle')" class="nav-link-lower">
								{{ $t("navbarTitle.roomHandle") }}
							</p>
						</div>
						<div
							class="navlink-wrapper"
							v-if="inventRole"
							:class="$route.meta.mainHeader === 3 ? 'active-border' : ''"
						>
							<p @click="pushRoute('/inventory')" class="nav-link-lower">
								{{ $t("navbarTitle.inventory") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="gr-color sub-wrapper layout-padding">
			<slot name="bottom-navbar"></slot>
		</div>
	</div>

	<div class="layout">
		<div class="layout-padding" v-if="$route.meta.mainHeader !== 1">
			<slot name="container"></slot>
		</div>
		<div class="container-fluid ps-0 pe-0" v-else>
			<slot name="container"></slot>
		</div>
	</div>

	<footer v-if="!hideFooter" class="desktop-container">
		<div class="main-footer gr-color layout-padding align-items-center">
			<slot name="footer"></slot>
		</div>
	</footer>
</template>

<style scoped>
.mobile-navbar-wrapper {
	display: none;
}
.small-logo {
	height: 1.4rem;
	transition: 0.2s;
}

.greenified-logo {
	height: 1.6rem;
	transition: 0.2s;
}
.logo-wrapper {
	height: 6rem;
	transition: 0.2s;
}

.small-logo-wrapper {
	height: 4rem;
	transition: 0.2s;
}

.w-80 {
	width: 80%;
}

.navbar-wrapper {
	position: sticky;
	z-index: 9;
	top: 0;
	left: 0;
	background-color: white;
}

.layout {
	flex: 1;
	margin-top: 1.4rem;
}

.fixed-navbar-wrapper {
	position: fixed;
	width: 100%;
	/* margin-top: 3rem; */
	z-index: 9;
	top: 0;
	left: 0;
	background-color: white;
}

.top-navbar {
	background-color: #333;
	color: white;
	min-height: 2.5rem;
	height: 2.5rem;
	font-size: 14px !important;
}

.gr-color {
	background-color: #f8f5ee;
}

.layout-padding {
	padding-left: 6rem;
	padding-right: 6rem;
}
</style>

<script>
import router from "@/router";
export default {
	name: "NavBar",
	computed: {
		authenticated() {
			return this.$store.state.auth.authenticated;
		},
	},

	data() {
		return {
			smallNavbar: false,
			showHamb: false,
			inventRole: this.$store.getters["inventRole"],
			adminRole: this.$store.getters["adminRole"],
		};
	},
	props: {
		hideFooter: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		pushRoute(route) {
			if (this.$route.name === route) {
				window.location.reload();
			} else {
				router.push({ path: route });
			}
		},
		scrollToTop() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},
		handleScroll() {
			if (document.documentElement.scrollTop >= 50) {
				this.smallNavbar = true;
			} else if (document.documentElement.scrollTop < 14.8) {
				this.smallNavbar = false;
			}
		},
		activateScroll() {
			this.showHamb = true;
		},

		deactivateScroll() {
			this.showHamb = false;
		},
	},
	watch: {},
	created() {
		window.addEventListener("scroll", this.handleScroll);
	},
	unmounted() {
		window.removeEventListener("scroll", this.handleScroll);
	},
};
</script>

import axios from "axios";
import store from "../store";

const header = {
	Accept: "application/json",
	"Content-Type": "application/json",
};

export default () => {
	return axios.create({
		baseURL: `${process.env.VUE_APP_LARAVEL_API}/api/${
			store.getters.language
				? store.getters.language
				: store.getters.locale
		}/${store.getters.client.klient}`,
		withCredentials: false,
		headers: header,
	});
};

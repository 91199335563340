import api from "@/services/api";

export default {
	getRooms() {
		return api().get("/room");
	},

	getRoomArticles(plats) {
		return api().get(`/room/${plats}`);
	},
};

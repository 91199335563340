<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid sticky-header" v-else>
			<div class="row align-items-center table-header-b">
				<div
					class="col-sm-4 ps-0"
					:class="sortOption.title === 'benamning' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('benamning')"
					>
						<p class="me-2">{{ $t("inventory.roomInChosenInventory") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'benamning' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-3"
					:class="sortOption.title === 'antal_artiklar' ? 'active-th' : ''"
				>
					<div class="d-flex align-items-center">
						<p>
							{{ $t("inventory.inventoryAmount") }}
							<span class="amount-span ms-3"
								>{{ invAmount }} / {{ artAmount }}</span
							>
						</p>
					</div>
				</div>

				<div
					class="col d-flex justify-content-end report-wrapper align-items-center"
				>
					<div class="search-bar" v-if="invItems.length > 7">
						<img
							src="@/assets/img/search-icon.svg"
							alt="dropdown arrow"
							class="search-icon"
						/>
						<input
							type="text"
							class="text-input"
							:placeholder="$t('placeHolders.searchRoom')"
							v-model="searchValue"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<div
				class="row table-item-b align-items-center"
				v-for="inv in filteredInventories"
				:key="inv"
			>
				<div class="col-sm-4 ps-0 fw-bolder">{{ inv.benamning }}</div>
				<div class="col-sm-3 d-flex">
					{{ inv.inventerade }}/{{ inv.antal_artiklar }}
					<img
						src="@/assets/img/check-only.svg"
						alt="check icon"
						class="check-icon ms-2"
						v-if="inv.inventerade === inv.antal_artiklar"
					/>
				</div>
				<div class="col d-flex justify-content-end">
					<button class="standard-btn confirm-btn" @click="showInventory(inv)">
						{{ $t("inventory.viewAndInventoryProducts") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inventory_api from "@/services/api/inventory_api";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import router from "@/router";

export default {
	components: { LoaderSpinner },
	props: {
		invId: String,
		locName: String,
	},
	emits: ["hasRecs", "hasDeviations", "hasActiveReconditions"],
	data() {
		return {
			isLoading: false,
			invItems: [],
			selectedRoom: [],
			displayInventory: false,
			inventRole: this.$store.getters["inventRole"],
			sortOption: {
				title: "",
				active: false,
			},
			invType: null,
			allReconditions: null,
			deviations: null,
			searchValue: "",
			activeReconditions: null,
		};
	},
	computed: {
		filteredInventories() {
			if (this.searchValue.length === 0) {
				return this.invItems;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.invItems.filter((a) => {
					return a.benamning.toLowerCase().includes(searchText);
				});
			}
		},
		clientId() {
			return this.$store.state.client.klient;
		},
		artAmount() {
			let amount = 0;
			this.invItems.map((inv) => {
				amount += Number(inv.antal_artiklar);
			});
			return amount;
		},
		invAmount() {
			let amount = 0;
			this.invItems.map((inv) => {
				amount += Number(inv.inventerade);
			});
			return amount;
		},
	},
	methods: {
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;

				if (this.sortOption.active) {
					this.invItems.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.invItems.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;

				this.invItems.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
			}
		},
		showInventory(inv) {
			router.push({
				name: "InventorySlotSelected",
				params: { invId: inv.invid, loc: inv.plats, locName: this.locName },
			});
		},

		getInventoryLocations() {
			this.isLoading = true;
			if (this.inventRole) {
				inventory_api
					.getInventoryLocations(this.invId)
					.then((res) => {
						this.invItems = res.data.rooms;
						this.invType = res.data.type;
						this.allReconditions = res.data.allReconditions;
						this.activeReconditions = res.data.activeReconditions;
						this.deviations = res.data.articles;

						if (this.allReconditions > 0) {
							this.$emit("hasRecs", true);
						}
						if (this.deviations > 0) {
							this.$emit("hasDeviations", true);
						}
						if (this.activeReconditions > 0) {
							this.$emit("hasActiveReconditions", true);
						}

						this.isLoading = false;
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},
	},
	mounted() {
		this.getInventoryLocations();
	},
	watch: {
		clientId() {
			this.invItems = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getInventoryLocations();
		},
	},
};
</script>

<style scoped>
.table-header-b {
	height: 3rem;
}
.search-bar {
	width: 8.4rem;
	height: 2.1rem;
}
.report-wrapper .standard-btn {
	min-width: 9rem;
}

.amount-span {
	font-weight: 400 !important;
}
</style>

<template>
	<GrModalMedium>
		<template v-slot:modal-header-title>
			<p class="fw-bolder">{{ $t("modalLabels.moveHistory") }}</p>
		</template>
		<template v-slot:modal-content>
			<div class="table-header-b col-sm-12 d-flex align-items-center">
				<div class="col-sm-5">
					{{ $t("tableHeaders.from") }}
				</div>
				<div class="col-sm-5">
					{{ $t("tableHeaders.to") }}
				</div>
				<div class="col-sm-2">
					{{ $t("tableHeaders.date") }}
				</div>
			</div>
			<div class="table-wrapper">
				<div class="row">
					<div
						class="table-item-b d-flex align-items-center ps-0 pe-0"
						v-for="room in moveHistory"
						:key="room"
					>
						<div class="col-sm-5">
							{{ room.fran }}
						</div>
						<div class="col-sm-5">
							{{ room.till }}
						</div>
						<div class="col-sm-2">
							{{ room.datum !== null ? room.datum.substring(0, 10) : "-" }}
						</div>
					</div>
				</div>
			</div>
		</template>
	</GrModalMedium>
</template>

<script>
import GrModalMedium from "@/modals/GrModalMedium.vue";

export default {
	components: { GrModalMedium },
	props: { moveHistory: Object },
};
</script>

<style scoped>
.table-header-b {
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}
.table-wrapper {
	padding-left: 2rem;
	padding-right: 2rem;
	max-height: 30rem;
	overflow: auto;
	background-color: white;
}
</style>

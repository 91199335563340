import api from "@/services/api";

export default {
	getUniqueIds(artikelnr, plats) {
		return api().get(`/article/${artikelnr}/room/${plats}`);
	},

	getAllUniques() {
		return api().get("/article/all");
	},

	getOrderUniques() {
		return api().get("/article/order");
	},

	getErrands(status) {
		return api().get(`/errand/${status}`);
	},
	postLocations(plats, idArr) {
		return api().post(
			"/uniqueArticle/move",
			JSON.stringify({ plats: plats, idArr: idArr })
		);
	},

	getUniqueId(id) {
		return api().get(`/uniqueArticle/${id}/show`);
	},

	postLocation(plats, id) {
		return api().post(
			`/uniqueArticle/${id}/move`,
			JSON.stringify({ plats: plats })
		);
	},

	getInventoryHistory(id) {
		return api().get(`/uniqueArticle/${id}/iHistory`);
	},

	getMoveHistory(id) {
		return api().get(`/uniqueArticle/${id}/mHistory`);
	},

	postOrder(order, artikelnr) {
		return api().post(
			`/uniqueArticle/${artikelnr}/order`,
			JSON.stringify({ order: order })
		);
	},

	discardArticle(id) {
		return api().get(`/uniqueArticle/${id}/discard`);
	},

	discardArticles(id) {
		return api().post(`/uniqueArticle/discard`, JSON.stringify({ id: id }));
	},
};

<template>
	<div class="main-container" :style="{ height: isLoading ? '30vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid sticky-header" v-else>
			<div class="table-header-b row align-items-center">
				<div class="col-sm-3 ps-0">{{ $t("tableHeaders.name") }}</div>
				<div class="col-sm-3">{{ $t("tableHeaders.email") }}</div>
				<div class="col-sm-2">{{ $t("tableHeaders.company") }}</div>

				<div class="col-sm-4 d-flex">
					<p class="me-4">{{ $t("tableHeaders.permissions") }}</p>
					<Popper :hover="true">
						<template #content>
							<div class="popper-content">
								<p class="fw-bolder">
									{{ $t("tableHeaders.infoAboutPermissions") }}:
								</p>

								<p>{{ $t("textFields.viewPermission") }}</p>
								<p>{{ $t("textFields.inventoryPermission") }}</p>
								<p>{{ $t("textFields.claimPermission") }}</p>
								<p>{{ $t("textFields.orderPermission") }}</p>
								<p>{{ $t("textFields.changePermission") }}</p>
							</div>
						</template>
						<img
							src="@/assets/img/info-icon.svg"
							alt="info icon"
							class="info-icon"
						/>
					</Popper>
				</div>

				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<template v-for="user in filteredUsers" :key="user">
				<form
					v-if="user.aktiv === 'J' && user.id === null"
					id="userform"
					@submit.prevent="checkReqFields()"
					class="row table-item-b align-items-center"
				>
					<div class="col-sm-3 fw-bold ps-0">
						<input
							type="text"
							class="input-field spec-width"
							:placeholder="$t('tableHeaders.name')"
							v-model="user.namn"
							@input="isEditing = true"
							@blur="
								!user.namn.length ? (showWarning = true) : (showWarning = false)
							"
							maxlength="40"
							required
						/>

						<RequiredWarning
							:warningText="$t('warningTexts.requiredField')"
							v-if="showWarning && !user.namn.length"
						/>
					</div>

					<div class="col-sm-3 fw-bold">
						<input
							type="text"
							:placeholder="$t('tableHeaders.email')"
							class="input-field spec-width"
							v-model="user.epost"
							@blur="verifyUser(user)"
							maxlength="50"
							@input="isEditing = true"
							@change="userChanged = true"
							required
						/>

						<RequiredWarning
							:warningText="$t('warningTexts.emailIsTaken')"
							v-if="user.invalidUser && user.epost.length"
						/>

						<RequiredWarning
							:warningText="$t('warningTexts.emailStructureError')"
							v-if="
								!validRegex.test(user.epost) &&
								user.epost.length < 6 &&
								showEmailWarning
							"
						/>
					</div>
					<div class="col-sm-2 fw-bold">
						<input
							type="text"
							class="input-field w-75"
							:placeholder="$t('tableHeaders.company')"
							v-model="user.foretag"
							maxlength="40"
							@input="isEditing = true"
						/>
					</div>
					<div class="col d-flex align-items-center justify-content-between">
						<Multiselect
							class="selector-xl right-multiselect"
							:options="userPrivileges"
							:open-direction="'top'"
							:show-labels="false"
							:placeholder="$t('placeHolders.choosePermissions')"
							:close-on-select="false"
							:clear-on-select="true"
							:multiple="true"
							><template v-slot:caret>
								<div class="multiselect__select">
									<img
										src="@/assets/img/down-icon.svg"
										alt="dropdown icon"
									/></div
							></template>
							<template #option="{ option }"
								><div
									v-if="option === 'produkter'"
									class="d-flex align-items-center multi-checkbox"
								>
									<input
										type="checkbox"
										class="dropdown-checkbox"
										checked
										disabled
									/>
									<p>
										{{ $t("buttonTexts.viewProducts") }}
									</p>
								</div>
								<div
									v-else
									class="d-flex align-items-center w-100 multi-checkbox"
									@click="
										user[option] = user[option] === 'J' ? 'N' : 'J';
										isEditing = true;
									"
								>
									<input
										type="checkbox"
										@change="isEditing = true"
										class="dropdown-checkbox"
										true-value="J"
										false-value="N"
										v-model="user[option]"
									/>
									<p>
										{{ $t("list.privileges." + option) }}
									</p>
								</div></template
							></Multiselect
						>
						<button class="active-btn" type="submit" form="userform">
							{{ $t("modalLabels.add") }}
						</button>
					</div>
				</form>
				<div
					class="row table-item-b align-items-center"
					v-if="user.aktiv === 'J' && user.id !== null"
				>
					<div class="col-sm-3 fw-bold ps-0 h-100 d-flex align-items-center">
						<p>{{ user.namn }}</p>
					</div>

					<div class="col-sm-3 fw-bold h-100 d-flex align-items-center">
						<p class="ellipsis">{{ user.epost }}</p>
					</div>
					<div class="col-sm-2 fw-bold h-100 d-flex align-items-center">
						<p>{{ user.foretag }}</p>
					</div>
					<div class="col-sm-4 d-flex align-items-center h-100">
						<p>
							{{ $t("textFields.view")
							}}{{
								user.beh_reklamera === "J" ? `, ${$t("textFields.claim")}` : ""
							}}{{ user.beh_andra === "J" ? `, ${$t("textFields.order")}` : ""
							}}{{
								user.beh_inventera === "J" ? `, ${$t("textFields.store")}` : ""
							}}{{
								user.beh_produktinfo === "J"
									? `, ${$t("textFields.editProductInfo")}`
									: ""
							}}
						</p>
					</div>

					<div class="col h-100 d-flex align-items-center"></div>
				</div>
			</template>
		</div>
	</div>

	<VerificationModal
		:header="$t('verificationTexts.headers.add')"
		v-if="verifyUpdate"
		:verificationText="$t('verificationTexts.addUser')"
		@accept="updateUsers()"
		@close="verifyUpdate = false"
	/>

	<ResponseModal
		v-if="updateError"
		:response="0"
		:responseText="
			$t('responseText.addUserError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="updateError = false"
	/>
</template>

<script>
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import admin_api from "@/services/api/admin_api";
import Popper from "vue3-popper";
import ResponseModal from "@/modals/ResponseModal.vue";
import Multiselect from "vue-multiselect";
import VerificationModal from "@/modals/VerificationModal.vue";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import router from "@/router";
export default {
	components: {
		VerificationModal,
		LoaderSpinner,
		RequiredWarning,
		Popper,
		Multiselect,
		ResponseModal,
	},
	emits: ["isEditing"],
	props: { searchValue: String },
	data() {
		return {
			verifyUpdate: false,
			users: [],
			array: [],
			isEditing: false,
			isLoading: false,
			adminRole: this.$store.getters["adminRole"],
			login: "",
			sortOption: {
				title: "",
				active: false,
			},
			userPrivileges: [
				"produkter",
				"beh_inventera",
				"beh_reklamera",
				"beh_andra",
				"beh_produktinfo",
			],
			userChanged: false,
			lastIndex: null,
			showWarning: false,
			updateError: false,
			showEmailWarning: false,
			validRegex:
				/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		};
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredUsers() {
			if (this.searchValue.length === 0) {
				return this.users;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.users.filter((u) => {
					return (
						u.namn.toLowerCase().includes(searchText) ||
						u.login.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		verifyUser(user) {
			this.showEmailWarning = false;
			if (
				user.epost.length >= 6 &&
				this.validRegex.test(user.epost) &&
				this.userChanged
			) {
				admin_api.verifyUser(user.epost, user.id).then((res) => {
					if (res.data !== 1) {
						user.invalidUser = true;
					} else {
						user.invalidUser = false;
					}
				});
				this.isEditing = true;
				this.showEmailWarning = false;
				this.userChanged = false;
			} else {
				this.showEmailWarning = true;
			}
		},

		checkReqFields() {
			let validFields = true;
			for (let i = 0; i < this.users.length; i++) {
				if (
					this.users[i].invalidEmail ||
					this.users[i].namn === "" ||
					(this.users[i].epost.length < 6 &&
						!this.validRegex.test(this.users[i].epost))
				) {
					this.showWarning = true;
					this.showEmailWarning = true;
					validFields = false;
				}
			}
			if (validFields) {
				this.verifyUpdate = true;
				this.showWarning = false;
			}
		},

		addRow() {
			if (!this.isLoading) {
				this.users.push({
					aktiv: "J",
					beh_andra: "N",
					beh_inventera: "N",
					beh_reklamera: "N",
					beh_produktinfo: "N",
					epost: "",
					foretag: "",
					id: null,
					login: "",
					regdatum: null,
					senaste_inloggning: null,
					tel: "",
					namn: "",
				});
				setTimeout(() => {
					window.scrollTo(0, document.body.scrollHeight);
				}, 1);
			}
		},

		getUsers() {
			if (this.adminRole) {
				this.isLoading = true;
				this.isEditing = false;
				admin_api
					.getUsers(this.clientId)
					.then((res) => {
						this.users = res.data;
						this.lastIndex = this.users.length;
						this.isLoading = false;
						this.addRow();
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},
		updateUsers() {
			this.verifyUpdate = false;
			let validUser = true;
			for (let i = 0; i < this.users.length; i++) {
				if (this.users[i].validLogin === false) {
					validUser = false;
					break;
				}
			}
			if (validUser) {
				admin_api
					.updateUsers(this.users)
					.then(() => {
						this.verifyUpdate = false;

						this.getUsers();
					})
					.catch(() => {
						this.updateError = true;
					});
			} else {
				this.showWarning = true;
			}
		},
	},
	mounted() {
		this.getUsers();
		this.login = this.$store.state.client.login;
	},

	watch: {
		clientId() {
			this.users = [];
			this.showWarning = false;
			this.adminRole = this.$store.getters["adminRole"];

			this.getUsers();
		},

		updateError(bool) {
			if (!bool) {
				document.body.classList.remove("modal-open");
			}
		},

		verifyUpdate(bool) {
			if (!bool) {
				document.body.classList.remove("modal-open");
			}
		},

		isEditing(val) {
			this.$emit("isEditing", val);
		},
	},
};
</script>

<style scoped>
.spec-width {
	max-width: 100%;
	width: 14rem;
}
</style>

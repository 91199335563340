<template>
	<div class="modal-wrapper">
		<Transition name="fade">
			<div class="container-fluid medium-modal-container" v-if="showContent">
				<div class="row">
					<div class="col ps-0">
						<slot name="modal-header-title"></slot>
					</div>
					<div
						class="col-sm-1 pe-0 d-flex justify-content-end align-items-start"
					>
						<img
							src="@/assets/img/close.svg"
							alt="close icon"
							class="close-icon"
							@click="closeModal()"
						/>
					</div>
				</div>

				<div class="row medium-modal-content gr-color mt-3">
					<slot name="modal-content"></slot>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	emits: ["close"],
	data() {
		return {
			showContent: false,
		};
	},

	methods: {
		closeModal() {
			this.showContent = false;
			window.setTimeout(() => {
				this.$emit("close");
				document.body.classList.remove("modal-open");
			}, 200);
		},
	},
	mounted() {
		this.$store.state.modalActive = true;

		this.showContent = true;
		document.body.classList.add("modal-open");
	},
	beforeUnmount() {
		this.$store.state.modalActive = false;
	},
	watch: {},
};
</script>

<style scoped>
.medium-modal-content {
	margin-left: -2rem;
	margin-right: -2rem;
}
.close-icon {
	width: 1.6rem;
}
.medium-modal-header {
	height: 14%;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.close-icon {
	width: 1.2rem;
}
.close-icon:hover {
	transform: scale(1.1);
	transition: 0.1s;
	cursor: pointer;
}

.medium-modal-container {
	padding: 2rem;
	width: 36rem;
	background-color: white;
	height: 40rem;
}
</style>

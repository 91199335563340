<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid sticky-header" v-else>
			<div class="table-header-b row align-items-center">
				<div
					class="col-sm-3 ps-0"
					:class="sortOption.title === 'namn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('namn')"
					>
						<p class="me-2">{{ $t("tableHeaders.name") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'namn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-3"
					:class="sortOption.title === 'epost' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('epost')"
					>
						<p class="me-2">{{ $t("tableHeaders.email") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'epost' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'foretag' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('foretag')"
					>
						<p class="me-2">{{ $t("tableHeaders.company") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'foretag' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col-sm-4 d-flex">
					<p class="me-4">{{ $t("tableHeaders.permissions") }}</p>
					<Popper :hover="true">
						<template #content>
							<div class="popper-content">
								<p class="fw-bolder">
									{{ $t("tableHeaders.infoAboutPermissions") }}:
								</p>

								<p>{{ $t("textFields.viewPermission") }}</p>
								<p>{{ $t("textFields.inventoryPermission") }}</p>
								<p>{{ $t("textFields.claimPermission") }}</p>
								<p>{{ $t("textFields.orderPermission") }}</p>
								<p>{{ $t("textFields.changePermission") }}</p>
							</div>
						</template>
						<img
							src="@/assets/img/info-icon.svg"
							class="info-icon"
							alt="info icon"
						/>
					</Popper>
				</div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<template v-for="user in filteredUsers" :key="user">
				<div
					class="row table-item-b align-items-center"
					v-if="user.aktiv === 'J'"
				>
					<div class="col-sm-3 fw-bold d-flex justify-content-between ps-0">
						<p>{{ user.namn }}</p>
						<p class="fw-light me-5">
							{{ user.beh_admin === "J" ? "Admin" : "" }}
						</p>
					</div>

					<div class="col-sm-3">
						<p class="ellipsis">{{ user.epost }}</p>
					</div>
					<div class="col-sm-2">{{ user.foretag }}</div>
					<div class="col-sm-4 d-flex align-items-center">
						<p>{{ $t("textFields.view") }}</p>
						<p class="ellipsis">
							{{
								user.beh_reklamera === "J" ? `, ${$t("textFields.claim")}` : ""
							}}{{ user.beh_andra === "J" ? `, ${$t("textFields.order")}` : ""
							}}{{
								user.beh_inventera === "J" ? `, ${$t("textFields.store")}` : ""
							}}{{
								user.beh_produktinfo === "J"
									? `, ${$t("textFields.editProductInfo")}`
									: ""
							}}
						</p>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import admin_api from "@/services/api/admin_api";
import Popper from "vue3-popper";
import router from "@/router";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
export default {
	components: {
		LoaderSpinner,
		Popper,
	},
	props: { searchValue: String },
	data() {
		return {
			users: [],
			isLoading: false,
			adminRole: this.$store.getters["adminRole"],
			sortOption: {
				title: "",
				active: false,
			},
			login: "",
		};
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredUsers() {
			if (this.searchValue.length === 0) {
				return this.users;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.users.filter((u) => {
					return (
						u.namn.toLowerCase().includes(searchText) ||
						u.login.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		getUsers() {
			if (this.adminRole) {
				this.isLoading = true;
				admin_api
					.getUsers()
					.then((res) => {
						this.users = res.data;
						this.isLoading = false;
					})
					.catch(() => {
					
							router.push({ name: "Home" });
						
					});
			} else {
				router.push({ name: "Home" });
			}
		},

		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (this.sortOption.active) {
					this.users.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.users.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				this.users.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
			}
		},
	},
	mounted() {
		this.getUsers();
		this.login = this.$store.state.client.login;
	},

	watch: {
		clientId() {
			this.users = [];
			this.adminRole = this.$store.getters["adminRole"];
			this.getUsers();
		},
	},
};
</script>

<style scoped>
.ellipsis {
	max-width: 80%;
}
</style>

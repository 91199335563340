<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div
			v-else
			class="container-fluid ps-0 pe-0"
			:class="isActive ? '' : 'sticky-header'"
		>
			<div class="row layout-padding m-0">
				<div
					class="col-sm-3 table-header ps-0"
					:class="sortOption.title === 'benamn1' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('benamn1')"
					>
						<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'benamn1' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'artikelnr' ? 'active-th' : ''"
				>
					<p>{{ $t("tableHeaders.artNumber") }}</p>
				</div>
				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'levnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('levnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.brand") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'levnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'antal' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('antal')"
					>
						<p class="me-2">{{ $t("tableHeaders.productAmount") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'antal' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col table-header"></div>
			</div>
		</div>

		<div
			class="container-fluid ps-0 pe-0"
			v-for="article in filteredArticles"
			:key="article.artikelnr"
		>
			<div
				class="row align-items-center layout-padding m-0"
				:class="article.active ? 'sticky-header gr-color' : ''"
				:style="{ borderBottom: article.active ? '0' : '' }"
				@click="showRooms(article)"
			>
				<div class="col-sm-3 ps-0 d-flex align-items-center table-item">
					<div class="product-image-container">
						<img
							v-if="article.bild"
							v-lazy="article.bild"
							alt="product-image"
							class="product-image"
						/>

						<img
							v-else
							src="@/assets/img/no-image.svg"
							alt="product-image"
							class="product-image err-image"
						/>
					</div>

					<p class="ms-2 fw-bold ellipsis">{{ article.benamn1 }}</p>
				</div>

				<div class="col-sm-2 table-item">
					{{ article.artikelnr }}
				</div>
				<div class="col-sm-2 table-item">
					{{ article.levnamn }}
				</div>

				<div class="col-sm-2 table-item">
					{{ article.antal }}
				</div>

				<div class="col-sm-3 d-flex justify-content-end table-item">
					<Popper :hover="true">
						<template #content>
							<div class="popper-content">
								<p>{{ $t("buttonTexts.showProductInfo") }}</p>
							</div>
						</template>
						<img
							src="@/assets/img/search-file.svg"
							alt="edit article icon"
							class="edit-article-image me-4"
							@click.stop="openModal(article)"
						/>
					</Popper>
					<button
						class="standard-btn me-2"
						:class="
							inactiveButtons && !article.active
								? 'active-btn'
								: article.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							article.active
								? $t("buttonTexts.closeProduct")
								: $t("buttonTexts.showProduct")
						}}
					</button>
				</div>
			</div>
			<div class="row mt-4 mb-5 ms-0 me-0" v-if="article.active" @click.stop>
				<ProductRooms
					:articleRooms1="articleRooms1"
					@refreshLocation="getArticles()"
					@multiMove="updateAndOpenProduct"
				/>
			</div>
		</div>
		<div class="container-fluid ps-0 pe-0">
			<div class="row layout-padding ms-0 me-0">
				<div class="col bottom-border"></div>
			</div>
		</div>
	</div>

	<EditArticleInformation
		v-if="displayModal"
		@closeModal="closeModal()"
		:articleData="articleData"
		@refresh="getArticles()"
	/>
</template>

<style scoped>
.standard-btn {
	min-width: 7.6rem;
}
</style>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import ProductRooms from "@/components/main_view_components/ProductRooms.vue";
import EditArticleInformation from "@/components/main_view_components/product_components/EditArticleInformation.vue";
import article_filter_api from "@/services/api/article_filter_api.js";
import Popper from "vue3-popper";
import router from "@/router";
export default {
	components: {
		ProductRooms,
		LoaderSpinner,
		EditArticleInformation,
		Popper,
	},
	props: { searchValue: String },
	computed: {
		isActive() {
			let active = false;
			this.articles.map((art) => {
				if (art.active) {
					active = true;
				} else {
					active = false;
				}
			});
			return active;
		},

		clientId() {
			return this.$store.state.client.klient;
		},
		lang() {
			return this.$store.state.language;
		},
		filteredArticles() {
			if (this.searchValue.length === 0) {
				return this.articles;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.articles.filter((a) => {
					return (
						a.artikelnr.toLowerCase().includes(searchText) ||
						a.text_etikett.toLowerCase().includes(searchText) ||
						a.benamn1.toLowerCase().includes(searchText) ||
						a.levnamn.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	data() {
		return {
			sampleImage: require("../../assets/img/no-image.svg"),
			articleRooms1: [],
			isLoading: false,
			articles: [],
			articleData: [],
			displayModal: false,
			artikelnr: null,
			plats: null,
			sortOption: {
				title: "",
				active: false,
			},
			inactiveButtons: false,
		};
	},

	methods: {
		updateAndOpenProduct() {
			this.getArticles();
		},
		openModal(article) {
			this.articleData = article;
			this.displayModal = true;
		},
		closeModal() {
			this.displayModal = false;
		},
		showRooms(art) {
			art.active = !art.active;

			this.articles.map((artX) => {
				if (artX.artikelnr !== art.artikelnr) {
					artX.active = false;
				}
			});

			this.articleRooms1 = art;

			if (!art.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
		getArticles: function () {
			this.articles = [];
			this.isLoading = true;
			this.inactiveButtons = false;
			article_filter_api
				.getArticles()
				.then((res) => {
					this.articles = res.data;
					this.articles.map((a) => {
						Object.keys(a).find((key) => {
							if (a[key] === null) {
								a[key] = "";
							}
						});
					});

					this.isLoading = false;
				})
				.catch((res) => {
					if (res.response?.status === 401) {
						router.push({ name: "Home" });
					}
				});
		},

		setSorting(opt) {
			let sorting = opt.toLowerCase();

			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (opt === "benamn1" || opt === "levnamn") {
					if (this.sortOption.active) {
						this.articles.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
					} else {
						this.articles.sort().reverse();
					}
				} else {
					Number(opt);
					if (this.sortOption.active) {
						this.articles.sort((a, b) => a[opt] - b[opt]);
					} else {
						this.articles.sort().reverse();
					}
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt === "benamn1" || opt === "levnamn") {
					this.articles.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
				} else {
					Number(opt);
					this.articles.sort((a, b) => a[opt] - b[opt]);
				}
			}
		},
	},

	watch: {
		clientId() {
			this.articles = [];
			this.articleRooms1 = [];
			this.getArticles();
		},
	},
	mounted() {
		this.getArticles();
	},
};
</script>

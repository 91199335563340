import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
export default Vuex.createStore({
	state() {
		return {
			client: 0,
			language: null,
			cookies: false,
			modalActive: false,
			locale: null,
		};
	},
	getters: {
		client(state) {
			return state.client;
		},
		locale(state) {
			return state.locale;
		},
		language(state) {
			return state.language;
		},
		cookies(state) {
			return state.cookies;
		},
		adminRole(state) {
			return state.client.beh_admin === "J";
		},
		inventRole(state) {
			return (
				state.client.beh_inventera === "J" || state.client.beh_admin === "J"
			);
		},
		claimRole(state) {
			return (
				state.client.beh_reklamera === "J" || state.client.beh_admin === "J"
			);
		},
		changeRole(state) {
			return state.client.beh_andra === "J" || state.client.beh_admin === "J";
		},
		productRole(state) {
			return (
				state.client.beh_produktinfo === "J" || state.client.beh_admin === "J"
			);
		},
	},
	mutations: {
		SET_COOKIES(state, value) {
			state.cookies = value;
		},
		SET_CLIENT(state, value) {
			state.client = value;
		},
		SET_LANG(state, value) {
			state.language = value;
		},
	},
	actions: {
		changeCookies({ commit }, value) {
			commit("SET_COOKIES", value);
		},
		changeClient({ commit }, client) {
			commit("SET_CLIENT", client);
		},
		changeLanguage({ commit }, Lang) {
			commit("SET_LANG", Lang);
		},
	},
	plugins: [createPersistedState()],
	modules: {
		auth,
	},
});

<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid sticky-header" v-else>
			<div class="row table-header-b align-items-center">
				<div
					class="col-sm-2 ps-0"
					:class="sortOption.title === 'enhet' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('enhet')"
					>
						<p class="me-2">{{ $t("tableHeaders.location2") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'enhet' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'hus' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('hus')"
					>
						<p class="me-2">{{ $t("tableHeaders.building") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'hus' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'vaning' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('vaning')"
					>
						<p class="me-2">{{ $t("tableHeaders.floor") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'vaning' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2"
					:class="sortOption.title === 'rum' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('rum')"
					>
						<p class="me-2">{{ $t("tableHeaders.room") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'rum' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2"
					:class="sortOption.title === 'platsnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('platsnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.locationName") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'platsnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 d-flex justify-content-center"
					:class="sortOption.title === 'antal' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('antal')"
					>
						<p class="me-2 no-wrap">{{ $t("tableHeaders.productAmount") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'antal' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<div
				class="row table-item-b align-items-center"
				v-for="(room, i) in filteredRooms"
				:key="room"
			>
				<div class="col-sm-2 ps-0">
					<Multiselect
						class="left-multiselect selector-l"
						v-if="distinctLocations.length > 1"
						:open-direction="i === 0 || i === 1 || i === 2 ? 'bottom' : ''"
						:options="distinctLocations.map((loc) => loc.enhet)"
						:taggable="true"
						@tag="
							(newLoc) => {
								distinctLocations.push({ enhet: newLoc });
								room.enhet = newLoc;
								isEditing = true;
							}
						"
						:tag-placeholder="$t('placeHolders.add')"
						v-model="room.enhet"
						:show-labels="false"
						:placeholder="$t('placeHolders.chooseLocation')"
						@select="isEditing = true"
						><template v-slot:caret>
							<div class="multiselect__select">
								<img
									src="../../assets/img/down-icon.svg"
									alt="dropdown icon"
								/></div
						></template>
					</Multiselect>
					<input
						v-else
						@input="isEditing = true"
						class="input-field"
						type="text"
						maxlength="100"
						v-model="room.enhet"
						:placeholder="$t('tableHeaders.location2')"
					/>
				</div>

				<div class="col-sm-2">
					<input
						@input="isEditing = true"
						class="input-field"
						type="number no-arrows"
						v-model="room.hus"
						:placeholder="$t('tableHeaders.building')"
					/>
				</div>
				<div class="col-sm-2">
					<input
						@input="isEditing = true"
						class="input-field no-arrows w-50"
						type="number"
						v-model="room.vaning"
						:placeholder="$t('tableHeaders.floor')"
					/>
				</div>

				<div class="col-sm-2">
					<input
						@input="isEditing = true"
						class="input-field"
						type="text"
						maxlength="12"
						v-model="room.rum"
						:placeholder="$t('tableHeaders.room')"
					/>
				</div>

				<div class="col-sm-2 d-flex align-items-center">
					<input
						@input="isEditing = true"
						class="input-field w-100"
						type="text"
						maxlength="100"
						v-model="room.platsnamn"
						:placeholder="$t('tableHeaders.locationName')"
					/>
					<RequiredWarning
						v-if="showWarning && !room.platsnamn.length"
						:warningText="$t('buttonTexts.locationRequired')"
						class="ms-1"
					/>
				</div>
				<div class="col-sm-2 d-flex justify-content-center">
					<p>{{ room.antal }}</p>
				</div>
			</div>
		</div>
	</div>
	<footer class="container-fluid sticky-footer" v-if="isEditing">
		<button class="active-btn" @click="checkValidValue">
			{{ $t("buttonTexts.saveChanges") }}
		</button>
	</footer>

	<VerificationModal
		:header="$t('buttonTexts.save')"
		v-if="saveVerification"
		:verificationText="$t('verificationTexts.saveChanges')"
		@close="saveVerification = false"
		@accept="updateRooms()"
	/>
	<VerificationModal
		:header="$t('buttonTexts.reset')"
		v-if="resetVerification"
		:verificationText="$t('verificationTexts.resetChanges')"
		@close="resetVerification = false"
		@accept="getHandleRooms()"
	/>
	<ResponseModal
		v-if="editSuccess"
		@close="
			editSuccess = false;
			getHandleRooms();
		"
		:responseText="$t('responseText.roomUpdateSuccess')"
	/>
	<ResponseModal
		v-if="editFail"
		@close="editFail = false"
		:response="0"
		:responseText="
			$t('responseText.roomUpdateError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
	/>
</template>

<script>
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import room_handle_api from "@/services/api/room_handle_api";
import Multiselect from "vue-multiselect";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import router from "@/router";

export default {
	components: {
		ResponseModal,
		VerificationModal,
		LoaderSpinner,
		RequiredWarning,
		Multiselect,
	},

	data() {
		return {
			editSuccess: false,
			editFail: false,
			rooms: [],
			isLoading: true,
			validValue: false,
			isEditing: false,
			saveVerification: false,
			resetVerification: false,
			showWarning: false,
			inventRole: this.$store.getters["inventRole"],
			sortOption: {
				title: "",
				active: false,
			},
			distinctLocations: [],
		};
	},
	emits: ["isEditing"],
	props: { searchValue: String },
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredRooms() {
			if (this.searchValue.length === 0) {
				return this.rooms;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.rooms.filter((r) => {
					return (
						r.hus.toLowerCase().includes(searchText) ||
						r.platsnamn.toLowerCase().includes(searchText) ||
						r.rum.toLowerCase().includes(searchText) ||
						r.enhet.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		addTag(newLoc) {
			this.distinctLocations.push({ enhet: newLoc });
		},
		getHandleRooms: function () {
			if (this.inventRole) {
				this.isLoading = true;
				room_handle_api
					.getHandleRooms()
					.then((res) => {
						this.rooms = res.data;

						this.rooms.map((r) => {
							Object.keys(r).find((key) => {
								if (r[key] === null) {
									r[key] = "";
								}
							});
						});

						this.isEditing = false;
						this.saveVerification = false;
						this.resetVerification = false;
						this.showWarning = false;
						this.isLoading = false;
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},
		getDistinctLocations() {
			room_handle_api.getDistinctLocations().then((res) => {
				this.distinctLocations = res.data;
			});
		},
		checkValidValue() {
			let validLocName = true;
			for (let i = this.rooms.length - 1; i > 0; i--) {
				if (this.rooms[i].platsnamn === "") {
					this.showWarning = true;
					validLocName = false;
				}
			}
			if (validLocName) {
				this.saveVerification = true;
				this.showWarning = false;
			}
		},

		updateRooms: function () {
			this.saveVerification = false;
			room_handle_api
				.updateRooms(this.rooms)
				.then(() => {
					this.isEditing = false;
					this.editSuccess = true;
				})
				.catch(() => {
					this.editFail = true;
				});
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (opt === "platsnamn" || opt === "rum" || opt === "enhet") {
					if (this.sortOption.active) {
						this.rooms.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
					} else {
						this.rooms.sort().reverse();
					}
				} else {
					Number(opt);
					if (this.sortOption.active) {
						this.rooms.sort((a, b) => a[opt] - b[opt]);
					} else {
						this.rooms.sort().reverse();
					}
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt === "platsnamn" || opt === "rum" || opt === "enhet") {
					this.rooms.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
				} else {
					Number(opt);
					this.rooms.sort((a, b) => a[opt] - b[opt]);
				}
			}
		},
	},
	mounted() {
		this.getHandleRooms();
		this.getDistinctLocations();
	},
	watch: {
		clientId() {
			this.rooms = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getHandleRooms();
			this.getDistinctLocations();
		},

		isEditing(val) {
			this.$emit("isEditing", val);
		},
	},
};
</script>

<style scoped></style>

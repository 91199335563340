<template>
	<div class="row mb-2 layout-padding m-0">
		<div class="col ps-0 pe-0">
			<p class="large-text">{{ $t("navbarTitle.productOverview") }}</p>
		</div>
	</div>
	<div class="row mb-3 layout-padding m-0">
		<div class="col ps-0 pe-0">
			<p class="small-text">
				{{ $t("landingPage.productInformation") }}
			</p>
		</div>
	</div>
	<div class="row mb-3 layout-padding m-0">
		<div class="col d-flex ps-0 pe-0">
			<button
				:class="`${option === 0 ? 'active-filter' : 'filter-btn'}`"
				@click="option === 0 ? $refs.roomFilter.getRooms() : (option = 0)"
			>
				{{ $t("filterOptions.filterByRoom") }}
			</button>
			<button
				:class="`${option === 1 ? 'active-filter' : 'filter-btn'}`"
				@click="option === 1 ? $refs.productFilter.getArticles() : (option = 1)"
			>
				{{ $t("filterOptions.filterByProduct") }}
			</button>
			<button
				:class="`${option === 2 ? 'active-filter' : 'filter-btn'}`"
				@click="
					option === 2 ? $refs.idFilter.getUniqueArticles() : (option = 2)
				"
			>
				{{ $t("filterOptions.filterById") }}
			</button>

			<div class="search-bar">
				<img
					src="@/assets/img/search-icon.svg"
					alt="search-icon"
					class="search-icon"
				/>
				<input
					type="text"
					class="text-input"
					:placeholder="getPlaceholder()"
					v-model="searchValue"
				/>
			</div>
		</div>
	</div>
	<RoomFilter ref="roomFilter" :searchValue="searchValue" v-if="option === 0" />

	<ProductFilter
		ref="productFilter"
		:searchValue="searchValue"
		v-if="option === 1"
	/>

	<IdFilter
		ref="idFilter"
		:searchValue="searchValue"
		v-if="option === 2 && productOption"
	/>
</template>

<script>
import RoomFilter from "@/views/main_view/RoomFilter.vue";
import ProductFilter from "@/views/main_view/ProductFilter.vue";
import IdFilter from "./IdFilter.vue";

export default {
	components: {
		ProductFilter,
		RoomFilter,
		IdFilter,
	},
	data() {
		return {
			option: 0,
			productOption: true,
			orderOption: false,
			searchValue: "",

			windowWidth: window.innerWidth,
		};
	},
	computed: {},
	methods: {
		getPlaceholder() {
			if (this.option === 0) {
				return this.$t("placeHolders.searchLocation");
			} else if (this.option === 1) {
				return this.$t("placeHolders.searchProduct");
			} else if (this.option === 2) {
				return this.$t("placeHolders.searchId");
			} else {
				return this.$t("placeHolders.searchOrder");
			}
		},
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	watch: {
		option() {
			this.searchValue = "";
		},
		productOption(bool) {
			if (bool) {
				this.orderOption = false;
			}
		},
		orderOption(bool) {
			if (bool) {
				this.productOption = false;
			}
		},
	},
};
</script>

<style scoped>
.small-text {
	font-size: 14px;
}
.fw-bolder {
	font-size: 14px;
}
</style>

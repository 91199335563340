import api from "@/services/api";

export default {
	getHandleRooms() {
		return api().get("/room/handler");
	},
	getDistinctLocations() {
		return api().get("/room/handler/distinct");
	},
	updateRooms(rum) {
		return api().post("/room/handler", JSON.stringify({ rum: rum }));
	},
	combineRooms(plats, toLocation) {
		return api().post(
			"/room/handler/combine",
			JSON.stringify({ toLocation: toLocation, plats: plats })
		);
	},
};

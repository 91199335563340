<template>
	<div class="container-fluid ps-0 pe-0">
		<div class="row">
			<div class="col-sm-6">
				<h5>404 URL not found</h5>
			</div>
			<div class="col"></div>
		</div>
		<div class="row mt-3">
			<div class="col-sm-12">
				<p>
					The URL that you are attempting to use does not exist. Click the
					button below to return to the home page.
				</p>
			</div>
		</div>

		<div class="row mt-5 align-items-center">
			<div class="col-sm-3">
				<button class="active-btn w-50" @click="navToHome()">Return</button>
			</div>
			<div class="col-sm-4"></div>
		</div>
	</div>
</template>
<script>
import router from "@/router";

export default {
	data() {
		return {};
	},
	computed: {},
	methods: {
		navToHome() {
			router.push({ name: "Home" });
		},
	},
	mounted() {},
};
</script>

<style scoped>
.req-list {
	position: fixed;
	z-index: 3;
	top: 18%;

	background-color: white;
	padding-left: 1.6rem;
	padding-right: 1rem;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	color: black;
	border-radius: 10px;

	font-size: 14px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.req-list p {
	font-weight: bolder;
}
.reset-pw-wrapper {
	height: 70vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reset-pw-container {
	width: 90%;
	height: 80%;
	background-color: #f8f5ee;
	border-radius: 20px;
	display: flex;
	justify-content: center;
}

.reset-pw-content {
	margin-top: 4%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.reset-pw-content h2 {
	margin-bottom: 10%;
	text-align: center;
}

.reset-pw-content h2 {
	margin-bottom: 10%;
}

.pw-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2rem;
}

.pw-row h3 {
	margin-bottom: 1rem;
}

.pw-row input {
	height: 3rem;
	border-radius: 10px;
	border: 1px solid grey;
	width: 18rem;
	font-size: 20px;
	padding-left: 1rem;
}

.save-btn {
	height: 42px;
	font-size: 14px;
}
.sub-header {
	width: 100%;
	height: 4rem;

	background-color: #f8f5ee;
	margin-bottom: 1rem;
}

.sub-header-container {
	width: 100%;
	height: 100%;
}

.sub-header h2 {
	margin-left: 1rem;
}

.navbar-container {
	width: 100%;
	display: flex;
	height: 10vh;
	align-items: center;
}

.logo {
	height: 40%;
	cursor: pointer;
}
</style>

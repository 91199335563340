<template>
	<div class="wrapper-img-upload-class">
		<img
			@click="
				if (editable) {
					openUpload();
				}
			"
			v-if="!isLoading"
			:src="content + id"
			class="img-upload-class"
			ref="imgUploader"
			alt="uploaded image"
		/>
		<input @change="uploadFile()" ref="fileUploader" type="file" hidden />
		<div
			:class="'image-upload-icon ' + (editable ? 'editable-icon' : null)"
		></div>
	</div>
</template>

<script>
import doc_bank_api from "@/services/api/doc_bank_api";

export default {
	components: {},
	props: {
		modelValue: { type: String },
		file: { type: File },
		version: { type: Number, default: 1 },
		editable: { type: Boolean, default: true },
		start_file: { type: File, default: null },
	},
	emits: ["update:modelValue", "updated"],
	data() {
		return {
			id: null,
			isLoading: true,
			content: process.env.VUE_APP_CONTENT_URL,
			uploadedFile: {},
		};
	},
	mounted() {
		if (this.start_file !== null) {
			this.postStartFile(this.start_file);
		} else {
			this.id = this.modelValue;
			this.isLoading = false;
		}
	},
	methods: {
		postStartFile(file) {
			const formData = new FormData();
			formData.append("file", file);

			doc_bank_api
				.postImage(formData)
				.then((response) => {
					this.id = response.data;
					this.$emit("update:modelValue", this.id);
					this.isLoading = false;
					this.$emit("updated");
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		openUpload: function () {
			let fileInputElement = this.$refs.fileUploader;
			fileInputElement.click();
		},

		uploadFile() {
			this.isLoading = true;
			this.uploadedFile = {
				file: this.$refs.fileUploader.files[0],
			};

			const formData = new FormData();
			formData.append("file", this.uploadedFile.file);
			doc_bank_api
				.postImage(formData)
				.then((response) => {
					this.id = response.data;
					this.$emit("update:modelValue", this.id);
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
	},
};
</script>

<style scoped>
.img-upload-class {
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.wrapper-img-upload-class {
	position: relative;
	width: 100%;
	height: 100%;
}
.image-upload-icon {
	position: absolute;
	display: none;
	right: 0px;
	bottom: 0px;
}
</style>

<template>
	<div
		class="main-container ps-0 pe-0"
		:style="{ height: isLoading ? '10rem' : '' }"
	>
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid mb-4" v-if="articleRooms.length >= 6">
			<div class="row layout-padding">
				<div class="col-sm-6 ps-0 pe-0 d-flex">
					<div class="space"></div>
					<div class="search-bar ms-2">
						<img
							src="@/assets/img/search-icon.svg"
							alt="search icon"
							class="search-icon"
						/>
						<input
							type="text"
							class="text-input"
							:placeholder="$t('placeHolders.searchLocation')"
							v-model="searchValue"
						/>
					</div>
				</div>
				<div class="col"></div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row align-items-center layout-padding">
				<div class="col-sm-3 no-wrap d-flex ps-0 pe-0">
					<div class="space"></div>
					<div class="ms-2 table-header w-100">
						<p>
							{{ $t("tableHeaders.chooseOneRoomBelow") }}
						</p>
					</div>
				</div>

				<div class="col-sm-2 table-header">
					<p>{{ $t("tableHeaders.productAmount") }}</p>
				</div>
				<div class="col pe-0 table-header"></div>
			</div>
		</div>
		<div
			class="container-fluid main-container"
			v-for="room in filteredRooms"
			:key="room"
		>
			<div
				class="row align-items-center layout-padding"
				@click="showUniques(room)"
				:class="room.active ? 'lower-sticky-header gr-color' : ''"
			>
				<div class="col-sm-3 d-flex ps-0 pe-0">
					<div class="space"></div>
					<div
						class="ms-2 w-100 table-item"
						:style="{ borderBottom: room.active ? '0' : '' }"
					>
						{{ room.platsnamn }}
					</div>
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.antal }}
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					<button
						class="standard-btn"
						:class="
							inactiveButtons && !room.active
								? 'active-btn'
								: room.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							room.active
								? $t("handleRooms.closeRoom")
								: $t("buttonTexts.showRoom")
						}}
					</button>
				</div>
				<div
					class="col table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				></div>
			</div>

			<div class="row mt-3 mb-5" @click.stop v-if="room.active">
				<UniqueIds
					:idList="idList"
					@update="getArticleRooms()"
					@multiMove="$emit('multiMove', idList)"
				/>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row layout-padding">
				<div class="col-sm-12 d-flex ps-0 pe-0">
					<div class="space"></div>
					<div class="bottom-border w-100 ms-2"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import article_filter_api from "@/services/api/article_filter_api";
import UniqueIds from "./UniqueIds.vue";
export default {
	props: { articleRooms1: Object },
	components: { UniqueIds, LoaderSpinner },
	data() {
		return {
			searchValue: "",
			uniqueIds: [],
			idList: [],
			isLoading: false,
			sortOption: {
				title: "",
				active: false,
			},
			articleRooms: [],
			inactiveButtons: false,
		};
	},
	emits: ["refreshLocation", "multiMove"],
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredRooms() {
			if (this.searchValue.length === 0) {
				return this.articleRooms;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.articleRooms.filter((r) => {
					return (
						r.platsnamn.toLowerCase().includes(searchText) ||
						r.hus.toLowerCase().includes(searchText) ||
						r.plats.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		getArticleRooms() {
			this.inactiveButtons = false;

			this.isLoading = true;
			article_filter_api
				.getArticleRooms(this.articleRooms1.artikelnr)
				.then((res) => {
					this.articleRooms = res.data;
					this.articleRooms.map((x) => {
						x.active = false;
					});
					this.isLoading = false;
				});
		},

		showUniques(room) {
			room.active = !room.active;

			this.articleRooms.map((x) => {
				if (x.plats !== room.plats) {
					x.active = false;
				}
			});

			this.idList = room;

			if (!room.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (opt === "platsnamn") {
					if (this.sortOption.active) {
						this.articleRooms.sort((x, y) =>
							x[sorting] < y[sorting] ? -1 : 1
						);
					} else {
						this.articleRooms.sort().reverse();
					}
				} else {
					Number(opt);
					if (this.sortOption.active) {
						this.articleRooms.sort((a, b) => a[opt] - b[opt]);
					} else {
						this.articleRooms.sort().reverse();
					}
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt === "platsnamn") {
					this.articleRooms.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
				} else {
					Number(opt);
					this.articleRooms.sort((a, b) => a[opt] - b[opt]);
				}
			}
		},
	},
	watch: {},

	mounted() {
		this.idList = [];
		this.getArticleRooms();
	},
};
</script>

<style scoped>
.standard-btn {
	min-width: 5.9rem;
}
</style>

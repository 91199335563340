import api from "@/services/api";

export default {
	getInventories() {
		return api().get("/inventory");
	},

	postNewInventory(inv) {
		return api().post("/inventory/create", JSON.stringify({ inv: inv }));
	},

	getInventoryLocations(invid) {
		return api().get(`/inventory/${invid}`);
	},

	postInventoryState(invid) {
		return api().post(`/inventory/${invid}/update`);
	},

	getReport(invid) {
		return api().get(`/inventory/${invid}/pdf`, {
			responseType: "arraybuffer",
		});
	},

	postInventoryArticles(plats, invid, id, atgard) {
		return api().post(
			`/inventory/${invid}/${plats}`,
			JSON.stringify({ atgard: atgard, id: id })
		);
	},

	getInvArticles(invid, plats) {
		return api().get(`/inventory/${invid}/${plats}/showInventory`);
	},

	postScannedArticles(plats, invid, articles) {
		return api().post(
			`/inventory/${invid}/${plats}/scan`,
			JSON.stringify({ articles: articles })
		);
	},

	inventoryControl(id, plats, invid) {
		return api().post(`/inventory/${invid}/${plats}/${id}/control`);
	},
};

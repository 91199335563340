<template>
	<span class="loader">{{ text }}</span>
</template>

<script>
export default {
	props: {
		text: { type: String, default: "" },
	},
};
</script>

<style scoped>
.loader {
	color: black;
	display: inline-block;
	position: relative;
	font-size: 14px;
	box-sizing: border-box;
}
.loader::after {
	content: "";
	width: 2px;
	height: 2px;
	background: black;
	position: absolute;
	bottom: 5px;
	right: -3px;
	box-sizing: border-box;
	animation: animloader 1.4s linear infinite;
}

@keyframes animloader {
	0% {
		box-shadow: 0 0 rgb(255, 255, 255), 0 0 rgb(255, 255, 255);
	}
	50% {
		box-shadow: 5px 0 black, 10px 0 rgb(255, 255, 255);
	}
	100% {
		box-shadow: 5px 0 black, 10px 0 black;
	}
}
</style>

<template>
	<div
		class="main-container container-fluid ps-0 pe-0"
		:style="{ height: isLoading ? '100%' : 'auto' }"
	>
		<div class="row mb-2 mt-5">
			<div class="col">
				<p class="large-text">
					{{ $t("landingPage.welcome") }} {{ userData.namn }}
				</p>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-sm-10 info-text">
				<p>
					{{ $t("landingPage.imsInformation") }}
				</p>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-sm-10 d-flex">
				<div
					class="info-box gr-color pointer"
					@click="pushRoute('ProductOverview')"
				>
					<p class="fw-bolder mt-4 info-header">
						{{ $t("navbarTitle.productOverview") }}
					</p>
					<p class="small-text mt-2">
						{{ $t("landingPage.productInformation") }}
					</p>
				</div>
				<div
					class="info-box gr-color ms-5"
					:class="!inventRole ? 'no-pointer' : 'pointer'"
					@click="inventRole ? pushRoute('HandleRoomsSlot') : ''"
				>
					<p class="fw-bolder mt-4 info-header">
						{{ $t("navbarTitle.roomHandle") }}
					</p>
					<p class="small-text mt-2">
						{{ $t("landingPage.roomInformation") }}
					</p>
				</div>
				<div
					class="info-box gr-color ms-5"
					:class="!inventRole ? 'no-pointer' : 'pointer'"
					@click="inventRole ? pushRoute('InventorySlot') : ''"
				>
					<p class="fw-bolder mt-4 info-header">
						{{ $t("navbarTitle.inventory") }}
					</p>
					<p class="small-text mt-2">
						{{ $t("landingPage.inventoryInformation") }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import admin_api from "@/services/api/admin_api";
import router from "@/router";

export default {
	components: {},
	props: {},
	data() {
		return {
			userData: [],
			isLoading: false,
			inventRole: this.$store.getters["inventRole"],
		};
	},
	computed: {},
	methods: {
		pushRoute(route) {
			router.push({ name: route });
		},
		getUser() {
			this.isLoading = true;
			admin_api
				.getUser(this.$store.state.client.id, this.$store.state.client.login)
				.then((res) => {
					this.userData = res.data;
					this.isLoading = false;
				})
				.catch(() => {
					this.$store.dispatch("auth/handleBackendError");
				});
		},
	},
	watch: {},

	mounted() {
		this.getUser();
	},
};
</script>

<style scoped>
.no-pointer {
	cursor: default;
}

.pointer {
	cursor: pointer;
}
.info-text {
	font-size: 14px;
}

.small-text {
	text-align: left;
}

.info-box {
	height: 14rem;
	width: 14rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
}

.info-box-small {
	height: 10rem;
	width: 14rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
}

.info-box-arrow {
	margin-top: 1rem;
	width: 2rem;
	transform: rotate(-90deg);
}

.info-header {
	text-align: left;
	font-size: 16px !important;
	width: 100%;
}
</style>

<template>
	<GrLayout>
		<template v-slot:top-navbar-right>
			<div class="row" v-if="$store.state.auth.authenticated">
				<div class="col-sm-3"></div>
				<div class="col d-flex align-items-center justify-content-end">
					<p
						@click="pushRoute('/')"
						class="me-5"
						:class="$route.meta.mainHeader === 0 ? 'active-link' : 'nav-link'"
					>
						Dashboard
					</p>

					<CustomSelect
						class="me-5"
						v-if="privileges.length > 1 && !isLoading"
						:default="selectedClient"
						:options="privileges"
						:optKey="'klientnamn'"
						@selectedOption="selectClient"
					/>

					<p v-else></p>

					<p
						@click="pushRoute('/users')"
						class="me-5"
						:class="$route.meta.mainHeader === 4 ? 'active-link' : 'nav-link'"
						v-if="adminRole"
					>
						{{ $t("navbarTitle.users") }}
					</p>
					<CustomSelect
						v-if="langOptions && !isLoading"
						class="me-5"
						:optKey="'lang'"
						:key="selectedLang"
						:options="langOptions"
						:default="selectedLang"
						@selectedOption="selectLanguage"
					/>

					<p class="nav-link" @click="logOut">
						{{ $t("navbarTitle.signout") }}
					</p>
				</div>
			</div>
		</template>

		<template v-slot:container>
			<slot name="container" />
		</template>
		<template v-slot:footer-left-content>
			<p class="mt-2 greenified-info me-5">
				{{ $t("textFields.greenifiedInfo") }}
			</p>
		</template>
		<template v-slot:footer-right-content>
			<div class="footer-row">
				<div class="d-flex flex-column" v-if="$store.state.auth.authenticated">
					<p class="fw-bolder footer-header">{{ $t("footer.myAccount") }}</p>
					<p class="mt-2 greenified-info small-link" @click="pushRoute('/')">
						Dashboard
					</p>
					<p
						class="greenified-info small-link"
						v-if="adminRole"
						@click="pushRoute('users')"
					>
						{{ $t("navbarTitle.users") }}
					</p>
				</div>
				<div v-else></div>

				<div class="d-flex flex-column">
					<p class="fw-bolder footer-header">Greenified</p>
					<a
						class="mt-2 greenified-info small-link"
						:href="`https://greenified.${domain}/valkommen-till-greenified/`"
						target="_blank"
						>{{ $t("footer.aboutGreenified") }}</a
					>
					<a
						class="greenified-info small-link"
						:href="`https://greenified.${domain}/tillaggstjanster/`"
						target="_blank"
						>{{ $t("footer.services") }}</a
					>
				</div>
				<div class="d-flex flex-column">
					<p class="fw-bolder footer-header">{{ $t("footer.contact") }}</p>
					<p class="mt-2 greenified-info">+46 (0)200 77 00 60</p>
					<a
						class="greenified-info small-link"
						href="mailto: info@greenified.se"
						target="_blank"
						>info@greenified.se</a
					>
					<a
						class="small-link mt-4 xs-link"
						:href="`https://greenified.${domain}/integritetspolicy/`"
						target="_blank"
					>
						{{ $t("footer.privacyPolicy") }}
					</a>
				</div>
			</div>
		</template>
	</GrLayout>
</template>

<style scoped>
.breadCrumb {
	font-weight: 600 !important;
}
</style>

<script>
import CustomSelect from "@/assets/component_assets/CustomSelect.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import router from "@/router";
import GrLayout from "./GrLayout.vue";

export default {
	name: "NavBar",
	data() {
		return {
			isLoading: false,
			paths: this.$route,
			privileges: [],
			selectedClient: null,
			langOptions: null,
			domain: this.$store.getters.language || this.$i18n.locale,
			selectedLang: {
				id: this.$store.getters.language || this.$i18n.locale,
				lang:
					this.$store.getters.language?.toUpperCase() ||
					this.$i18n.locale?.toUpperCase(),
			},
			adminRole: this.$store.getters["adminRole"],
			showSelect: false,
		};
	},
	components: { CustomSelect, GrLayout },
	computed: {
		lang() {
			return this.$store.state.language;
		},
		client() {
			return this.$store.state.client;
		},
	},
	methods: {
		pushRoute(route) {
			if (this.$route.name === route) {
				window.location.reload();
			} else {
				router.push({ path: route });
			}
		},
		selectLanguage(lang) {
			this.selectedLang = lang;
			if (this.selectedLang.lang !== "") {
				if (this.getLanguage !== this.selectedLang.id) {
					this.$i18n.locale = this.selectedLang.id;
					this.changeLanguage(this.selectedLang.id);
				}
			}
		},
		selectClient(client) {
			if (this.getClient.klient !== client.klient) {
				this.changeClient(client);
				this.adminRole = this.$store.getters["adminRole"];
			}
		},

		...mapActions({
			signOut: "auth/logout",
			changeClient: "changeClient",
			changeLanguage: "changeLanguage",
		}),
		...mapGetters({
			getClient: "client",
			getLanguage: "language",
		}),
		async logOut() {
			await axios.post("/api/logout").then(() => {
				this.signOut();
				router.push({ name: "login" });
			});
		},

		getLocales() {
			let domains = this.$i18n.messages.sv.list.domains;
			let host = window.location.host;

			this.langOptions = Object.entries(domains[host]?.locales).map((arr) => {
				return { id: arr[0], lang: arr[1] };
			});
		},

		getUserPrivileges: async function () {
			this.isLoading = true;
			await axios.get(`api/${this.getLanguage()}/privileges`).then((res) => {
				this.privileges = res.data;
				if (this.getClient() === 0) {
					this.selectedClient = this.privileges[0];
					this.changeClient(this.privileges[0]);
				} else {
					this.selectedClient = this.getClient();
				}

				this.isLoading = false;
			});

			this.getLocales();
		},
	},

	mounted() {
		this.getUserPrivileges();
		this.mainHeader = this.paths.meta.mainheader;
	},
	watch: {
		client(value) {
			this.selectedClient = value;
		},
	},
};
</script>

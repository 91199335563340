<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div class="container-fluid sticky-header" v-if="inventories.length">
			<div class="row align-items-center table-header-b">
				<div
					class="col-sm-2 ps-0"
					:class="sortOption.title === 'namn' ? 'active-th' : ''"
				>
					<div class="d-flex sorting align-items-center" @click="sort('namn')">
						<p class="me-2">{{ $t("tableHeaders.name") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'namn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="d-flex align-items-center">
						<p>{{ $t("tableHeaders.inventoryAmount") }}</p>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'text_status' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="sort('text_status')"
					>
						<p class="me-2">{{ $t("tableHeaders.state") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'text_status' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'skapad' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="sort('skapad')"
					>
						<p class="me-2">{{ $t("tableHeaders.created") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'skapad' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'datum' ? 'active-th' : ''"
				>
					<div class="d-flex sorting align-items-center" @click="sort('datum')">
						<p class="me-2">{{ $t("tableHeaders.endDate") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'datum' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="container-fluid mt-4 ps-0 pe-0 inventory-container">
			<div class="row">
				<div class="col">
					<div class="bottom-border"></div>
				</div>
			</div>
			<div class="row mt-3">
				<p>{{ $t("textFields.noInventoriesCreated") }}</p>
				<p class="mt-3">
					{{ $t("textFields.click") }}
					<span class="fw-bolder create-link" @click="$emit('newInventory')">
						{{ $t("inventory.createNewInventory") }}
					</span>
					{{ $t("textFields.toGetStarted") }}
				</p>
			</div>
		</div>
		<div
			class="container-fluid"
			v-for="inv in filteredInventories"
			:key="inv.invid"
		>
			<div class="row table-item-b align-items-center">
				<div class="fw-bolder col-sm-2 ps-0">
					{{ inv.namn }}
				</div>
				<div class="col-sm-2 d-flex">
					<p>{{ inv.inv_artiklar }}/{{ inv.tot_inv_artiklar }}</p>
					<img
						src="@/assets/img/check-only.svg"
						alt="check icon"
						class="check-icon ms-2"
						v-if="inv.inv_artiklar === inv.tot_inv_artiklar"
					/>
				</div>

				<div class="col-sm-2">{{ inv.text_status }}</div>
				<div class="col-sm-2">
					{{ inv.skapad !== null ? inv.skapad.substring(0, 10) : "" }}
				</div>
				<div class="col-sm-1 no-wrap">
					{{ inv.datum !== null ? inv.datum.substring(0, 10) : "" }}
				</div>
				<div class="col-sm-3 d-flex justify-content-end">
					<button class="standard-btn confirm-btn" @click="openInventory(inv)">
						{{ $t("buttonTexts.showInventory") }}
					</button>
					<button
						class="standard-btn ms-3"
						v-if="inv.status === 'A'"
						@click="setInvState(inv)"
					>
						{{ $t("modalLabels.close") }}
					</button>
					<div v-else class="ms-3 small-btn"></div>
				</div>
			</div>
		</div>
	</div>

	<NewInventory
		v-if="addInvModal"
		@accept="
			() => {
				addInvModal = false;
				$emit('option', 0);
				getInventories();
				addedInventory = true;
			}
		"
		@cancel="
			addInvModal = false;
			$emit('option', 0);
		"
	/>

	<VerificationModal
		:header="$t('verificationTexts.headers.close')"
		v-if="showVerification"
		:verificationText="
			$t('verificationTexts.closeInventory', [closedInventory.namn])
		"
		@accept="postInventoryState"
		@close="showVerification = false"
	/>

	<ResponseModal
		v-if="closeSuccess"
		:response="1"
		:responseText="$t('responseText.closeSuccess')"
		@close="
			closeSuccess = false;
			getInventories();
		"
	/>

	<ResponseModal
		v-if="closeError"
		:response="0"
		:responseText="
			$t('responseText.somethingWentWrong') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="closeError = false"
	/>
</template>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import inventory_api from "@/services/api/inventory_api";
import VerificationModal from "@/modals/VerificationModal.vue";
import NewInventory from "@/components/inventory_components/NewInventory.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import router from "@/router";
export default {
	components: {
		NewInventory,
		VerificationModal,
		LoaderSpinner,
		ResponseModal,
	},
	emits: ["option", "newInventory"],
	data() {
		return {
			addedInventory: false,
			showVerification: false,
			selectedInventory: [],
			inventories: [],
			isLoading: false,
			showInventory: false,
			closedInventory: [],
			sortOption: {
				title: "",
				active: false,
			},
			inventRole: this.$store.getters["inventRole"],
			addInvModal: false,
			closeSuccess: false,
			closeError: false,
			percentCompleted: null,
			sortDesc: false,
		};
	},
	props: {
		searchValue: String,
		option: Number,
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredInventories() {
			if (this.searchValue.length === 0) {
				return this.sortedItems;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.sortedItems.filter((a) => {
					return a.namn.toLowerCase().includes(searchText);
				});
			}
		},

		sortedItems() {
			if (this.sortOption.title) {
				const key = this.sortOption.title;
				const order = this.sortDesc ? 1 : -1;
				return this.inventories.slice().sort((a, b) => {
					const aValue = a[key];
					const bValue = b[key];

					if (key === "skapad") {
						const dateA = new Date(aValue);
						const dateB = new Date(bValue);

						if (isNaN(dateA) && isNaN(dateB)) return 0;
						if (isNaN(dateA)) return -1;
						if (isNaN(dateB)) return 1;

						return (dateA - dateB) * order;
					}

					if (aValue === null && bValue === null) return 0;
					if (aValue === null) return -1;
					if (bValue === null) return 1;

					if (typeof aValue === "string" && typeof bValue === "string") {
						return aValue.localeCompare(bValue) * order;
					}

					return (aValue - bValue) * order;
				});
			}
			return this.inventories;
		},
	},
	methods: {
		sort(key) {
			if (this.sortOption.title === key) {
				this.sortDesc = !this.sortDesc;
				this.sortOption.active = !this.sortOption.active;
			} else {
				this.sortOption.title = key;
				this.sortOption.active = true;
				this.sortDesc = false;
			}
		},
		hideInvRooms() {
			this.showInventory = false;
		},
		openInventory(inv) {
			router.push({
				name: "InventorySlotRooms",
				params: { invId: inv.invid, locName: inv.namn },
			});
		},
		setInvState(inv) {
			this.closedInventory = inv;

			this.showVerification = true;
		},
		postInventoryState: function () {
			this.showVerification = false;
			inventory_api
				.postInventoryState(this.closedInventory.invid)
				.then(() => {
					this.closeSuccess = true;
				})
				.catch(() => {
					this.closeError = true;
				});
		},

		getInventories: function () {
			if (this.inventRole) {
				this.isLoading = true;
				inventory_api
					.getInventories()
					.then((res) => {
						this.inventories = res.data;
						this.isLoading = false;
						if (this.addedInventory) {
							let idArray = [];
							this.inventories.map((inv) => {
								idArray.push(inv.invid);
							});

							let maxId = Math.max(...idArray);
							let newInventory = this.inventories.filter((inv) => {
								return Number(inv.invid) === maxId;
							});

							this.openInventory(newInventory[0]);
						}

						this.addedInventory = false;
					})
					.catch((res) => {
						if (res.status === 401) {
							router.push({ name: "Home" });
						}
					});
			} else {
				router.push({ name: "Home" });
			}
		},
	},
	mounted() {
		this.getInventories();
	},
	watch: {
		clientId() {
			this.inventories = [];
			this.showInventory = false;
			this.inventRole = this.$store.getters["inventRole"];
			this.getInventories();
		},
		option(val) {
			if (val === 1) {
				this.addInvModal = true;
			}
		},
	},
};
</script>

<style scoped>
.create-link {
	cursor: pointer;
}
.small-btn {
	width: 5rem;
}

@media screen and (max-height: 900px) {
	.inventory-container {
		margin-bottom: 10rem;
	}
}
</style>

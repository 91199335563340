a
<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid" v-if="!reconditions.length">
			<div class="row table-item-b">
				<div class="col ps-0">
					<h5>{{ $t("textFields.noActionsFound") }}</h5>
				</div>
			</div>
		</div>
		<template v-else>
			<ReconditionsTable
				@isEditing="isEditing = true"
				v-if="sort === 0"
				:reconditions="reconditions"
				:searchValue="searchValue"
			/>
			<ReconditionsTable
				v-if="sort === 1"
				:reconditions="inactiveRecs"
				:searchValue="searchValue"
			/>
			<ReconditionsTable
				v-if="sort === 2"
				:reconditions="activeRecs"
				:searchValue="searchValue"
				@isEditing="isEditing = true"
			/>
		</template>

		<footer
			class="container-fluid sticky-footer"
			v-if="reconditions.length && isEditing"
		>
			<button class="active-btn" @click="isEditing ? (verifyRec = true) : ''">
				{{
					isEditing ? $t("buttonTexts.saveAction") : $t("buttonTexts.noChanges")
				}}
			</button>
		</footer>
	</div>

	<VerificationModal
		:header="$t('verificationTexts.headers.action')"
		:verificationText="$t('verificationTexts.updateAction')"
		v-if="verifyRec"
		@close="verifyRec = false"
		@accept="postRecState"
	/>

	<ResponseModal
		v-if="actionSuccess"
		:response="1"
		:responseText="$t('responseText.actionUpdateSuccess')"
		@close="
			actionSuccess = false;
			getActiveRecs();
		"
	/>

	<ResponseModal
		v-if="actionError"
		:response="0"
		:responseText="
			$t('responseText.actionUpdateError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="actionError = false"
	/>
</template>

<script>
import ReconditionsTable from "./ReconditionsTable.vue";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import reconditions_api from "@/services/api/reconditions_api";
import router from "@/router";

export default {
	components: {
		LoaderSpinner,
		VerificationModal,
		ResponseModal,
		ReconditionsTable,
	},
	props: {
		sort: { value: Number, default: null },
		id: { value: String, default: null },
		searchValue: { value: String, default: null },
	},
	data() {
		return {
			activeRecs: [],
			inactiveRecs: [],
			actionSuccess: false,
			actionError: false,
			verifyRec: false,
			isLoading: false,
			isEditing: false,
			loadingDev: false,
			sortOption: {
				title: "",
				active: false,
			},
			inventRole: this.$store.getters["inventRole"],
			reconditions: [],
			recOptions: [
				{
					text: this.$t("inventory.productIsHandled"),
					status: "A",
				},
				{
					text: this.$t("inventory.productIsDiscarded"),
					status: "K",
				},
				{
					text: this.$t("inventory.commentNotRelevant"),
					status: "E",
				},
			],
		};
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		actionText(status) {
			let text = "";
			if (status === "A") {
				text = this.$t("inventory.states.handled");
			} else if (status === "K") {
				text = this.$t("inventory.states.discarded");
			} else {
				text = this.$t("inventory.states.noAction");
			}
			return text;
		},

		getActiveRecs() {
			if (this.inventRole) {
				this.reconditions = [];
				this.activeRecs = [];
				this.inactiveRecs = [];
				this.isLoading = true;
				reconditions_api
					.getActiveRecs(this.id)
					.then((res) => {
						this.reconditions = res.data.all;
						this.activeRecs = res.data.active;
						this.inactiveRecs = res.data.inactive;
						this.isLoading = false;
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},
		postRecState() {
			reconditions_api
				.postRecState(this.id, this.reconditions)
				.then(() => {
					this.isEditing = false;
					this.verifyRec = false;
					this.actionSuccess = true;
				})
				.catch(() => {
					this.actionError = true;
				});
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();

			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;

				this.reconditions.sort().reverse();
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;

				this.reconditions.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
			}
		},
	},
	mounted() {
		this.getActiveRecs();
	},
	watch: {
		clientId() {
			this.reconditions = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getActiveRecs();
		},
	},
};
</script>

<style scoped>
.report-wrapper .standard-btn {
	width: 10rem;
}

.amount-span {
	font-weight: 400 !important;
}
</style>

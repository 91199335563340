<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />

		<div
			v-else
			class="container-fluid ps-0 pe-0"
			:class="isActive ? '' : 'sticky-header'"
		>
			<div class="row layout-padding m-0">
				<div
					class="col-sm-3 table-header ps-0"
					:class="sortOption.title === 'platsnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('platsnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.locationName") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'platsnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'hus' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('hus')"
					>
						<p class="me-2">{{ $t("tableHeaders.building") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'hus' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-1 table-header"
					:class="sortOption.title === 'vaning' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('vaning')"
					>
						<p class="me-2">{{ $t("tableHeaders.floor") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'vaning' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-1 table-header"
					:class="sortOption.title === 'rum' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('rum')"
					>
						<p class="me-2 no-wrap">{{ $t("tableHeaders.room") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'rum' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'antal' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('antal')"
					>
						<p class="me-2">{{ $t("tableHeaders.productAmount") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'antal' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col table-header"></div>
			</div>
		</div>

		<div
			class="container-fluid ps-0 pe-0"
			v-for="room in filteredRooms"
			:key="room"
		>
			<div
				class="row align-items-center layout-padding m-0"
				@click="showProducts(room)"
				:class="room.active ? 'sticky-header gr-color' : ''"
			>
				<div
					class="col-sm-3 fw-bold ellipsis table-item ps-0"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.platsnamn }}
				</div>

				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.hus }}
				</div>

				<div
					class="col-sm-1 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.vaning }}
				</div>

				<div
					class="col-sm-1 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.rum }}
				</div>
				<div
					class="col-sm-1 table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					{{ room.antal }}
				</div>
				<div
					class="col-sm-4 d-flex justify-content-end table-item"
					:style="{ borderBottom: room.active ? '0' : '' }"
				>
					<button
						class="me-2 standard-btn"
						:class="
							inactiveButtons && !room.active
								? 'active-btn'
								: room.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							room.active
								? $t("handleRooms.closeRoom")
								: $t("buttonTexts.showRoom")
						}}
					</button>
				</div>
			</div>
			<div class="row mt-4 mb-5 ms-0 me-0" v-if="room.active" @click.stop>
				<RoomProducts
					@closeRoom="getRooms()"
					ref="roomProducts"
					:roomArticles1="roomArticles1"
					@multiMove="updateAndOpenRoom"
				/>
			</div>
		</div>
		<div class="container-fluid ps-0 pe-0">
			<div class="row layout-padding m-0">
				<div class="col bottom-border"></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
body.modal-open {
	overflow: hidden;
}

.standard-btn {
	min-width: 8rem;
}
</style>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import room_filter_api from "@/services/api/room_filter_api.js";
import RoomProducts from "@/components/main_view_components/RoomProducts.vue";
import router from "@/router";
export default {
	data() {
		return {
			rooms: [],
			isLoading: false,
			chosenValue: false,
			roomArticles1: [],
			locName: "",
			sortOption: {
				title: "",
				active: false,
			},
			ascending: true,
			sortingKey: null,
			inactiveButtons: false,
			downloadProgress: 0,
		};
	},
	components: { RoomProducts, LoaderSpinner },
	props: {
		searchValue: String,
	},
	computed: {
		isActive() {
			let active = false;
			this.rooms.map((room) => {
				if (room.active) {
					active = true;
				} else {
					active = false;
				}
			});
			return active;
		},
		clientId() {
			return this.$store.state.client.klient;
		},
		lang() {
			return this.$store.state.language;
		},

		filteredRooms() {
			if (this.searchValue.length === 0) {
				return this.rooms;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.rooms.filter((r) => {
					return (
						r.hus.toLowerCase().includes(searchText) ||
						r.plats.toLowerCase().includes(searchText) ||
						r.platsnamn.toLowerCase().includes(searchText) ||
						r.rum.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},

	methods: {
		updateAndOpenRoom(location) {
			this.getRooms(location);
		},
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (this.sortOption.active) {
					if (opt === "platsnamn") {
						this.rooms.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
					} else {
						this.rooms.sort((a, b) => {
							const keyA = a[sorting];
							const keyB = b[sorting];

							if (keyA === "" && keyB !== "") {
								return 1;
							} else if (keyA !== "" && keyB === "") {
								return -1;
							} else if (keyA === "" && keyB === "") {
								return 0;
							} else {
								return keyA - keyB;
							}
						});
					}
				} else {
					this.rooms.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt === "platsnamn") {
					this.rooms.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.rooms.sort((a, b) => {
						const keyA = a[sorting];
						const keyB = b[sorting];

						if (keyA === "" && keyB !== "") {
							return 1;
						} else if (keyA !== "" && keyB === "") {
							return -1;
						} else if (keyA === "" && keyB === "") {
							return 0;
						} else {
							return keyA - keyB;
						}
					});
				}
			}
		},

		getRooms: function (location) {
			this.rooms = [];
			this.isLoading = true;
			this.inactiveButtons = false;
			room_filter_api
				.getRooms()
				.then((res) => {
					this.rooms = res.data;

					this.rooms.map((r) => {
						Object.keys(r).find((key) => {
							if (r[key] === null) {
								r[key] = "";
							}
						});
					});

					this.isLoading = false;

					if (location) {
						const previousRoom = this.rooms.find(
							(room) => room.plats === location.plats
						);
						if (previousRoom) {
							this.showProducts(previousRoom);
						}
					}
				})
				.catch(() => {
					router.push({ name: "Home" });
				});
		},

		showProducts(room) {
			this.roomArticles1 = [];
			this.locName = room.platsnamn;
			this.roomArticles1 = room;
			room.active = !room.active;
			this.rooms.map((r) => {
				if (r.plats !== room.plats) {
					r.active = false;
				}
			});
			if (!room.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
	},

	mounted() {
		this.getRooms();
	},

	watch: {
		clientId() {
			this.rooms = [];
			this.getRooms();
		},
	},
};
</script>

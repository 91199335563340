import api from "@/services/api";

export default {
	getArticles() {
		return api().get("/article");
	},

	getArticleRooms(artikelnr) {
		return api().get(`/article/${artikelnr}`);
	},

	
};

<template>
	<div class="modal-wrapper">
		<Transition name="fade">
			<div
				class="container-fluid v-modal-container gr-color"
				v-if="showContent"
			>
				<div class="row modal-header">
					<slot name="modal-header-title"></slot>
				</div>

				<div class="row v-modal-content">
					<slot name="modal-content"></slot>
				</div>
				<div class="row button-container">
					<slot name="button-container"></slot>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	emits: ["close"],
	data() {
		return {
			showContent: false,
		};
	},
	props: { close: { type: Boolean, default: false } },
	methods: {
		closeModal() {
			this.showContent = false;
			window.setTimeout(() => {
				this.$emit("close");
			}, 200);
		},
	},
	watch: {
		close(bool) {
			if (bool) {
				this.closeModal();
				document.body.classList.remove("modal-open");
			}
		},
	},
	mounted() {
		this.$store.state.modalActive = true;

		this.showContent = true;
		document.body.classList.add("modal-open");
	},
	beforeUnmount() {
		this.$store.state.modalActive = false;
	},
};
</script>

<style scoped>
.v-modal-content {
	height: 8rem;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.close-icon {
	width: 1.2rem;
}
.close-icon:hover {
	transform: scale(1.1);
	transition: 0.1s;
	cursor: pointer;
}

.v-modal-container {
	padding: 2rem;
	width: 26rem;
	height: 18rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.button-container {
	display: flex;
	justify-content: center;
}
</style>

<template>
	<GrModalSmall :cancel="cancel">
		<template v-slot:modal-header-title>
			<div class="row">
				<div class="col-sm-12">
					<h5 class="fw-bold fs-6">
						{{ $t("inventory.newInventory") }} 
					</h5>
				</div>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="row">
				<div class="col-sm-12 d-flex align-items-center">
					<p class="fw-bolder small-text me-2">
						{{ $t("inventory.submitNameForInventory") }}
					</p>
					<RequiredWarning
						v-if="showWarning && !createdInventory.invName.length"
						:warningText="$t('warningTexts.requiredField')"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12">
					<input
						class="input-field w-100"
						type="text"
						:placeholder="$t('tableHeaders.name')"
						v-model="createdInventory.invName"
						@input="isEditing = true"
					/>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-sm-12 d-flex align-items-center">
					<p class="fw-bolder small-text me-2">
						{{ $t("inventory.submitDateForInventory") }}
					</p>
					<RequiredWarning
						v-if="showWarning && !createdInventory.invDate.length"
						:warningText="$t('warningTexts.requiredField')"
					/>
					<RequiredWarning
						v-if="showDateWarning && createdInventory.invDate < date"
						:warningText="$t('warningTexts.earliestDate', [date])"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12">
					<input
						class="input-field w-100 date-field"
						type="date"
						v-model="createdInventory.invDate"
						@input="isEditing = true"
					/>
				</div>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="row">
				<div class="col-sm-12 d-flex justify-content-between">
					<button
						class="standard-btn me-3"
						@click="isEditing ? (closeVerification = true) : (cancel = true)"
					>
						{{ $t("buttonTexts.cancel") }}
					</button>

					<button class="active-btn" @click="postNewInventory">
						{{ $t("inventory.createInventory") }}
					</button>
				</div>
			</div>
		</template>
		<template v-slot:modal-slot>
			<VerificationModal
				:verificationText="$t('verificationTexts.closeChanges')"
				v-if="closeVerification"
				@accept="cancel = true"
				@close="closeVerification = false"
			/>
			<ResponseModal
				:response="0"
				v-if="addInvError"
				:responseText="
					$t('responseText.somethingWentWrong') +
					' ' +
					$t('responseText.contactSystemAdministrator')
				"
				@close="addInvError = false"
			/>

			<ResponseModal
				:response="1"
				v-if="addInvSuccess"
				:responseText="
					$t('responseText.addInvSuccess', [createdInventory.invName])
				"
				@close="$emit('accept')"
			/>
		</template>
	</GrModalSmall>
</template>

<script>
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import GrModalSmall from "@/modals/GrModalSmall.vue";
import inventory_api from "@/services/api/inventory_api";
export default {
	data() {
		return {
			addInvError: false,
			addInvSuccess: false,
			date: new Date().toISOString().split("T")[0],
			createdInventory: {
				invName: "",
				invDate: "",
				invType: "I",
			},
			showWarning: false,
			cancel: false,
			isEditing: false,
			closeVerification: false,
			showDateWarning: false,
			rooms: [],
			selectedRooms: [],
		};
	},
	components: {
		GrModalSmall,
		RequiredWarning,
		VerificationModal,
		ResponseModal,
	},
	emits: ["accept"],
	computed: {},
	methods: {
		postNewInventory: function () {
			if (this.createdInventory.invDate >= this.date) {
				this.showDateWarning = false;
				if (
					this.createdInventory.invName.length > 0 &&
					this.createdInventory.invDate.length > 0
				) {
					inventory_api
						.postNewInventory(this.createdInventory)
						.then(() => {
							this.showWarning = false;
							this.addInvSuccess = true;
						})
						.catch(() => {
							this.addInvError = true;
						});
				} else {
					this.showWarning = true;
				}
			} else {
				this.showDateWarning = true;
			}
		},
	},
	mounted() {
		this.createdInventory.invDate = this.date;
	},
};
</script>

<style scoped>
.active-btn {
	white-space: nowrap;
}

.radio-input {
	cursor: pointer;
}
</style>

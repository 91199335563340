<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div class="container-fluid sticky-header" v-else>
			<div class="row table-header-b align-items-center">
				<div
					class="col-sm-1 ps-0"
					:class="sortOption.title === 'enhet' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('enhet')"
					>
						<p class="me-2">{{ $t("tableHeaders.location2") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'enhet' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'hus' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center ps-3"
						@click="setSorting('hus')"
					>
						<p class="me-2">{{ $t("tableHeaders.building") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'hus' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'vaning' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('vaning')"
					>
						<p class="me-2">{{ $t("tableHeaders.floor") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'vaning' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div
					class="col-sm-2"
					:class="sortOption.title === 'rum' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('rum')"
					>
						<p class="me-2">{{ $t("tableHeaders.room") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'rum' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2"
					:class="sortOption.title === 'platsnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('platsnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.locationName") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'platsnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 d-flex justify-content-center"
					:class="sortOption.title === 'antal' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('antal')"
					>
						<p class="me-2">{{ $t("tableHeaders.productAmount") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'antal' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div class="col-sm-1">
					<th>{{ $t("tableHeaders.check") }}</th>
				</div>
			</div>
		</div>
		<div class="container-fluid" v-if="!isLoading">
			<div
				class="row table-item-b align-items-center"
				v-for="room in filteredRooms"
				:key="room"
			>
				<div class="col-sm-1 ps-0">
					{{ room.enhet }}
				</div>
				<div class="col-sm-2">
					<p class="ps-3">{{ room.hus }}</p>
				</div>

				<div class="col-sm-2">{{ room.vaning }}</div>

				<div class="col-sm-2">
					{{ room.rum }}
				</div>

				<div class="col-sm-2">
					{{ room.platsnamn }}
				</div>

				<div class="col-sm-2 d-flex justify-content-center">
					{{ room.antal }}
				</div>

				<div class="col-sm-1">
					<input
						type="checkbox"
						name="room"
						:value="room.plats"
						v-model="combinedRooms"
						@input="isEditing = true"
					/>
				</div>
			</div>
		</div>
	</div>
	<footer
		class="container-fluid sticky-footer"
		v-if="combinedRooms.length && isEditing"
	>
		<div class="col-sm-6"></div>

		<div class="col-sm-6 d-flex align-items-center justify-content-end">
			<p class="me-3">
				{{ $t("handleRooms.mergeRooms", [combinedRooms.length]) }}
			</p>
			<div class="content-s">
				<Multiselect
					class="selector-c right-multiselect"
					:options="filteredObjects"
					v-model="combineToRoom"
					:custom-label="(option) => option.platsnamn"
					:show-labels="false"
					:placeholder="$t('handleRooms.chooseRoom')"
					@select="isEditing = true"
					><template v-slot:caret>
						<div class="multiselect__select">
							<img
								src="@/assets/img/down-icon.svg"
								alt="dropdown icon"
							/></div></template
				></Multiselect>
			</div>
			<RequiredWarning
				v-if="showWarning"
				:warningText="$t('warningTexts.noRoomSelected')"
			/>

			<button
				class="active-btn ms-3"
				@click="
					combineToRoom !== null
						? (combineVerification = true)
						: (showWarning = true)
				"
			>
				{{ $t("buttonTexts.save") }}
			</button>
		</div>
	</footer>
	<VerificationModal
		:header="$t('verificationTexts.headers.combine')"
		v-if="combineVerification"
		:verificationText="
			$t('verificationTexts.combineVerify', [
				combinedRooms.length,
				combineToRoom.platsnamn,
			])
		"
		@close="closeVerification()"
		@accept="combineRooms()"
	/>

	<ResponseModal
		v-if="combineSuccess"
		@close="
			combineSuccess = false;
			getHandleRooms();
		"
		:responseText="$t('responseText.combineSuccess')"
	/>
	<ResponseModal
		v-if="combineFail"
		@close="combineFail = false"
		:response="0"
		:responseText="
			$t('responseText.combineError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
	/>
</template>

<script>
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import Multiselect from "vue-multiselect";
import VerificationModal from "@/modals/VerificationModal.vue";
import room_handle_api from "@/services/api/room_handle_api";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import router from "@/router";

export default {
	components: {
		ResponseModal,
		VerificationModal,
		Multiselect,
		LoaderSpinner,
		RequiredWarning,
	},
	emits: ["isEditing"],
	props: { searchValue: String },
	data() {
		return {
			showWarning: false,
			isEditing: false,
			rooms: [],
			isLoading: true,
			combinedRooms: [],
			locations: [],
			combineToRoom: null,
			combineVerification: false,
			combineSuccess: false,
			combineFail: false,
			inventRole: this.$store.getters["inventRole"],
			sortOption: {
				title: "",
				active: false,
			},
		};
	},
	computed: {
		filteredObjects() {
			if (this.combinedRooms.length === 0) {
				return this.locations;
			}

			return this.locations.filter((location) => {
				return !this.combinedRooms.includes(location.plats);
			});
		},
		clientId() {
			return this.$store.state.client.klient;
		},
		filteredRooms() {
			if (this.searchValue.length === 0) {
				return this.rooms;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.rooms.filter((r) => {
					return (
						r.hus.toLowerCase().includes(searchText) ||
						r.platsnamn.toLowerCase().includes(searchText) ||
						r.rum.toLowerCase().includes(searchText) ||
						r.enhet.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	methods: {
		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (opt === "platsnamn" || opt === "rum" || opt === "enhet") {
					if (this.sortOption.active) {
						this.rooms.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
					} else {
						this.rooms.sort().reverse();
					}
				} else {
					Number(opt);
					if (this.sortOption.active) {
						this.rooms.sort((a, b) => a[opt] - b[opt]);
					} else {
						this.rooms.sort().reverse();
					}
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				if (opt === "platsnamn" || opt === "rum" || opt === "enhet") {
					this.rooms.sort((x, y) => (x[opt] < y[opt] ? -1 : 1));
				} else {
					Number(opt);
					this.rooms.sort((a, b) => a[opt] - b[opt]);
				}
			}
		},
		getHandleRooms: function () {
			if (this.inventRole) {
				this.isLoading = true;
				room_handle_api
					.getHandleRooms()
					.then((res) => {
						this.rooms = res.data;
						this.locations = res.data;
						this.isEditing = false;

						this.combinedRooms = [];
						this.isLoading = false;

						this.rooms.map((r) => {
							r.selected = false;
							Object.keys(r).find((key) => {
								if (r[key] === null) {
									r[key] = "";
								}
							});
						});
					})
					.catch(() => {
						router.push({ name: "Home" });
					});
			} else {
				router.push({ name: "Home" });
			}
		},

		combineRooms: function () {
			this.combineVerification = false;
			room_handle_api
				.combineRooms(this.combinedRooms, this.combineToRoom.plats)
				.then(() => {
					this.isEditing = false;
					this.combineSuccess = true;
				})
				.catch(() => {
					this.combineFail = true;
				});
		},
		closeVerification() {
			this.combineVerification = false;
			this.leaveVerification = false;
		},
	},
	mounted() {
		this.getHandleRooms();
	},
	watch: {
		clientId() {
			this.rooms = [];
			this.inventRole = this.$store.getters["inventRole"];
			this.getHandleRooms();
		},

		isEditing(bool) {
			this.$emit("isEditing", bool);
		},
	},
};
</script>

<style scoped></style>

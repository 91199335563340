<template>
	<GrModalSmall :cancel="cancel">
		<template v-slot:modal-header-title>
			<div class="row">
				<div class="col-sm-12">
					<h5 class="fw-bold fs-6">{{ $t("buttonTexts.resetPassword") }}</h5>
				</div>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="row">
				<div class="col-sm-12 d-flex align-items-center">
					<p class="fw-bolder small-text me-2">
						{{ $t("textFields.enterYourEmail") }}
					</p>
					<RequiredWarning
						v-if="showWarning"
						:warningText="$t('warningTexts.requiredField')"
					/>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-sm-12">
					<form id="resetform" @submit.prevent="resetPassword()">
						<input
							type="email"
							class="input-field w-100"
							v-model="email"
							required
						/>
					</form>
				</div>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="row">
				<div class="col-sm-12 d-flex justify-content-between">
					<button class="standard-btn me-3" @click="cancel = true">
						{{ $t("buttonTexts.cancel") }}
					</button>

					<button class="active-btn" type="submit" form="resetform">
						{{ $t("buttonTexts.send") }}
					</button>
				</div>
			</div>
		</template>
		<template v-slot:modal-slot>
			<ResponseModal
				v-if="resetSuccess"
				:response="1"
				:responseText="translatedText"
				@close="$emit('accept')"
			/>
			<ResponseModal
				v-if="resetError"
				:response="0"
				:responseText="$t('responseText.passwordResetError')"
				@close="resetError = false"
			/>
		</template>
	</GrModalSmall>
</template>

<script>
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import GrModalSmall from "@/modals/GrModalSmall.vue";
import ResponseModal from "@/modals/ResponseModal.vue";
import privilages_api from "@/services/api/privilages_api";
export default {
	emits: ["accept"],
	data() {
		return {
			cancel: false,
			email: "",
			resetSuccess: false,
			resetError: false,
			showWarning: false,
		};
	},
	computed: {
		translatedText() {
			return this.$t("responseText.passwordResetSuccess", [
				`<span style="font-weight: bolder">${this.email}</span>`,
			]);
		},
	},
	components: { GrModalSmall, ResponseModal, RequiredWarning },
	methods: {
		resetPassword() {
			if (this.email.length) {
				privilages_api
					.resetPassword(this.email)
					.then(() => {
						this.resetSuccess = true;
					})
					.catch(() => {
						this.resetError = true;
					});
			} else {
				this.showWarning = true;
			}
		},
	},
};
</script>

<template>
	<GrModalSmall :cancel="cancel">
		<template v-slot:modal-header-title>
			<div class="row">
				<div class="col">
					<h5 class="fw-bold fs-6">{{ $t("modalLabels.addLink") }}</h5>
				</div>
			</div>
		</template>
		<template v-slot:modal-content>
			<form action="" id="linkform" @submit.prevent="addLink()">
				<div class="row">
					<div class="col-sm-12 d-flex align-items-center">
						<p class="fw-bolder small-text me-2">
							{{ $t("modalLabels.title") }} *
						</p>
						<RequiredWarning
							v-if="showWarning && !linkFields.linkTitle.length"
							:warningText="$t('warningTexts.requiredField')"
						/>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-sm-12">
						<input
							class="input-field w-100"
							type="text"
							maxlength="60"
							v-model="linkFields.linkTitle"
							@input="isEditing = true"
							required
						/>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-sm-12 d-flex align-items-center">
						<p class="fw-bolder small-text me-2">
							{{ $t("modalLabels.link") }} *
						</p>
						<RequiredWarning
							v-if="showWarning && !linkFields.linkUrl.length"
							:warningText="$t('warningTexts.requiredField')"
						/>
					</div>
				</div>

				<div class="row mt-2">
					<div class="col-sm-12">
						<input
							class="input-field w-100"
							type="url"
							maxlength="60"
							v-model="linkFields.linkUrl"
							@input="isEditing = true"
							required
						/>
					</div>
				</div>
			</form>
		</template>
		<template v-slot:button-container>
			<div class="row">
				<div class="col-sm-12 d-flex justify-content-between">
					<button
						class="standard-btn me-2"
						@click="!isEditing ? (cancel = true) : (closeVerification = true)"
					>
						{{ $t("buttonTexts.cancel") }}
					</button>
					<button class="active-btn" type="submit" form="linkform">
						{{ $t("modalLabels.add") }}
					</button>
				</div>
			</div>
		</template>
		<template v-slot:modal-slot>
			<VerificationModal
				:header="$t('verificationTexts.headers.exit')"
				:verificationText="$t('verificationTexts.closeChanges')"
				v-if="closeVerification"
				@accept="cancel = true"
				@close="closeVerification = false"
			/>
		</template>
	</GrModalSmall>
</template>

<script>
import GrModalSmall from "@/modals/GrModalSmall.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
export default {
	emits: ["add"],
	components: {
		GrModalSmall,
		RequiredWarning,
		VerificationModal,
	},
	data() {
		return {
			closeVerification: false,
			cancel: false,
			linkFields: {
				linkTitle: "",
				linkUrl: "",
			},
			showWarning: false,
			isEditing: false,
			addLinkSuccess: false,
			addLinkError: false,
		};
	},
	methods: {
		isValidUrl() {
			var urlPattern = new RegExp(
				"^(https?:\\/\\/)?" +
					"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
					"((\\d{1,3}\\.){3}\\d{1,3}))" +
					"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
					"(\\?[;&a-z\\d%_.~+=-]*)?" +
					"(\\#[-a-z\\d_]*)?$",
				"i"
			);
			return urlPattern.test(this.linkFields.linkUrl);
		},
		addLink() {
			if (this.isValidUrl) {
				this.$emit("add", this.linkFields);
				this.linkFields = {
					linkTitle: "",
					linkUrl: "",
				};
				this.isEditing = false;
			}
		},
	},
};
</script>

<style></style>

<template>
	<div class="row mb-2">
		<div class="col">
			<p class="large-text">{{ locName }}</p>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col">
			<p class="small-text">
				{{ $t("landingPage.inventoryInformation") }}
			</p>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col-sm-9 d-flex">
			<button
				:class="`${option === 0 ? 'active-filter' : 'filter-btn'}`"
				@click="
					option === 0 ? $refs.invFilter.getInventoryLocations() : (option = 0)
				"
			>
				{{ $t("inventory.viewAndStoreProducts") }}
			</button>
			<button
				v-if="hasReconditions"
				:class="`${option === 1 ? 'active-filter' : 'filter-btn'}`"
				@click="option === 1 ? $refs.recFilter.getActiveRecs() : (option = 1)"
			>
				{{ $t("inventory.productsWithActions") }}
			</button>
			<div v-if="hasActiveReconditions" class="me-3">
				<div
					class="standard-btn d-flex align-items-center pe-4"
					v-if="loadingRec"
				>
					<LoaderDots :text="$t('buttonTexts.pleaseWait')" />
				</div>
				<button class="standard-btn no-wrap" @click="getRecReport()" v-else>
					{{ $t("buttonTexts.reconditionsReport") }}
				</button>
			</div>
			<div v-if="hasDeviations">
				<div
					class="standard-btn d-flex align-items-center pe-4"
					v-if="loadingDev"
				>
					<LoaderDots :text="$t('buttonTexts.pleaseWait')" />
				</div>
				<button class="standard-btn no-wrap" @click="getDevReport(1)" v-else>
					{{ $t("buttonTexts.deviationReport") }}
				</button>
			</div>
		</div>
		<div
			class="col-sm-3 d-flex justify-content-end align-items-center"
			v-if="option === 1"
		>
			<div class="sort-box">
				<p class="ms-3">{{ $t("placeHolders.sorting") }}</p>
				<GrSort
					:options="sortOptions"
					:optKey="'text'"
					@selectedOption="setSorting"
				/>
			</div>
		</div>
	</div>
	<InventoryRooms
		@hasRecs="(bool) => (hasReconditions = bool)"
		ref="invFilter"
		@hasDeviations="(bool) => (hasDeviations = bool)"
		@hasActiveReconditions="(bool) => (hasActiveReconditions = bool)"
		:searchValue="searchValue"
		v-if="option === 0"
		:invId="invId"
		:locName="locName"
	/>
	<ReconditionsActive
		ref="recFilter"
		:searchValue="searchValue"
		:sort="sort"
		v-if="option === 1"
		:id="invId"
	/>
</template>

<script>
import InventoryRooms from "./InventoryRooms.vue";
import router from "@/router";
import ReconditionsActive from "../reconditions_view/ReconditionsActive.vue";
import GrSort from "@/assets/component_assets/GrSort.vue";
import inventory_api from "@/services/api/inventory_api";
import reconditions_api from "@/services/api/reconditions_api";
import LoaderDots from "@/assets/component_assets/LoaderDots.vue";

export default {
	props: {
		invId: { type: String, default: null },
		locName: { type: String, default: null },
	},
	components: {
		InventoryRooms,
		ReconditionsActive,
		GrSort,
		LoaderDots,
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	data() {
		return {
			hasReconditions: false,
			hasActiveReconditions: false,
			option: 0,
			loadingDev: false,
			loadingRec: false,
			searchValue: "",
			hasDeviations: false,
			windowWidth: window.innerWidth,
			inventRole: this.$store.getters["inventRole"],
			activeRecs: true,
			historyRecs: false,
			invName: null,
			isLoading: false,
			sortOptions: [
				{
					id: 0,
					text: this.$t("inventory.showAll"),
				},
				{
					id: 1,
					text: this.$t("inventory.handled"),
				},
				{
					id: 2,
					text: this.$t("inventory.notHandled"),
				},
			],
			sort: 0,
		};
	},
	methods: {
		navReturn() {
			router.push({ name: "InventorySlot" });
		},
		setSorting(value) {
			this.sort = value.id;
		},
		getDevReport() {
			if (this.inventRole) {
				this.loadingDev = true;

				inventory_api.getReport(this.invId).then((res) => {
					let blob = new Blob([res.data], { type: "application/pdf" }),
						url = window.URL.createObjectURL(blob);

					window.open(url);
					this.loadingDev = false;
				});
			} else {
				router.push({ name: "Home" });
			}
		},
		getRecReport() {
			if (this.inventRole) {
				this.loadingRec = true;
				reconditions_api.getReport(this.invId).then((res) => {
					let blob = new Blob([res.data], { type: "application/pdf" }),
						url = window.URL.createObjectURL(blob);

					window.open(url);
					this.loadingRec = false;
				});
			} else {
				router.push({ name: "Home" });
			}
		},
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},

	watch: {
		clientId() {
			router.push({ name: "InventorySlot" });
		},
	},
};
</script>

<style scoped>
.sort-box {
	background-color: #f8f5ee;
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 14px;
	width: 14rem;
	justify-content: space-between;
}
.bc {
	cursor: pointer;
}
</style>

<template>
	<div class="main-container ps-0 pe-0">
		<LoaderSpinner bgColor="#f8f5ee" v-if="isLoading" />

		<div class="container-fluid">
			<div class="row fw-bold d-flex layout-padding">
				<div class="col-sm-3 d-flex pe-0">
					<div class="space"></div>
					<div class="space"></div>
					<div class="table-header-b ms-1 w-100">
						{{ $t("articleInfoLabels.purchaseDate") }}
					</div>
				</div>

				<div class="col-sm-2 table-header-b">
					{{ $t("articleInfoLabels.orderNumber") }}
				</div>

				<div class="col-sm-2 table-header-b">
					<p>{{ $t("articleInfoLabels.rowNumber") }}</p>
				</div>

				<div
					class="col-sm-2 table-header-b"
					v-if="$store.state.client.intern === 'J'"
				>
					<p>{{ $t("articleInfoLabels.price") }}</p>
				</div>
				<div class="col-sm-2 table-header-b" v-else></div>

				<div class="col-sm-2 pe-0 d-flex align-items-center table-header-b">
					<p class="no-wrap me-2">
						{{ $t("articleInfoLabels.latestInventory") }}
					</p>
					<Popper :hover="true" v-if="latestInv !== null">
						<template #content>
							<div class="popper-content">
								<p>{{ $t("articleInfoLabels.showHistory") }}</p>
							</div>
						</template>

						<img
							src="@/assets/img/history.svg"
							alt="history icon"
							class="history-icon"
							@click="showInvHistory = true"
						/>
					</Popper>
				</div>
			</div>
		</div>

		<div class="container-fluid mt-2">
			<div class="row layout-padding">
				<div class="col-sm-3 d-flex pe-0">
					<div class="space"></div>
					<div class="space"></div>
					<div class="ms-1 w-100">
						<p v-if="chosenId.ansk_datum !== null">{{ chosenId.ansk_datum }}</p>
						<p v-else>{{ $t("textFields.noInformation") }}</p>
					</div>
				</div>
				<div class="col-sm-2 d-flex align-items-center">
					<p v-if="chosenId.ordernr !== null">
						{{ chosenId.ordernr }}
					</p>
					<p v-else>{{ $t("textFields.noInformation") }}</p>
				</div>

				<div class="col-sm-2 d-flex align-items-center">
					<p v-if="chosenId.radnr !== null">
						{{ chosenId.radnr }}
					</p>
					<p v-else>{{ $t("textFields.noInformation") }}</p>
				</div>

				<div
					class="col-sm-2 d-flex align-items-center"
					v-if="$store.state.client.intern === 'J'"
				>
					<p v-if="chosenId.inkpris !== null">
						{{ chosenId.inkpris }}
						{{ $store.state.client.sprak === "no" ? "nok" : "sek" }}
					</p>
					<p v-else>{{ $t("textFields.noInformation") }}</p>
				</div>
				<div v-else class="col-sm-2 d-flex align-items-center"></div>

				<div class="col-sm-2 d-flex">
					<div class="d-flex align-items-center">
						<p v-if="latestInv !== null">
							{{ latestInv }}
						</p>
						<p v-else>{{ $t("inventory.states.notInventoried") }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row mt-4 layout-padding">
				<div class="col d-flex pe-0">
					<div class="space"></div>
					<div class="space"></div>
					<div
						class="selector-container d-flex align-items-center ms-1"
						v-if="chosenId.vart_reknr === null"
					>
						<div class="d-flex" v-if="moveHistory.length || inventRole">
							<p class="fw-bold">
								{{ $t("articleInfoLabels.changeRoom") }}
							</p>
							<RequiredWarning
								v-if="showWarning"
								:warningText="$t('warningTexts.chooseARoom')"
							/>
						</div>

						<Popper :hover="true" v-if="moveHistory.length">
							<template #content>
								<div class="popper-content">
									<p>{{ $t("articleInfoLabels.showHistory") }}</p>
								</div>
							</template>

							<img
								src="@/assets/img/history.svg"
								alt="history icon"
								class="history-icon ms-4"
								@click="showMoveHistory = true"
							/>
						</Popper>
					</div>
					<p class="fw-bolder ms-1 no-wrap" v-else>
						{{ $t("buttonTexts.productIsClaimed") }}
					</p>
				</div>
			</div>
		</div>
		<div class="container-fluid mt-2">
			<div class="row align-items-center layout-padding">
				<div
					class="col d-flex pe-0"
					v-if="inventRole && chosenId.vart_reknr === null"
				>
					<div class="space"></div>
					<div class="space"></div>
					<div class="content-s">
						<Multiselect
							class="selector-c multiselect-left"
							:options="locations"
							v-model="newLocation"
							:show-labels="false"
							@select="verifyMove = true"
							label="platsnamn"
							:placeholder="$t('handleRooms.chooseRoom')"
							><template v-slot:caret>
								<div class="multiselect__select">
									<img
										src="../../assets/img/down-icon.svg"
										alt="dropdown icon"
									/></div></template
						></Multiselect>
					</div>
				</div>

				<div class="col d-flex pe-0" v-else>
					<div class="space"></div>
					<div class="space"></div>
					<div class="ms-1">
						<p v-if="chosenId.reklamation !== null">
							{{ $t("articleInfoLabels.clientErrNumber") }}:
							{{ chosenId.reklamation }}
						</p>

						<p v-if="chosenId.vart_reknr !== null">
							{{ $t("articleInfoLabels.inputsErrNumber") }}:
							{{ chosenId.vart_reknr }}
						</p>
					</div>
				</div>

				<div class="col-sm-2"></div>

				<div class="col-sm-2 d-flex" v-if="chosenId.vart_reknr === null">
					<button
						v-if="changeRole"
						class="standard-btn medium-btn no-wrap"
						@click="chosenId.vart_reknr === null ? (verifyDiscard = true) : ''"
					>
						{{ $t("buttonTexts.deleteProduct") }}
					</button>
				</div>
				<div
					class="col-sm-3 d-flex justify-content-start"
					v-if="chosenId.vart_reknr === null"
				>
					<button
						v-if="chosenId.vart_reknr === null && claimRole"
						class="standard-btn d-flex align-items-center no-wrap"
						@click="
							!isEditing ? (showClaim = !showClaim) : (verifyClose = true)
						"
					>
						{{ $t("modalLabels.claimProduct") }}
					</button>
				</div>
			</div>
			<div class="row layout-padding" v-if="showClaim && claimRole">
				<div class="col d-flex">
					<div class="space"></div>
					<div class="space"></div>
					<ClaimProduct
						@refresh="
							showClaim = false;
							getUniqueId();
						"
						:chosenId="chosenId"
						@isEditing="isEditing = true"
					/>
				</div>
			</div>
		</div>
	</div>

	<VerificationModal
		:header="$t('inventory.move')"
		v-if="verifyMove"
		:verificationText="
			$t('verificationTexts.moveText', [
				chosenId.benamn1,
				chosenId.platsnamn,
				newLocation.platsnamn,
			])
		"
		@close="
			verifyMove = false;
			newLocation = null;
		"
		@accept="postLocation()"
	/>

	<VerificationModal
		v-if="verifyDiscard"
		:header="$t('articleInfoLabels.remove')"
		:verificationText="translatedText"
		@close="verifyDiscard = false"
		@accept="discardArticle()"
	/>

	<VerificationModal
		v-if="verifyClose"
		:header="$t('verificationTexts.headers.close')"
		:verificationText="$t('verificationTexts.closeChanges')"
		@close="verifyClose = false"
		@accept="
			showClaim = false;
			verifyClose = false;
			isEditing = false;
		"
	/>

	<ResponseModal
		v-if="discardSuccess"
		:response="1"
		:responseText="$t('responseText.discardSuccess')"
		@close="
			discardSuccess = false;
			$emit('refreshUnique');
		"
	/>

	<ResponseModal
		v-if="discardError"
		:response="0"
		:responseText="$t('responseText.discardError')"
		@close="discardError = false"
	/>
	<ResponseModal
		v-if="moveError"
		:response="0"
		:responseText="
			$t('responseText.moveError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="moveError = false"
	/>
	<ResponseModal
		v-if="moveSuccess"
		:response="1"
		:responseText="$t('responseText.moveSuccess')"
		@close="
			moveSuccess = false;
			getUniqueId();
			$emit('refreshUnique');
		"
	/>
	<InventoryHistory
		v-if="showInvHistory"
		:invHistory="invHistory"
		@close="showInvHistory = false"
	/>
	<MoveHistory
		v-if="showMoveHistory"
		:moveHistory="moveHistory"
		@close="showMoveHistory = false"
	/>
</template>

<style scoped>
.fixed-margin {
	margin-left: -1.2rem;
}
.history-icon {
	width: 1.6rem;
	cursor: pointer;
}

p {
	font-size: 14px;
}
.selector-container {
	width: 16rem;
}

.history-link {
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}

.history-link:hover {
	text-decoration: underline;
	transition: 0.2s;
}

.is-claimed-text {
	cursor: default;
	color: red;
	font-weight: bolder;
}
.is-claimed-text:hover {
	text-decoration: underline;
}

.product-info-wrapper {
	padding-top: 1.4rem;
	padding-bottom: 2.4rem;
}
</style>
<script>
import Multiselect from "vue-multiselect";
import Popper from "vue3-popper";
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import unique_api from "@/services/api/unique_api";
import room_handle_api from "@/services/api/room_handle_api";
import InventoryHistory from "./product_components/InventoryHistory.vue";
import MoveHistory from "./product_components/MoveHistory.vue";
import ClaimProduct from "./product_components/ClaimProduct.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";

export default {
	props: {
		chosenId1: Object,
		hideColumns: { type: Boolean, default: false },
	},
	components: {
		VerificationModal,
		ResponseModal,
		ClaimProduct,
		InventoryHistory,
		MoveHistory,
		RequiredWarning,
		Popper,
		Multiselect,
		LoaderSpinner,
	},
	data() {
		return {
			verifyDiscard: false,
			verifyMove: false,
			moveSuccess: false,
			moveError: false,
			isLoading: false,
			showOrder: false,
			hisOption: null,
			showInvHistory: false,
			showMoveHistory: false,
			showMove: false,
			showMultiMove: false,
			newLocation: null,
			locations: [],
			showClaim: false,
			invHistory: [],
			latestInv: null,
			inventRole: this.$store.getters["inventRole"],
			changeRole: this.$store.getters["changeRole"],
			claimRole: this.$store.getters["claimRole"],
			placement: "top",
			moveHistory: [],
			discardSuccess: false,
			discardError: false,
			showWarning: false,
			isEditing: false,
			chosenId: [],
			verifyClose: false,
		};
	},
	computed: {
		translatedText() {
			return this.$t("verificationTexts.discardArticle", [
				`<span style="font-weight: bolder">${this.chosenId.benamn1}</span>`,
				this.chosenId.id,
			]);
		},
	},
	emits: ["refreshUnique"],
	methods: {
		getUniqueId() {
			this.isLoading = true;
			unique_api.getUniqueId(this.chosenId1.id).then((res) => {
				this.chosenId = res.data.data;

				this.getInventoryHistory();
				this.getHandleRooms();
				this.getMoveHistory();
				this.isLoading = false;
			});
		},

		discardArticle() {
			unique_api
				.discardArticle(this.chosenId.id)
				.then(() => {
					this.discardSuccess = true;
				})
				.catch(() => {
					this.discardError = true;
				});
		},

		postLocation: function () {
			this.verifyMove = false;
			unique_api
				.postLocation(this.newLocation.plats, this.chosenId.id)
				.then(() => {
					this.moveSuccess = true;
				})
				.catch(() => {
					this.moveError = true;
				});
		},

		getHandleRooms: function () {
			this.locations = [];
			room_handle_api.getHandleRooms().then((res) => {
				res.data.map((loc) => {
					if (loc.plats !== Number(this.chosenId.plats)) {
						this.locations.push(loc);
					}
				});
			});
		},
		getInventoryHistory: function () {
			unique_api.getInventoryHistory(this.chosenId.id).then((res) => {
				this.invHistory = res.data;
				if (this.invHistory.length) {
					this.latestInv = this.invHistory[0].inv_datum_short;
				}
			});
		},

		getMoveHistory: function () {
			unique_api.getMoveHistory(this.chosenId.id).then((res) => {
				this.moveHistory = res.data;
			});
		},
	},
	watch: {},
	mounted() {
		this.getUniqueId();
	},
};
</script>

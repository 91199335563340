<template>
	<GrVerification :close="close">
		<template v-slot:modal-header-title>
			<div class="col-sm-12 d-flex flex-column align-items-center">
				<img
					v-if="response === 0"
					src="@/assets/img/warning-logo.svg"
					alt="warning logo"
					class="res-image"
				/>
				<img
					v-else
					src="@/assets/img/success-icon.svg"
					alt="success icon"
					class="res-image"
				/>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="col-sm-12 d-flex justify-content-center">
				<p class="v-text">
					<span v-html="responseText"></span>
				</p>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="col-sm-12 d-flex justify-content-center">
				<button @click="close = true" class="active-btn">
					{{ $t("modalLabels.close") }}
				</button>
			</div>
		</template>
	</GrVerification>
</template>

<script>
import GrVerification from "./GrVerification.vue";

export default {
	data() {
		return {
			close: false,
		};
	},
	props: {
		responseText: String,
		response: Number,
	},
	computed: {},
	methods: {},
	components: { GrVerification },
};
</script>

<style scoped>
.res-image {
	width: 2.6rem;
}

.v-text {
	text-align: left;
}
</style>

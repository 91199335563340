<template>
	<GrVerification :close="close">
		<template v-slot:modal-header-title>
			<div class="col-sm-12">
				<p class="fw-bolder">{{ header }}</p>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="col-sm-12 d-flex">
				<p class="v-text">
					{{ $t("verificationTexts.verifyText") }}
					<span v-html="verificationText"></span>
				</p>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="col-sm-12 d-flex justify-content-between">
				<button @click="close = true" class="standard-btn me-4">
					{{ $t("buttonTexts.undo") }}
				</button>
				<button @click="$emit('accept')" class="active-btn">
					{{ $t("buttonTexts.yes") }}
				</button>
			</div>
		</template>
	</GrVerification>
</template>

<script>
import GrVerification from "./GrVerification.vue";

export default {
	props: {
		verificationText: String,
		header: String,
	},
	emits: ["accept"],
	data() {
		return {
			close: false,
		};
	},
	computed: {},
	methods: {},
	components: { GrVerification },
};
</script>

<style scoped>
.standard-btn,
.active-btn {
	width: 11rem;
}
.v-text {
	text-align: left;
}
</style>

<template>
	<div class="main-container" :style="{ height: isLoading ? '50vh' : '' }">
		<LoaderSpinner v-if="isLoading" bgColor="white" />
		<div
			class="container-fluid ps-0 pe-0"
			:class="isActive ? '' : 'sticky-header'"
			v-else
		>
			<div class="row layout-padding m-0">
				<div class="col-sm-2 ps-0 table-header">
					<div class="d-flex sorting align-items-center">
						<p>{{ $t("tableHeaders.idNumber") }}</p>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'benamn1' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('benamn1')"
					>
						<p class="me-2">{{ $t("tableHeaders.productName") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'benamn1' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div class="col-sm-2 table-header">
					<div class="d-flex sorting align-items-center">
						<p>{{ $t("tableHeaders.artNumber") }}</p>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'levnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('levnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.brand") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'levnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>

				<div
					class="col-sm-2 table-header"
					:class="sortOption.title === 'platsnamn' ? 'active-th' : ''"
				>
					<div
						class="d-flex sorting align-items-center"
						@click="setSorting('platsnamn')"
					>
						<p class="me-2">{{ $t("tableHeaders.location") }}</p>
						<img
							src="@/assets/img/drop-arrow.svg"
							alt="dropdown arrow"
							:class="
								sortOption.title === 'platsnamn' && sortOption.active
									? 'active-sort'
									: 'inactive-sort'
							"
						/>
					</div>
				</div>
				<div class="col table-header"></div>
			</div>
		</div>
		<lazy-component
			class="container-fluid ps-0 pe-0"
			v-for="article in filteredArticles"
			:key="article"
			:class="article.active ? 'gr-color main-container' : ''"
		>
			<div
				class="row align-items-center layout-padding m-0"
				@click="showUnique(article)"
			>
				<div
					class="col-sm-2 table-item ps-0"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.id }}
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					<div class="product-image-container">
						<img
							v-if="article.img_url"
							:src="article.img_url"
							alt="product-image"
							class="product-image"
						/>
						<img
							v-else
							src="@/assets/img/no-image.svg"
							alt="product-image"
							class="product-image err-image"
						/>
					</div>

					<p class="ellipsis fw-bolder ms-2">{{ article.benamn1 }}</p>
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.artikelnr }}
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					{{ article.levnamn }}
				</div>
				<div
					class="col-sm-2 table-item"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					<p class="ellipsis">{{ article.platsnamn }}</p>
				</div>
				<div
					class="col-sm-2 table-item d-flex justify-content-end"
					:style="{ borderBottom: article.active ? '0' : '' }"
				>
					<button
						class="standard-btn"
						:class="
							inactiveButtons && !article.active
								? 'active-btn'
								: article.active
								? 'bg-white'
								: 'confirm-btn'
						"
					>
						{{
							article.active
								? $t("buttonTexts.closeProductId")
								: $t("buttonTexts.showProductId")
						}}
					</button>
				</div>
			</div>

			<div @click.stop v-if="article.active" class="row me-0 ms-0 mt-2 mb-5">
				<ProductInfoV2
					:hideColumns="true"
					:chosenId1="chosenId1"
					@refreshUnique="getUniqueArticles()"
				/>
			</div>
			<div
				class="row bottom-bar layout-padding m-0"
				@click.stop
				v-if="article.active"
			></div>
		</lazy-component>
		<div class="container-fluid ps-0 pe-0">
			<div class="row layout-padding m-0">
				<div class="col bottom-border"></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.selected-item-l {
	top: 0;
}
.standard-btn {
	min-width: 8.8rem;
}
</style>

<script>
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";
import unique_api from "@/services/api/unique_api.js";
import ProductInfoV2 from "@/components/main_view_components/ProductInfoV2.vue";
import router from "@/router";

export default {
	components: {
		LoaderSpinner,
		ProductInfoV2,
	},

	props: { searchValue: String },

	computed: {
		isActive() {
			let active = false;
			this.articles.map((art) => {
				if (art.active) {
					active = true;
				} else {
					active = false;
				}
			});
			return active;
		},
		clientId() {
			return this.$store.state.client.klient;
		},
		lang() {
			return this.$store.state.language;
		},
		filteredArticles() {
			if (this.searchValue.length === 0) {
				return this.articles;
			} else {
				let searchText = this.searchValue.toLowerCase();
				return this.articles.filter((a) => {
					return (
						a.id.toString().toLowerCase().includes(searchText) ||
						a.platsnamn.toLowerCase().includes(searchText) ||
						a.levnamn.toLowerCase().includes(searchText) ||
						a.artikelnr.toLowerCase().includes(searchText) ||
						a.benamn1.toLowerCase().includes(searchText)
					);
				});
			}
		},
	},
	data() {
		return {
			isLoading: false,
			articles: [],
			articleData: [],
			displayModal: false,
			artikelnr: null,
			plats: null,
			sortOption: {
				title: "",
				active: false,
			},
			chosenId1: null,
			inactiveButtons: false,
		};
	},

	methods: {
		showUnique(id) {
			id.active = !id.active;

			this.chosenId1 = id;

			Object.keys(this.chosenId1).find((key) => {
				if (this.chosenId1[key] === "") {
					this.chosenId1[key] = null;
				}
			});
			this.articles.map((x) => {
				if (x.id !== id.id) {
					x.active = false;
				}
			});

			if (!id.active) {
				this.inactiveButtons = false;
			} else {
				this.inactiveButtons = true;
			}
		},
		getUniqueArticles: function () {
			this.articles = [];
			this.inactiveButtons = false;
			this.isLoading = true;
			unique_api
				.getAllUniques()
				.then((res) => {
					this.articles = res.data;
					this.articles.map((a) => {
						Object.keys(a).find((key) => {
							if (a[key] === null) {
								a[key] = "";
							}
						});
					});
					this.isLoading = false;
				})
				.catch(() => {
					router.push({ name: "Home" });
				});
		},

		setSorting(opt) {
			let sorting = opt.toLowerCase();
			if (this.sortOption.title === opt) {
				this.sortOption.active = !this.sortOption.active;
				if (this.sortOption.active) {
					this.articles.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
				} else {
					this.articles.sort().reverse();
				}
			} else {
				this.sortOption.title = opt;
				this.sortOption.active = true;
				this.articles.sort((x, y) => (x[sorting] < y[sorting] ? -1 : 1));
			}
		},
	},

	watch: {
		clientId() {
			this.articles = [];
			this.getUniqueArticles();
		},
	},
	mounted() {
		this.getUniqueArticles();
	},
};
</script>

<template>
	<LayoutContent
		:key="$store.getters.client.login + $store.getters.client.klient"
	>
		<template v-slot:container>
			<router-view
				:key="
					$route.fullPath +
					$store.getters.client.login +
					$store.getters.client.klient
				"
			/>
			<CookieInformation v-if="cookiesModal" @close="verifyCookies" />
		</template>
	</LayoutContent>
</template>

<script>
import CookieInformation from "@/modals/CookieInformation.vue";
import { mapActions, mapGetters } from "vuex";
import LayoutContent from "./layout/navbar/LayoutContent.vue";

export default {
	name: "App",
	components: { CookieInformation, LayoutContent },
	data() {
		return {
			cookiesModal: false,
		};
	},
	methods: {
		verifyCookies() {
			this.changeCookies(true);
			this.cookiesModal = false;
		},
		...mapActions({
			changeCookies: "changeCookies",
		}),
		...mapGetters({
			getCookies: "cookies",
		}),
	},
	mounted() {
		if (!this.getCookies()) {
			this.cookiesModal = true;
		}
	},
};
</script>

<style>
#app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
</style>

import api from "@/services/api";
import api_no_auth from "../api_no_auth";

export default {
	getUserPrivileges() {
		return api().get("/privileges");
	},

	verifyToken(klient, token, userId) {
		return api_no_auth().get(`/password/${klient}/${token}/${userId}/verify`);
	},
	getNewReset(klient, token, userId) {
		return api_no_auth().get(`/password/${klient}/${token}/${userId}/reset`);
	},

	postNewPassword(klient, token, userId, password) {
		return api_no_auth().post(
			`/password/${klient}/${token}/${userId}`,
			JSON.stringify({
				password: password,
			})
		);
	},

	resetPassword(email) {
		return api_no_auth().post(
			`/reset`,
			JSON.stringify({
				email: email,
			})
		);
	},
};

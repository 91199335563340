<template>
	<GrModalLarge
		:showBottomBorder="true"
		@validate="validateClose"
		ref="largeModal"
		@closeModal="$emit('closeModal')"
		@click="activeScanning = true"
	>
		<template v-slot:modal-header-title>
			<div class="col-sm-12 d-flex align-items-center">
				<h5 class="fw-bold mb-2 fs-5">
					{{ $t("navbarTitle.inventory") }} - {{ $t("inventory.scanQrCode") }}
				</h5>

				<p class="fw-bold ms-5">
					{{ $t("inventory.scanner") }}:
					<span v-if="activeScanning" style="color: green">{{
						$t("inventory.active")
					}}</span>
					<span v-else style="color: red">{{ $t("inventory.inactive") }}</span>
				</p>
			</div>
		</template>
		<template v-slot:modal-subheader>
			<div class="col-sm-8 d-flex align-items-center mt-3">
				<div class="d-flex align-items-center h-100">
					<p class="fw-bold me-3">{{ $t("inventory.chosenRoom") }}</p>
					<p>{{ selectedRoom.benamning }}</p>
					<p class="fw-bold ms-5 me-3">{{ $t("inventory.inventoryAmount") }}</p>
					<p>
						{{ selectedRoom.inventerade }}/{{ selectedRoom.antal_artiklar }}
					</p>
				</div>
			</div>
			<div class="col-sm-4 d-flex align-items-center justify-content-end mt-3">
				<RequiredWarning
					class="me-2"
					:warningText="$t('warningTexts.10digitsRequired')"
					v-if="errorMsg"
				/>
				<form @submit="submitInput" class="manual-form">
					<input
						type="text"
						class="manual-input"
						:placeholder="$t('inventory.manualInput')"
						@click.stop="activeScanning = false"
						@input="
							() => {
								errorMsg = false;
								isEditing = true;
							}
						"
						v-model="manualInput"
						ref="input"
						@blur="!manualInput.length ? (activeScanning = true) : ''"
					/>
					<input type="submit" hidden />
				</form>
			</div>
		</template>
		<template v-slot:modal-bottom-border>
			<div class="container-fluid">
				<div
					class="row table-header-b bottom-inv-bar no-border h-100 align-items-center"
				>
					<div class="col-sm-2 ps-0">
						{{ $t("tableHeaders.productName") }}
					</div>
					<div class="col-sm-2">{{ $t("tableHeaders.artNumber") }}</div>
					<div class="col-sm-2">{{ $t("articleInfoLabels.id") }}</div>
					<div class="col-sm-3">{{ $t("tableHeaders.locationName") }}</div>
					<div class="col-sm-3">{{ $t("tableHeaders.state") }}</div>
				</div>
			</div>
		</template>
		<template v-slot:modal-content>
			<div class="table-wrapper pb-4 main-container bottom-inv-bar">
				<LoaderSpinner v-if="isLoading" />
				<div
					class="container-fluid"
					v-for="article in articleStore.slice().reverse()"
					:key="article"
				>
					<template
						v-if="
							article.status !== 'F' ||
							(!article.inventoryState &&
								article.plats !== selectedRoom.plats &&
								article.status !== 'F')
						"
					>
						<div class="row align-items-center inv-table-item comment-item">
							<div class="col-sm-2 d-flex align-items-center ps-0">
								<input
									v-if="article.status === 'E'"
									type="checkbox"
									v-model="article.inventoryState"
								/>

								<img
									v-else-if="article.status === 'I'"
									src="@/assets/img/check-green.svg"
									alt="green checkbox"
								/>
								<p class="ms-2 w-75 ellipsis">{{ article.benamn }}</p>
							</div>
							<div class="col-sm-2 d-flex align-items-center">
								<p>{{ article.artikelnr }}</p>
							</div>
							<div class="col-sm-2 d-flex align-items-center">
								<p>{{ article.id }}</p>
							</div>

							<div class="col-sm-3 d-flex align-items-center">
								<p>{{ article.platsnamn }}</p>
							</div>
							<div class="col-sm-3 d-flex align-items-center">
								<p>{{ convertStatus(article) }}</p>
							</div>
							<div
								class="col-sm-4 ps-0 mt-3"
								v-if="article.status !== 'I' && article.status !== 'D'"
							>
								<input
									type="text"
									class="input-field w-100"
									v-model="article.rec"
									:placeholder="$t('placeHolders.addCommentAboutProduct')"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="row align-items-center h-100 inv-table-item">
							<div class="col-sm-2 d-flex align-items-center"></div>
							<div class="col-sm-2 d-flex align-items-center"></div>
							<div class="col-sm-2 d-flex align-items-center">
								<p>{{ article.id }}</p>
							</div>

							<div class="col-sm-3 d-flex align-items-center"></div>
							<div
								class="col-sm-3 d-flex align-items-center"
								v-if="article.status === 'F'"
							>
								<p>{{ convertStatus(article) }}</p>
							</div>

							<div class="col-sm-3 d-flex align-items-center" v-else>
								<p>{{ article.text }}</p>
							</div>
						</div>
					</template>
				</div>
			</div>
		</template>
		<template v-slot:button-container>
			<div class="col-sm-6 d-flex justify-content-end pt-4">
				<button
					class="standard-btn"
					@click="articleStore.length ? (showReset = true) : noChanges()"
				>
					{{ $t("buttonTexts.clear") }}
				</button>

				<button class="active-btn ms-4" @click="checkInventory">
					{{
						showNoChanges ? $t("buttonTexts.noChanges") : $t("buttonTexts.save")
					}}
				</button>
			</div>
		</template>
	</GrModalLarge>
	<VerificationModal
		v-if="showStore"
		:header="$t('navbarTitle.inventory')"
		:verificationText="
			$t('verificationTexts.storeArticles', [this.validArticles.length])
		"
		@close="showStore = false"
		@accept="postScannedArticles()"
	/>
	<VerificationModal
		:header="$t('navbarTitle.inventory')"
		v-if="showMove"
		:verificationText="$t('verificationTexts.storeAndMove')"
		@close="showMove = false"
		@accept="postScannedArticles()"
	/>
	<VerificationModal
		:header="$t('verificationTexts.headers.exit')"
		v-if="showUnsaved"
		:verificationText="$t('verificationTexts.closeChanges')"
		@close="showUnsaved = false"
		@accept="$refs.largeModal.closeModal()"
	/>
	<VerificationModal
		:header="$t('buttonTexts.reset')"
		v-if="showReset"
		:verificationText="$t('verificationTexts.resetChanges')"
		@close="showReset = false"
		@accept="
			articleStore = [];
			validArticles = [];
			isReset = true;
			showReset = false;
		"
	/>
	<ResponseModal
		v-if="showSuccess"
		:response="1"
		:responseText="$t('responseText.inventorySuccess')"
		@close="
			showSuccess = false;
			$emit('updateData');
		"
	/>
	<ResponseModal
		v-if="showMoveSuccess"
		:response="1"
		:responseText="$t('responseText.inventoryAndMoveSuccess')"
		@close="
			showMoveSuccess = false;
			$emit('updateData');
		"
	/>
	<ResponseModal
		v-if="showError"
		:response="0"
		:responseText="
			$t('responseText.inventoryError') +
			' ' +
			$t('responseText.contactSystemAdministrator')
		"
		@close="showError = false"
	/>
</template>

<style scoped>
.standard-btn,
.active-btn {
	min-width: 10rem;
}
.bottom-inv-bar {
	padding-left: 2rem;
	padding-right: 2rem;
}
.table-wrapper {
	height: 100%;
	max-height: 100%;
	overflow: auto;
}

.manual-input {
	border: 1.5px solid black;
	font-size: 14px;
	width: 14rem;
	padding: 6px;
}

.manual-input::placeholder {
	color: black !important;
}

.inv-table-item {
	font-size: 14px;
	min-height: 4rem;

	border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.comment-item {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
</style>

<script>
import GrModalLarge from "@/modals/GrModalLarge.vue";
import inventory_api from "@/services/api/inventory_api";
import ResponseModal from "@/modals/ResponseModal.vue";
import VerificationModal from "@/modals/VerificationModal.vue";
import RequiredWarning from "@/assets/component_assets/RequiredWarning.vue";
import LoaderSpinner from "@/assets/component_assets/LoaderSpinner.vue";

export default {
	data() {
		return {
			isReset: false,
			isLoading: false,
			activeScanning: true,
			isEditing: false,
			articleStore: [],
			manualInput: "",
			showReset: false,
			showStore: false,
			showUnsaved: false,
			showMove: false,
			scannedID: [],
			errorMsg: false,
			controlledId: [],
			validArticles: [],
			showSuccess: false,
			showMoveSuccess: false,
			showError: false,
			showNoChanges: false,
			reconditions: [
				{
					name: this.$t("list.reconditions.W.name"),
					atgard: "W",
				},
				{
					name: this.$t("list.reconditions.T.name"),
					atgard: "T",
				},
				{
					name: this.$t("list.reconditions.V.name"),
					atgard: "V",
				},
				{
					name: this.$t("list.reconditions.R.name"),
					atgard: "R",
				},
				{
					name: this.$t("list.reconditions.A.name"),
					atgard: "A",
				},
			],
		};
	},
	emits: [
		"closeModal",
		"update:modelValue",
		"noUpdate",
		"updateValues",
		"updateData",
	],
	props: {
		selectedRoom: { type: Object, default: null },
		modelValue: { type: String, default: null },
		invType: { type: String, default: null },
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		validateClose() {
			if (!this.isEditing) {
				this.$refs.largeModal.closeModal();
			} else {
				this.showUnsaved = true;
			}
		},
		noChanges() {
			this.showNoChanges = true;
			window.setTimeout(() => {
				this.showNoChanges = false;
			}, 2000);
		},
		postScannedArticles() {
			this.showStore = false;
			this.showMove = false;
			this.isEditing = false;
			inventory_api
				.postScannedArticles(
					this.selectedRoom.plats,
					this.selectedRoom.invid,
					this.validArticles
				)
				.then(() => {
					if (this.showMove) {
						this.showMoveSuccess = true;
					} else {
						this.showSuccess = true;
					}

					for (let i = 0; i < this.validArticles.length; i++) {
						this.validArticles[i].status = "I";
					}

					this.validArticles = [];
				})
				.catch(() => {
					this.showError = true;
				});

			this.$emit("updateValues");
		},

		checkInventory() {
			if (this.articleStore.length) {
				for (let i = 0; i < this.articleStore.length; i++) {
					if (
						this.articleStore[i].status === "E" &&
						this.articleStore[i].inventoryState === true
					) {
						this.validArticles.push(this.articleStore[i]);
					}
				}

				this.validArticles.map((art) => {
					if (art.plats !== this.selectedRoom.plats) {
						art.moved = true;
						this.showMove = true;
						this.isEditing = true;
					}
				});

				if (!this.showMove && this.validArticles.length) {
					this.showStore = true;
					this.isEditing = true;
				}
			} else {
				this.noChanges();
			}
		},

		convertStatus(article) {
			if (article.status === "E") {
				return article.plats === this.selectedRoom.plats
					? this.$t("inventory.states.notInventoried")
					: this.$t("inventory.states.notInventoried") +
							", " +
							this.$t("inventory.states.differentRoom");
			} else if (article.status === "I") {
				return article.plats === this.selectedRoom.plats || article.moved
					? this.$t("inventory.states.inventoried")
					: this.$t("inventory.states.inventoried") +
							", " +
							this.$t("inventory.states.differentRoom");
			} else if (article.status === "J") {
				return article.plats === this.selectedRoom.plats
					? this.$t("inventory.states.updateSuccess")
					: this.$t("inventory.states.updateSuccess") +
							", " +
							this.$t("inventory.states.differentRoom");
			} else if (article.status === "R") {
				return article.plats === this.selectedRoom.plats
					? this.$t("inventory.states.moveSuccess")
					: this.$t("inventory.states.moveSuccess") +
							", " +
							this.$t("inventory.states.differentRoom");
			} else if (article.status === "D") {
				return this.$t("inventory.states.duplicate");
			} else {
				return this.$t("inventory.states.error");
			}
		},

		inventoryControl(id) {
			if (id.length > 2) {
				this.isLoading = true;
				inventory_api
					.inventoryControl(
						id,
						this.selectedRoom.plats,
						this.selectedRoom.invid
					)
					.then((res) => {
						let idExists = false;
						this.isLoading = false;
						this.controlledId = res.data;
						this.articleStore.map((art) => {
							if (this.controlledId.id === art.id) {
								this.controlledId.status = "D";
								this.controlledId.farg = "duplicate";
							}

							if (art.id === id) {
								idExists = true;
							}
						});

						if (
							this.controlledId.status === "E" &&
							this.controlledId.plats == this.selectedRoom.plats &&
							this.controlledId.farg !== "warning"
						) {
							this.controlledId["inventoryState"] = true;
						} else {
							this.controlledId["inventoryState"] = false;
						}
						if (idExists === false) {
							this.controlledId.rec = null;
							this.articleStore.push(this.controlledId);
						}

						this.articleStore.map((art) => {
							if (art.id === null) {
								art.id = id;
							}
						});

						this.scannedID = "";
						this.$emit("update:modelValue");
					});
			} else {
				this.scannedID = "";
			}
		},

		submitInput(e) {
			e.preventDefault();
			if (this.manualInput.length === 10) {
				this.inventoryControl(this.manualInput);
				this.manualInput = "";
				this.$refs.input.blur();
				this.activeScanning = true;
			} else {
				this.errorMsg = true;
				this.activeScanning = false;
			}
		},
	},
	mounted() {
		this.isLoading = false;
		window.addEventListener("keypress", (e) => {
			if (this.activeScanning) {
				this.scannedID += String.fromCharCode(e.keyCode);
				if (e.key === "Enter") {
					this.inventoryControl(this.scannedID);
				}
			}
		});
		if (this.modelValue.length > 2) {
			this.inventoryControl(this.modelValue);
			this.activeScanning = true;
		}

		window.addEventListener("blur", () => {
			this.activeScanning = false;
		});
	},
	watch: {
		setInv(val) {
			if (val === true) {
				this.isEditing = true;
			}
		},
		showMoveSuccess() {
			document.body.classList.add("modal-open");
		},
		showError() {
			document.body.classList.add("modal-open");
		},
		showSuccess() {
			document.body.classList.add("modal-open");
		},
	},
	components: {
		GrModalLarge,
		VerificationModal,
		ResponseModal,
		RequiredWarning,
		LoaderSpinner,
	},
};
</script>

<template>
	<InventorySelected ref="main" :invId="invId" :plats="loc" />
</template>

<script>
import router from "@/router";
import InventorySelected from "./InventorySelected.vue";

export default {
	props: {
		invId: { type: String, default: null },
		loc: { type: String, default: null },
		locName: { type: String, default: null },
	},

	components: {
		InventorySelected,
	},
	data() {
		return {
			option: 0,
			searchValue: "",
			windowWidth: window.innerWidth,
			activeRecs: true,
			historyRecs: false,
			invName: null,
			isLoading: false,
		};
	},
	computed: {
		clientId() {
			return this.$store.state.client.klient;
		},
	},
	methods: {
		navReturn(x) {
			if (x !== 1) {
				router.push({ name: "InventorySlot" });
			} else {
				router.go(-1);
			}
		},
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth;
		};
	},
	watch: {
		clientId() {
			router.push({ name: "InventorySlot" });
		},
	},
};
</script>

<style scoped>
.bc {
	cursor: pointer;
}
</style>

<template>
	<div class="modal-wrapper">
		<Transition name="fade">
			<div
				class="container-fluid large-modal-container"
				:class="grColor ? 'gr-color' : ''"
				v-if="showContent"
			>
				<div class="row align-items-center">
					<div class="col">
						<slot name="modal-header-title"></slot>
					</div>
					<div class="col-sm-1 d-flex justify-content-end align-items-start">
						<img
							src="@/assets/img/close.svg"
							alt="close icon"
							class="close-icon"
							@click="validate()"
						/>
					</div>
				</div>
				<div class="row mb-4">
					<slot name="modal-subheader"></slot>
				</div>
				<div
					class="row mt-3 gr-color modal-bottom-border"
					v-if="showBottomBorder"
				>
					<slot name="modal-bottom-border"> </slot>
				</div>

				<div
					class="row"
					:class="showBottomBorder ? 'fixed-padding large-modal-content' : ''"
				>
					<slot name="modal-content"></slot>
				</div>
				<div class="row justify-content-end align-items-center">
					<slot name="button-container"></slot>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
export default {
	emits: ["closeModal", "validate"],
	props: {
		showBottomBorder: { type: Boolean, default: false },
		verification: { type: Boolean, default: false },
		grColor: { type: Boolean, default: false },
	},
	data() {
		return {
			showContent: false,
		};
	},
	methods: {
		validate() {
			this.$emit("validate");
		},
		closeModal() {
			this.showContent = false;
			window.setTimeout(() => {
				this.$emit("closeModal");
				document.body.classList.remove("modal-open");
			}, 200);
		},
	},
	mounted() {
		this.showContent = true;
		this.$store.state.modalActive = true;
		document.body.classList.add("modal-open");
	},
	beforeUnmount() {
		this.$store.state.modalActive = false;
	},
	watch: {
		verification(val) {
			if (!val) {
				this.closeModal();
			}
		},
	},
};
</script>

<style scoped>
.modal-bottom-border {
	height: 3rem;
	font-weight: 600;
	margin-left: -2rem;
	margin-right: -2rem;
}

.fixed-padding {
	margin-left: -2rem;
	margin-right: -2rem;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.close-icon {
	width: 1.6rem;
}
.close-icon:hover {
	transform: scale(1.1);
	transition: 0.1s;
	cursor: pointer;
}

.large-modal-container {
	width: 70rem;
	padding: 2rem;
	height: 44rem;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.gr-color {
	background-color: #f8f5ee;
}

.large-modal-content {
	height: 28rem;
	max-height: 28rem;
}

@media screen and (max-height: 800px) {
	.large-modal-container {
		height: 38rem;
	}
	.large-modal-content {
		height: 22rem;
		max-height: 22rem;
	}
}
</style>
